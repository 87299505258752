const getters = {
  language: state => state.app.language,
  company: state => state.app.company,
  device: state => state.app.device,
  token: state => state.user.token,
  treatment: state => state.app.treatment,
  banks: state => state.app.banks,
  selectedLocation: state => state.locations.selectedLocation,
  services: state => state.services.services,
  selectedService: state => state.services.selectedService,
  selectedTimeStart: state => state.services.selectedTimeStart,
  listStaff: state => state.staffs.listStaff,
  dataUser: state => state.user.dataUser,
  paymentMethods: state => state.user.paymentMethods,
  cart: state => state.user.cart,
  dataService: state => state.services.services,
  countPendingRequest: state => state.app.countPendingRequest,
  activeTab: state => state.app.activeTab,
  datapage: state => state.app.datapage
}
export default getters
