import axios from 'axios'
import store from '../store'
// import { getSystemToken } from './auth'
import i18n from '../lang'

const cancelToken = axios.CancelToken
export let cancelSource = cancelToken.source()
export const refreshCancelToken = () => {
  cancelSource = cancelToken.source()
}

const service = axios.create({
  baseURL: 'https://zenwelecommerce.indociti.com/api/v1/',
  timeout: 500000
})

// request interceptor
service.interceptors.request.use(
  config => {
    store.commit('SET_COUNT_PENDING_REQUEST', 1)
    // const token = getSystemToken()
    // console.log(token)
    if (store.getters.token) {
      config.headers['Authorization'] = 'Bearer ' + store.getters.token
    }
    config.cancelToken = cancelSource.token
    return config
  },
  error => {
    store.commit('SET_COUNT_PENDING_REQUEST', 0)
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    store.commit('SET_COUNT_PENDING_REQUEST', 0)
    return response
  },
  error => {
    store.commit('SET_COUNT_PENDING_REQUEST', 0)

    let statusCode = 0
    let message = i18n.tc('messages.fallbackErrMessage')
    let string = i18n.tc('messages.fallbackErrMessage')

    if (axios.isCancel(error)) {
      message = error.message
      string = error.message
    }

    if (error.response) {
      const errorError = error.response.data.error.error
      let errArr = errorError
      string = errArr
      if (typeof errorError !== 'string') {
        string = ''
        errArr = Object.values(errorError)
        for (let index = 0; index < errArr.length; index++) {
          string += '- ' + errArr[index]
        }
      }
      statusCode = error.response.data.error.status_code
      message = error.response.data.error.message
    }

    // eslint-disable-next-line
    return Promise.reject({
      statusCode,
      message,
      string
    })
  }
)

export default service
