<template>
  <div>
    <div class="frame-empty">
      <div :style="'width: 100%; max-width: 350px; text-align: center; background: #E0E0E0; padding: 24px; border-radius: 3px;'">
        <div class="empty-text cz-w-fit cz-text-lg cz-color-info cz-mb-16">{{ text }}</div>
        <div class="empty-icon">
          <template v-if="dataPage === 'location'">
            <img src="../../icons/svg/location-icon-empty.svg" width="100" height="100">
          </template>
          <template v-if="dataPage === 'service'">
            <img src="../../icons/svg/service.svg" width="100" height="100">
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['page', 'text'],
  computed: {
    dataPage () {
      return this.page
    }
  },
  methods: {
    pageCheck () {
      if (this.dataPage === 'location') {
        return '../../icons/svg/location-icon-nof.svg'
      }
    }
  }
}
</script>
