import request from '../utils/request'
import store from '../store'

export function getPaymentMethod (query) {
  return request({
    url: store.getters.language + '/paymentMethod/getPayment',
    method: 'get',
    params: query
  })
}
