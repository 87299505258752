<template>
  <div class="cz-w-fit cz-text-center">
    <transition name="fade-time">
      <div v-if="!loading" :class="[timeMorning.length > 0 && timeAfternoon.length > 0 ? 'cz-post-relative' : '']" class=" cz-text-center frame-time" style="overflow-x: hidden;">
        <template v-if="timeMorning.length > 0 || timeAfternoon.length > 0">
          <div v-if="timeMorning.length > 0" class="cz-mb-8 cz-text-left">{{ $t('label.morning') }}</div>
          <div v-if="timeMorning.length > 0" class="cz-group-checkbox">
            <div v-for="(morning, keyTime) in timeMorning" :key="keyTime">
              <at-button
                :disabled="disabledButton(morning)"
                :class="checkClass(morning)"
                class="cz-post-relative"
                @click="checkTime(morning.time, morning)">
                <div
                  v-if="morning.time === timeMiddle"
                  :style="isoddNumber ? 'left: 50%; transform: translateX(-50%);' : 'left: 12px;'"
                  class="marking-duration">
                  {{ replaceDuration() }}
                </div>

                <div
                  v-if="morning.class === 'last-check-notfull'"
                  class="cz-child-last-not-full"
                  :style="{ width: morning.width + '%' }">
                </div>

                <div v-if="morning.class === 'last-check-notfull'" class="text-time">
                  {{ morning.time }}
                </div>
                <div v-else>
                  {{ morning.time }}
                </div>
                <div
                  v-if="morning.class === 'first-check'"
                  class="cz-child-first-check">
                </div>
                <div
                  v-else-if="morning.class === 'middle-check' || morning.class === 'last-check' || morning.class === 'last-check-notfull'"
                  class="cz-child-middle">
                </div>
              </at-button>
            </div>
          </div>

          <div v-if="timeAfternoon.length > 0" class="cz-mb-8 cz-text-left">{{ $t('label.afternoon') }}</div>
          <div v-if="timeAfternoon.length > 0" class="cz-group-checkbox">
            <div v-for="(afternoon, keyTime2) in timeAfternoon" :key="keyTime2">
              <at-button
                :disabled="disabledButton(afternoon)"
                :class="checkClass(afternoon)"
                class="cz-post-relative"
                @click="checkTime(afternoon.time, afternoon)">
                <div
                  v-if="afternoon.time === timeMiddle"
                  :style="isoddNumber ? 'left: 50%; transform: translateX(-50%);' : 'left: 12px;'"
                  class="marking-duration">
                  {{ replaceDuration() }}
                </div>

                <div
                  v-if="afternoon.class === 'last-check-notfull'"
                  class="cz-child-last-not-full"
                  :style="{ width: afternoon.width + '%' }">
                </div>

                <div v-if="afternoon.class === 'last-check-notfull'" class="text-time">
                  {{ afternoon.time }}
                </div>
                <div v-else>
                  {{ afternoon.time }}
                </div>
                <div
                  v-if="afternoon.class === 'first-check'"
                  class="cz-child-first-check">
                </div>
                <div v-else-if="afternoon.class === 'middle-check' || afternoon.class === 'last-check' || afternoon.class === 'last-check-notfull'" class="cz-child-middle">
                </div>
              </at-button>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="text-align: center; max-width: 200px; margin: 25% auto 0;">
            <img src="../../icons/svg/clock.svg" height="100" width="100" style="opacity: 0.5;">
            <div class="cz-mb-16"></div>
            <div class="cz-bold cz-text-lg cz-color-info">{{ $t('messages.timeNotAvailable') }}</div>
          </div>
        </template>
      </div>
    </transition>
    <loading-time :loading="loading" :data-loading="dataLoading"/>
  </div>
</template>

<script>
import loadingTime from '../../components/UI/loading/loadingTime'
import moment from '../../utils/moment'
export default {
  name: 'OptionTime',
  components: {
    loadingTime
  },
  props: ['loading', 'displayedTime', 'date', 'duration', 'selectedTimeData'],
  data () {
    return {
      dataLoading: 32,
      tempMorning: [],
      tempAfternoon: [],
      selected: [],
      operationalTime: this.$store.getters.selectedLocation.working_hours,
      timeMiddle: '',
      isoddNumber: false
    }
  },
  computed: {
    timeMorning () {
      if (this.displayedTime.time.time) {
        return this.getTime('morning')
      } else {
        return []
      }
    },
    timeAfternoon () {
      if (this.displayedTime.time.time) {
        return this.getTime('afternoon')
      } else {
        return []
      }
    },
    selectedDisplayed () {
      this.getTopDesc(this.selectedTimeData)
      return this.selectedTimeData
    }
  },
  methods: {
    getTime (event) {
      const date = moment(this.date).format('YYYY-MM-DD') + ' 12:00'
      const time = moment(date, 'YYYY-MM-DD HH:mm').toDate()
      const listTime = this.displayedTime.time.time
      const data = []
      listTime.map(x => {
        const tempDate = moment(this.date).format('YYYY-MM-DD') + ' ' + x.time
        const list = moment(tempDate, 'YYYY-MM-DD HH:mm').toDate()
        if (event === 'afternoon') {
          if (moment(list).isAfter(time) || moment(list).isSame(time)) {
            data.push(x)
          }
        } else {
          if (moment(list).isBefore(time)) {
            data.push(x)
          }
        }
      })
      if (event === 'afternoon') {
        this.tempAfternoon = data
      } else {
        this.tempMorning = data
      }
      return data
    },
    checkClass (data) {
      const today = moment(new Date(Date.now())).toDate()
      const dateSelected = moment(this.date).format('YYYY-MM-DD') + ' ' + data.time
      const clocks = moment(dateSelected, 'YYYY-MM-DD HH:mm').toDate()
      if (!data.class) {
        if (data.staff_working_hours_available !== 0 && data.appointment_available !== 0 && data.cart_available !== 0 && data.resource_available !== 0) {
          if (moment(today).isAfter(clocks)) {
            return 'block-check'
          } else {
            return 'checkbox'
          }
        } else {
          return 'block-check'
        }
      } else {
        return data.class
      }
    },
    disabledButton (data) {
      const today = moment(new Date(Date.now())).toDate()
      const dateSelected = moment(this.date).format('YYYY-MM-DD') + ' ' + data.time
      const clocks = moment(dateSelected, 'YYYY-MM-DD HH:mm').toDate()
      if (data.staff_working_hours_available !== 0 || data.staff_working_hours_available !== 0 || data.appointment_available !== 0 || data.cart_available !== 0 || data.resource_available !== 0) {
        if (moment(today).isAfter(clocks)) {
          return true
        } else {
          return false
        }
      }
    },
    checkTime (time, list) {
      this.selected = []
      const date = moment(this.date).format('YYYY-MM-DD')
      const start = moment(date + ' ' + list.time, 'YYYY-MM-DD HH:mm').toDate()
      const end = moment(date + ' ' + list.end_time, 'YYYY-MM-DD HH:mm').toDate()
      const tempData = []
      for (var i = 0; i < this.duration.strtotime; i += 900) {
        const list = moment(start).add(i, 'seconds').toDate()
        tempData.push(moment(list).format('HH:mm'))
      }
      tempData.push(moment(end).format('HH:mm'))
      tempData.map(x => {
        this.displayedTime.time.time.map(y => {
          if (y.time === x) {
            if (y.staff_working_hours_available !== 0 || y.staff_working_hours_available !== 0 || y.appointment_available !== 0 || y.cart_available !== 0 || y.resource_available !== 0) {
              this.selected.push(x)
            }
          }
        })
      })
      if (tempData.length !== this.selected.length) {
        this.selected.push(tempData[tempData.length - 1])
      }
      this.getTopDesc(this.selected)
      this.$emit('selected-time', this.selected)
    },

    getTopDesc (selected) {
      if (selected.length > 0) {
        this.isoddNumber = Math.abs(selected.length % 2) === 1
        this.timeMiddle = ''
        if (this.isoddNumber) {
          this.timeMiddle = selected[Math.round((selected.length - 1) / 2)]
        } else {
          this.timeMiddle = selected[0]
        }
      } else {
        this.timeMiddle = ''
      }
    },

    replaceDuration () {
      let newData = ''
      if (this.duration.desc.includes('h')) {
        newData = this.duration.desc.replace('h', this.$t('duration.h'))
        return newData
      } else {
        return this.duration.desc
      }
    }
  }
}
</script>
