<template>
  <transition name="fade-time">
    <div v-if="!loading" class="cz-w-fit">
      <at-dropdown trigger="click" placement="bottom-left" class="cz-w-fit">
        <at-button size="large" class="cz-btn block cz-p-null cz-radius-10 cz-border cz-emboss">
          <div class="row at-row cz-m-null cz-p-8 flex-center flex-middle">
            <div class="col-4 cz-p-null">
              <div :style="{ backgroundImage: 'url(' + firstService.photo_xs + ')' }" class="img-thumb-40"/>
            </div>
            <div
              :class="[optionService.length > 1 ? 'col-18' : 'col-20']"
              class="cz-text-left">
              <div :title="firstService.name" class="cz-text-sm cz-color cz-w-fit cz-text-overflow-dot">{{ firstService.name }}</div>
              <div class="cz-text-xs cz-color-info">{{ replaceDuration(firstService.duration.desc) }}</div>
            </div>
            <div v-if="optionService.length > 1" class="col-2">
              <i class="icon icon-chevron-down cz-text-md cz-lh-unset"/>
            </div>
          </div>
        </at-button>
        <at-dropdown-menu v-if="optionService.length > 1" slot="menu" style="width: 350px;">
          <at-dropdown-item v-for="(option, n) in optionService" :key="n" :label="n" class="cz-p-null">
            <div
              :class="[option.is_first ? 'cz-bg-info' : '']"
              class="row at-row cz-m-null cz-p-8 flex-center flex-middle"
              @click="selected(n)">
              <div class="col-4 cz-p-null">
                <div :style="{ backgroundImage: 'url(' + option.photo_xs + ')' }" class="img-thumb-40"/>
              </div>
              <div class="col-20 cz-text-left">
                <div class="cz-text-sm cz-color cz-w-fit" style="white-space: normal;">{{ option.name }}</div>
                <div class="cz-text-xs cz-color-info">{{ replaceDuration(option.duration.desc) }}</div>
              </div>
            </div>
          </at-dropdown-item>
        </at-dropdown-menu>
      </at-dropdown>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'OptionFirst',
  props: ['optionService', 'loading'],

  computed: {
    firstService () {
      const data = this.optionService.filter(function (x) { return x.is_first === true })
      return data[0]
    }
  },

  methods: {
    replaceDuration (data) {
      let newData = ''
      if (data.includes('h')) {
        newData = data.replace('h', this.$t('duration.h'))
        return newData
      } else {
        return data
      }
    },
    selected (key) {
      this.$emit('selected', key)
    }
  }
}
</script>
