export default {
  label: {
    name: 'Name',
    email: 'Email',
    phoneNumber: 'Phone number',
    bookingNotes: 'Booking notes',
    steps: 'steps',
    of: 'of',
    selectLocation: 'Select Location',
    openNow: 'Open now',
    openSoon: 'Open soon',
    closeSoon: 'Close soon',
    close: 'Close',
    moreLocations: 'More locations',
    bookNow: 'Book now',
    locations: 'Locations',
    items: 'items',
    categories: 'Categories',
    staff: 'Staff',
    chooseService: 'Choose service',
    services: 'Services',
    selectDate: 'Select date',
    selectStaff: 'Select staff',
    noStaff: 'No Staff Reference',
    pickTime: 'Pick time',
    afternoon: 'Afternoon',
    morning: 'Morning',
    selectDateStaff: 'Select date & staff',
    serviceSchedule: 'Service schedule',
    payment: 'Payment',
    selectPayment: 'Select payment',
    creditcard: 'Credit card / Debit card',
    payOnSpot: 'Pay on the spot',
    useVoucher: 'Use voucher',
    amount: 'Amount',
    cardInformation: 'Card information',
    cardHolderName: 'Card holder name',
    cardNumber: 'Card number',
    validThru: 'Valid thru',
    cvv: 'CVV',
    poweredBy: 'Powered by',
    payNow: 'Pay now',
    cancel: 'Cancel',
    done: 'Done',
    months: 'Months',
    years: 'Years',
    reload: 'Reload',
    today: 'Today',
    not_available: 'Not available!',
    required_name: 'name is required!',
    required_email: 'email is not valid!',
    required_mobile: 'mobile number is not valid!',
    required_card: 'a minimum of 12 digit numbers!',
    required: 'is required!',
    required_valid: 'The validity period is invalid!',
    cancelingService: 'Cancel service',
    timeHasExpired: 'Time has expired',
    tryAgain: 'Try again',
    succesBook: 'Your booking has been created.',
    makeAnotherBook: 'Make another booking',
    startat: 'Start at',
    dateTime: 'Date & Time',
    chooseStaff: 'Choose staff',
    removeService: 'Remove service',
    onMyLocation: 'On my location',
    onServiceProvider: 'On service provider location',
    onSwitchBookBy: 'Switch on to book as ',
    recentLocation: 'Recent location',
    useLocation: 'Use',
    setYourLocation: 'Set Your Location',
    selectProvider: 'Select Provider',
    near: 'Near',
    book: 'Book',
    selectItem: 'Select Item',
    add: 'Add',
    selectTime: 'Select Time',
    summary: 'Summary',
    cancelBook: 'Cancel booking',
    change: 'change',
    confirmBooking: 'Confirm Booking',
    back: 'Back',
    main: 'Main',
    onlineBooking: 'Online Booking',
    payTransfer: 'Bank Transfer',
    toPay: 'To Pay',
    enterPromoCode: 'Enter promo code',
    discard: 'Discard',
    keep: 'Keep',
    tryAnotherPay: 'Try Another Payment',
    orderDetails: 'Order details',
    payTo: 'Pay to',
    boughtItems: 'Bought items',
    total: 'Total',
    subTotal: 'Subtotal',
    discount: 'Discount',
    confirm: 'Confirm',
    unisex: 'Unisex',
    facility: 'Facility',
    class: 'Class',
    service: 'Service',
    male: 'Male',
    female: 'Female',
    contact: 'Contact',
    chargeNow: 'Charge Now'
  },
  tips: {
    timeStart: 'When do you want to start?',
    bookingAdd: 'Add your details to confirm booking',
    bookingClick: 'By clicking',
    bookingAgree: 'you agree to the following policy.',
    creditCardTips: 'Pay with Visa card or MasterCard',
    payOnSpotTips: 'Pay later at your booking location',
    useVoucherTips: 'Do you have voucher?',
    needMorePayTips: 'Need more convenient booking?',
    tryTips: 'Try',
    itTips: 'It',
    freeTips: 'free',
    beautyTips: 'Beauty and wellness booking web and app',
    ccvTips: 'Last 3 digit numbers in the back of your card.',
    tipsMarketing: 'Accepts marketing notifications',
    tipsAppointmentTakePlace: 'Where the service will take place ?',
    tipsSaveDetails: 'Save details for upcoming booking',
    payTransferTips: 'Pay with Transfer',
    tipsPleasePay: 'Please pay within',
    tipsForFailedPay: 'But don\'t worry, you can try another payment.',
    tipsDetailLocation: 'Based on services gender it has, this provider is assigned to '
  },
  messages: {
    locationNull: 'Location is not available!',
    serviceNull: 'Item is not available!',
    locationClose: 'This location is closed!',
    locationNotFound: 'Location is not found!',
    categoriesNull: 'Categories is not found!',
    staffNotValid: 'You must choose one staff for each service!',
    dateNotValid: 'Date is not allowed!',
    monthNotValid: 'Month is not allowed!',
    staffNotAvailable: ' is not available at ',
    comeEarlier: 'Please come 10 minute earlier',
    inCompleteForm: 'Please complete this form!',
    errorTimePicker: 'other staff do not have time yet',
    messageTimeOver: 'Don`t worry, lets go back into time and confirm your book',
    messageBookSuccess: 'An email confirmation has been sent to',
    noWorkingHours: ' don\'t have time working hours!',
    durationError: 'Sorry this service is available until ',
    durationErrorClock: ' o\'clock',
    timeNotAvailable: 'Sorry time not available!',
    messagesCancelBook: 'Are you sure you want to cancel this booking?',
    paymentNotSupport: ' is not available for ',
    sorry: 'Sorry',
    paymentFaild: 'payment failed!',
    fallbackErrMessage: 'Something went wrong. Try reload the page.'
  },
  month: {
    first: 'January',
    second: 'February',
    third: 'March',
    fourth: 'April',
    fifth: 'May',
    sixth: 'June',
    seventh: 'July',
    eighth: 'August',
    ninth: 'September',
    tenth: 'October',
    eleventh: 'November',
    twelveth: 'December'
  },
  duration: {
    min: 'm',
    h: 'h'
  },
  gender: {
    e: 'Everyone',
    m: 'Male',
    f: 'Female'
  },
  title: {
    booking_confirmation: 'Booking Confirmation'
  },
  transfer_m_bni: {
    _1: 'Open the BNI Mobile Banking app and login',
    _2: 'Choose menu Transfer',
    _3: 'Choose menu Virtual Account Billing',
    _4: 'Choose the bank account you want to use',
    _5: 'Enter the 16 digits virtual account number',
    _6: 'The billing information will appear on the payment validation page',
    _7: 'If the information is correct, enter your password to proceed the payment',
    _8: 'Your transaction will be processed'
  },
  transfer_i_bni: {
    _1: 'Go to <b>https://ibank.bni.co.id</b> and then click <b>Login</b>',
    _2: 'Continue login with your <b>User ID</b> and <b>Password</b>',
    _3: 'Click <b>Transfer</b> and then <b>Add Favorite Account</b> and choose <b>Antar Rekening BNI</b>',
    _4: 'Enter account name, account number, and email and then click <b>Continue</b>',
    _5: 'Input the <b>Authentification Code</b> from your token and then click <b>Continue</b>',
    _6: 'Back to main menu and select <b>Transfer</b> and then <b>Transfer Antar Rekening BNI</b>',
    _7: 'Pick the account that you just created in the previous step as <b>Rekening Tujuan</b> and fill in the rest before clicking <b>Continue</b>',
    _8: 'Check whether the details are correct, if they are, please input the <b>Authentification Code</b> and click <b>Continue</b> and you are done'
  },
  transfer_permata: {
    _1: 'On the main menu, choose <b>Other Transaction</b>',
    _2: 'Choose <b>Payment</b>',
    _3: 'Choose <b>Other Payment</b>',
    _4: 'Choose <b>Virtual Account</b>',
    _5: 'Enter the <b>16<b> digits Account No and <b>press Correct</b>',
    _6: 'Amount to be paid, account number, and merchant name will appear on the payment confirmation page. If the information is right, press <b>Correct</b>',
    _7: 'Choose your payment account and press <b>Correct</b>'
  },
  transfer_m_bca: {
    _1: 'Log in to your <b>BCA Mobile</b> app',
    _2: 'Choose <b>m-BCA</b>, then input your <b>m-BCA access code</b>',
    _3: 'Choose <b>m-Transfer</b>, then choose BCA Virtual Account',
    _4: '<b>Input Virtual Account Number</b> or choose an existing account from <b>Daftar Transfer</b>',
    _5: 'Input the <b>payable amount</b>',
    _6: 'Input your <b>m-BCA</b> pin',
    _7: 'Payment is finished. Save the notification as your payment receipt'
  },
  transfer_atm_bca: {
    _1: 'On the main menu, choose <b>Other Transaction</b>',
    _2: 'Choose <b>Transfer</b>',
    _3: 'Choose <b>Transfer To BCA Virtual Account</b>',
    _4: 'Enter your <b>Payment Code</b> (11 digits code) and press <b>Correct</b>',
    _5: 'Enter the full amount to be paid and press <b>Correct</b>',
    _6: 'Your payment details will appear on the payment confirmation page. If the information is correct press <b>Yes</b>'
  },
  transfer_click_bca: {
    _1: 'Choose Menu <b>Fund Transfer</b>',
    _2: 'Choose <b>Transfer To BCA Virtual Account</b>',
    _3: '<b>Input BCA Virtual Account Number</b> or <b>Choose from Transfer list</b> and <b>click Continue</b>',
    _4: 'Amount to be paid, account number and Merchant name will appear on the payment confirmation page, if the information is right click <b>Continue</b>',
    _5: 'Get your <b>BCA token</b> and input KEYBCA Response <b>APPLI 1</b> and click <b>Submit</b>',
    _6: 'Your Transaction is Done'
  }
}
