import { getSystemToken as apiGetSystemToken } from '../../api/auth'
import { getSystemToken, setSystemToken } from '../../utils/auth'
import { getPaymentMethod } from '../../api/paymentMethods'

const user = {
  state: {
    code: '',
    token: getSystemToken(),
    system_token: getSystemToken(),
    dataUser: null,
    paymentMethods: null,
    cart: null
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_SYSTEM_TOKEN: (state, systemToken) => {
      state.system_token = systemToken
    },
    SET_DATA_USER: (state, user) => {
      state.dataUser = user
      localStorage.setItem('user', JSON.stringify(state.dataUser))
    },
    SET_DATA_PAYMENT: (state, paymentMethods) => {
      state.paymentMethods = paymentMethods
    },
    SET_DATA_CART: (state, cart) => {
      state.cart = cart
    }
  },

  actions: {
    GetSystemAccessToken ({ commit }) {
      return new Promise((resolve, reject) => {
        apiGetSystemToken().then(response => {
          commit('SET_SYSTEM_TOKEN', response.data.access_token)
          commit('SET_TOKEN', response.data.access_token)
          setSystemToken(response.data.access_token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    setDataPayment ({ commit }, query) {
      return new Promise((resolve, reject) => {
        getPaymentMethod(query).then(response => {
          commit('SET_DATA_PAYMENT', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
