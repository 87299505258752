<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div class="back-step" @click="backStep">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="false" :title="title"/>
        </div>
      </div>
      <div :style="'padding-bottom: 0px;'" class="cz-body">
        <div class="content">
          <transition>
            <div v-if="!loading" class="content">
              <date-time-new
                v-if="services !== null"
                :loading="loadingDate"
                :date-now="date"
                :list-date="listDate"
                :open-now="isOpenLocation"
                @change-from-header="changingDateHeader"
                @change-manual-date="changingDate"
              />

              <list-service
                :loading="loading"
                :service-group="serviceGroup"
                :services="services"
                :style-css="styleList"
                :data-filter="dataToFilter"
                @selected="selectedService"
              />
            </div>
          </transition>

          <loading-page :loading="loading" :data-loading="loadService" :style-list="styleList"/>

          <transition name="fade-steady">
            <div
              v-if="!filterSteady && serviceGroup.length > 0"
              :style="total.item > 0 ? 'bottom: 78px;' : 'bottom: 16px;'"
              class="cz-btn-float-circle cz-cursor"
              @click="filterSteady = true">
              <img src="../../icons/svg/book-open.svg" height="20" width="24">
            </div>
          </transition>

          <transition name="slide-test-up-new">
            <div
            v-if="filterSteady && serviceGroup.length > 0"
            :style="total.item ? 'bottom: 78px; border-radius: 3px;' : 'bottom: 16px; border-radius: 3px;'"
            class="cz-list-menu-float">
              <div class="cz-container">
                <div
                  v-for="itemGroup in serviceGroup"
                  :key="itemGroup.service_group_id"
                  class="cz-list cz-text-sm cz-hover-info cz-cursor cz-border-null"
                  @click="handleSelectFiltered(itemGroup.service_group_id)">
                  <div class="row at-row no-gutter flex-middle">
                    <div class="col-xs-22 col-md-22">
                      {{ itemGroup.serviceGroup.name.replace('amp;', '') }}
                    </div>

                    <div class="col-xs-2 col-md-2 cz-text-xs cz-color-info cz-bold cz-text-center">
                      {{ getManyInGroup(itemGroup.service_group_id) }}
                    </div>
                  </div>
                </div>
              </div>

              <div :style="backgroundFloatColor" class="cz-btn-close cz-text-sm">
                <div class="row at-row no-gutter flex-middle">
                  <div class="col-xs-22 col-md-22">
                    <img
                      v-if="dataToFilter === 0"
                      src="../../icons/svg/book-open.svg"
                      height="20"
                      width="24"
                      class="cz-post-absolute"
                      style="top: 18px;"
                    >

                    <span
                      :class="[dataToFilter === 0 ? 'cz-ml-32' : '']"
                      class="cz-text-sm"
                      @click="handleSelectFiltered(null)">
                      <template v-if="dataToFilter === 0">
                        {{ $t('label.categories') }}
                      </template>
                      <template v-else>
                        <span class="cz-color-danger">{{ 'Reset' }}</span>
                      </template>
                    </span>
                  </div>

                  <div class="col-xs-2 col-md-2 cz-text-lg cz-text-center">
                    <i class="icon icon-x cz-cursor" @click="filterSteady = false"/>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="slide-test-up">
            <div v-if="total.item > 0 && isSelectedDate.length > 0" class="cz-btn-bottom-book">
              <div class="cz-w-fit cz-boxshadow-btn">
                <at-button
                  :type="total.price !== 0 ? 'success' : ''"
                  :disabled="total.price === 0"
                  class="cz-btn block text-sm bold cz-p-8"
                  @click="confirm">
                  <div class="flex at-flex flex-center flex-middle">
                    <div class="col">
                      <div v-if="total.item > 0" class="cz-text-left">
                        <div class="cz-text-xs cz-nobold">{{ total.item }} {{ $t('label.items') }}</div>
                        <div class="cz-text-sm cz-bold">{{ currency }} {{ formatted }}</div>
                      </div>
                      <div v-else class="cz-text-left">
                        <div>0</div>
                      </div>
                    </div>
                    <div class="col cz-text-right">
                      <span class="cz-text-lg">{{ $t('label.confirm') }}</span>
                    </div>
                  </div>
                </at-button>
                <input v-model="formatted" v-money="money" type="hidden"/>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import headerTitle from '../../components/UI/title'
import loadingPage from '../../components/UI/loading'
import dateTimeNew from '../../components/UI/dateTimeNew'
import ListService from './list'
import { fetchListSearch } from '../../api/services'
import moment from '../../utils/moment'
import axios from 'axios'
export default {
  components: {
    headerTitle,
    loadingPage,
    dateTimeNew,
    ListService
  },
  data () {
    return {
      loading: true,
      loadingDate: true,
      title: this.$t('label.selectItem'),
      services: [],
      serviceGroup: [],
      baseList: this.$store.getters.dataUser.reference,
      query: this.$store.getters.dataUser.query,
      date: moment(new Date(Date.now())),
      listDate: [],
      isOpenLocation: false,
      shiftTime: {
        start: this.$store.getters.selectedLocation.working_hours.shift_start_time,
        end: this.$store.getters.selectedLocation.working_hours.shift_end_time
      },
      selectedDate: moment(new Date(Date.now())),
      styleList: '',
      total: { item: 0, price: 0 },
      isSelectedDate: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true
      },
      formatted: '',
      loadService: {
        length: 11,
        radius: '3px',
        margin: '0 0 8px 0',
        type: 'list-banner',
        width: '100%',
        height: '56px'
      },
      filterSteady: false,
      dataToFilter: 0,
      slideAnimation: true
    }
  },
  computed: {
    currency () {
      return this.$store.getters.company.currency.symbol
    },
    backgroundFloatColor () {
      return 'background-color: #F5F5F5; color: #333;'
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  async mounted () {
    this.loading = true
    this.loadingDate = true
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
      this.getStatusLocation()
    }, 1000)
    await this.delay(300)
    this.slideAnimation = false
    this.getServices()
    this.getMyAddress()
  },
  methods: {
    backStep () {
      this.$store.commit('SET_SELECTED_SERVICES', null)
      if (this.baseList !== 0) {
        this.$store.dispatch('setActiveTab', 'my-location')
      } else {
        this.$store.dispatch('setActiveTab', 'detail-location')
      }
    },
    getServices () {
      if (this.$store.getters.selectedService === null) {
        if (this.baseList !== 0) {
          this.query.location_id = this.$store.getters.selectedLocation.id
          this.query.sort_type = 'nearest'
          fetchListSearch(this.query).then(response => {
            const data = response.data.data
            this.$store.commit('SET_SERVICES', data)
            this.getGroupingMyLocation(data)
          }).catch((error) => {
            this.$Message.error({
              message: error.string,
              icon: 'icon-x'
            })
            this.services = null
            this.loading = false
            this.loadingDate = false
          })
        } else {
          this.getGrouping(this.$store.getters.services)
        }
      } else {
        this.getFromHistory()
      }
    },
    getGrouping (list) {
      const data = list.map(x => ({ ...x, qty: 0 }))
      this.services = data
      if (this.services.length > 0) {
        const grouping = data.filter((thing, index, self) =>
          index === self.findIndex((y) => (
            y.service_group_id === thing.service_group_id
          ))
        )
        Promise.all(grouping).then(results => {
          this.serviceGroup = results
          this.loading = false
          this.getStatusLocation()
        })
      } else {
        this.services = null
        this.loading = false
      }
    },
    getGroupingMyLocation (list) {
      const data = list.map(x => ({ ...x._source, qty: 0, distance: x.fields.distance }))
      this.services = data
      const grouping = data.filter((thing, index, self) =>
        index === self.findIndex((y) => (
          y.service_group_id === thing.service_group_id
        ))
      )
      Promise.all(grouping).then(results => {
        this.serviceGroup = results
        this.loading = false
        this.getStatusLocation()
      })
    },
    getStatusLocation () {
      const stringDate = moment(this.date).format('YYYY-MM-DD')
      const start = moment(stringDate + ' ' + this.shiftTime.start, 'YYYY-MM-DD HH:mm')
      const end = moment(stringDate + ' ' + this.shiftTime.end, 'YYYY-MM-DD HH:mm')
      const opening = moment(stringDate + ' ' + this.shiftTime.start, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
      const closing = moment(stringDate + ' ' + this.shiftTime.end, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
      if (this.date.isBetween(start, end)) {
        if (this.date.isBetween(closing, end)) {
          this.isOpenLocation = false
        } else {
          this.isOpenLocation = true
        }
      } else {
        if (this.date.isBetween(opening, start)) {
          this.isOpenLocation = true
        } else {
          this.isOpenLocation = false
        }
      }
      if (this.listDate.length <= 0) {
        this.getListDate()
      }
    },
    getListDate () {
      const limit = 5
      const data = []
      let selectedDate = false
      for (let i = 0; i < limit; i++) {
        const newDate = moment(this.selectedDate).add(i, 'days')
        if (i === 0) {
          selectedDate = true
        } else {
          selectedDate = false
        }
        data.push({
          moment: newDate,
          selected: selectedDate
        })
      }
      if (!this.isOpenLocation && this.selectedDate.isSame(this.date)) {
        data[0].selected = 'block'
        data[1].selected = true
      }
      this.listDate = data
      this.loadingDate = false
    },
    changingDateHeader (key, list) {
      for (let i = 0; i < list.length; i++) {
        if (i === key) {
          list[i].selected = true
        } else {
          if (list[i].selected !== 'block') {
            list[i].selected = false
          }
        }
      }
    },
    changingDate (date) {
      const data = []
      this.listDate.map(x => {
        if (x.selected !== 'block') {
          x.selected = false
        }
        if (x.moment.isSame(date, 'date')) {
          x.selected = true
          data.push(x)
        }
      })
      this.selectedDate = moment(date)
      if (data.length <= 0) {
        this.getListDate()
      }
    },
    handleResize () {
      let paddingValue = 96
      if (this.total.item > 0) {
        paddingValue = 156
      }
      const height = window.innerHeight - 120
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding-top: 2px; padding-bottom: ' + paddingValue + 'px;'
    },
    selectedService () {
      this.total = {
        item: 0,
        price: 0
      }
      let price = 0
      this.services.map(x => {
        price = x.retail_price
        if (x.special_price > 0) {
          price = x.special_price
        }
        const totalItem = price * x.qty
        this.total.item += x.qty
        this.total.price += totalItem
      })
      this.handleResize()
      this.formatted = this.total.price
      this.isSelectedDate = this.listDate.filter(function (x) { return x.selected === true })
      this.$store.commit('SET_SELECTED_SERVICES', null)
    },
    confirm () {
      const detailTemp = []
      const selectedTemp = this.services.filter(function (x) { return x.qty > 0 })
      const listDateTemp = this.listDate.filter(function (y) { return y.selected === true })
      const dateTemp = listDateTemp[0].moment.toDate()
      selectedTemp.map(x => {
        if (x.qty > 0) {
          for (var i = 0; i < x.qty; i++) {
            detailTemp.push(x)
          }
        }
      })
      const data = {
        selected: selectedTemp,
        detail: detailTemp,
        group: this.serviceGroup,
        list_date: this.listDate,
        date: dateTemp,
        location_open: this.isOpenLocation
      }
      this.$store.commit('SET_SELECTED_SERVICES', { ...data })
      this.$store.dispatch('setActiveTab', 'pick-time')
    },
    async getFromHistory () {
      const data = this.$store.getters.selectedService
      this.listDate = data.list_date
      this.serviceGroup = data.group
      this.selectedDate = data.date
      this.isOpenLocation = data.location_open
      if (this.baseList !== 0) {
        this.services = this.$store.getters.services.map(x => ({ ...x._source, qty: 0, distance: x.fields.distance }))
      } else {
        this.services = this.$store.getters.services.map(x => ({ ...x, qty: 0 }))
      }
      this.total = {
        item: 0,
        price: 0
      }
      let price = 0
      await this.services.map(x => {
        data.selected.map(y => {
          if (x.id === y.id) {
            price = y.retail_price
            if (y.special_price > 0) {
              price = y.special_price
            }
            x.qty = y.qty
            const totalItem = price * y.qty
            this.total.item += y.qty
            this.total.price += totalItem
          }
        })
      })
      this.formatted = this.total.price
      this.isSelectedDate = this.listDate.filter(function (x) { return x.selected === true })
      this.loading = false
      this.loadingDate = false
    },
    handleSelectFiltered (id) {
      this.loading = true
      this.dataToFilter = 0
      if (id !== null) {
        this.dataToFilter = id
      }
      this.loading = false
      this.filterSteady = false
    },
    setBackgroundColor (n) {
      if (n % 2 !== 0) {
        return 'background-color: #FAFAFA;'
      }
    },
    getManyInGroup (id) {
      let n = 0
      const data = this.services.filter(function (data) {
        return data.service_group_id === id
      })
      n = data.length
      return n
    },
    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },
    async getMyAddress () {
      if (!this.$store.getters.dataUser.address) {
        if (this.$store.getters.dataUser.now_allow_location <= 0) {
          let results = {}
          const geolocate = this.$store.getters.dataUser.geolocation
          await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              latlng: geolocate.lat + ',' + geolocate.lon,
              key: 'AIzaSyA2Dt2etiCUW4cnw38iES-O8T7yPPIA680'
            }
          }).then(response => {
            const data = response.data.results[0]
            results.lat = data.geometry.location.lat
            results.lon = data.geometry.location.lng
            for (const component of data.address_components) {
              if (component.types[0] === 'postal_code') results.zipcode = component.long_name
              if (component.types[0] === 'administrative_area_level_4') results.address_level4 = component.long_name
              if (component.types[0] === 'administrative_area_level_3') results.address_level3 = component.long_name
              if (component.types[0] === 'administrative_area_level_2') results.address_level2 = component.long_name.replace('é', 'e')
              if (component.types[0] === 'administrative_area_level_1') results.address_level1 = component.long_name
              if (component.types[0] === 'country') results.country = component.long_name
            }
            results.formatted_address = data.formatted_address
            let newData = { address: results }
            newData = { ...this.$store.getters.dataUser, ...newData }
            this.$store.commit('SET_DATA_USER', newData)
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }
  }
}
</script>
