import request from '../utils/request'
import store from '../store'

const endPoint = '/company/show/'

export function fetchCompany (id) {
  return request({
    url: store.getters.language + endPoint + id,
    method: 'get'
  })
}

export function fetchBank () {
  return request({
    url: store.getters.language + '/payment/listbank',
    method: 'get'
  })
}
