<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div
          :style="widthScreen <= 400 ? 'align-items: center; justify-content: center;' : ''"
          class="header-title">
          <header-title :loading="false" :title="$t('title.booking_confirmation')"/>
          <div
            v-if="!loading"
            :class="[widthScreen <= 400 ? '' : 'cz-post-absolute']"
            class="cz-text-xs cz-color-info"
            style="right: 24px; top: 28px;">
            {{ timer }}
          </div>
        </div>
      </div>

      <div :style="'padding-bottom: 0px;'" class="cz-body">
        <transition name="fade-time">
          <div v-if="!loading" :style="styleList" class="content">
            <form-detail
              :selected-service="selectedService"
              :detail-service="detailService"
              :selected-location="selectedLocation"
              :data-user="dataUser"
              :loading="loading"
              :loading-button="loadingButton"
              @show-button="showingButton"
              @confirm="confirmUser"
              @cancel="cancelBooking"
            />
          </div>
        </transition>
        <loading-page :loading="loading" :data-loading="loaderBanner"/>
        <loading-page :loading="loading" :data-loading="loader"/>

        <transition name="fade-time">
          <div v-if="modalCancel" class="cz-modal">
            <div
              class="cz-content-modal new-service cz-post-relative"
              style="height: auto; min-height: unset; padding-bottom: 64px;">
              <div class="cz-w-fit cz-color cz-bold cz-text-lg cz-text-center">
                {{ $t('messages.messagesCancelBook') }}
              </div>
              <div
                class="cz-p-10"
                style="position: absolute; left: 0; bottom: 0; transform: translate(0, 0); width: 100%;">
                <div class="flex at-flex flex-center flex-middle">
                  <div class="cz-cell cz-plr-8" style="width: 50%;">
                    <at-button type="info" size="large" class="cz-btn cz-bg-input cz-w-fit" @click="modalCancel = false">
                      {{ $t('label.back') }}
                    </at-button>
                  </div>
                  <div class="cz-cell cz-plr-8" style="width: 50%;">
                    <at-button @click="destroyedData" type="success" size="large" class="cz-btn cz-w-fit">
                      {{ $t('label.main') }}
                    </at-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <loading-opacity :loading="loadingCircle"/>
    </div>
  </transition>
</template>

<script>
import headerTitle from '../../components/UI/title'
import FormDetail from './form'
import moment from '../../utils/moment.js'
import { bookingCart } from '../../api/booking'
import loadingPage from '../../components/UI/loading'
import loadingOpacity from '../../components/UI/loading/loadingOpacity'
export default {
  components: {
    headerTitle,
    FormDetail,
    loadingPage,
    loadingOpacity
  },
  data () {
    return {
      loading: true,
      date: moment(new Date(Date.now())),
      limiter: moment(new Date(Date.now())).add(this.$store.getters.cart.response.second_left, 'seconds'),
      seletedService: [],
      detailService: [],
      selectedLocation: this.$store.getters.selectedLocation,
      baseList: this.$store.getters.dataUser.reference,
      dataUser: {},
      loadingButton: false,
      modalCancel: false,
      loaderBanner: {
        length: 1,
        height: '200px',
        margin: '0 0 16px 0',
        radius: '10px'
      },
      loader: {
        length: 6,
        height: '48px',
        margin: '0 0 16px 0',
        radius: '3px'
      },
      styleList: '',
      loadingCircle: false,
      widthScreen: 0,
      slideAnimation: true,
      showButton: true
    }
  },
  computed: {
    timer () {
      const duration = moment.duration(this.limiter.diff(this.date))
      let results = ''
      if (duration.hours() > 0) {
        results = this.twoDigitNumber(duration.hours()) + ':' + this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else if (duration.minutes() > 0) {
        results = this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else {
        results = this.twoDigitNumber(duration.seconds())
      }
      this.getTimeOver(duration)
      return results
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  async mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
    }, 1000)
    await this.delay(300)
    this.slideAnimation = false
    this.getService()
    if (this.$store.getters.dataUser.useOldUser) {
      this.dataUser = this.$store.getters.dataUser
    }
  },
  methods: {
    showingButton (data) {
      this.showButton = false
      this.showButton = data
      this.handleResize()
    },
    twoDigitNumber (number) {
      if (number <= 9) {
        number = '0' + number
      }
      return number
    },
    getTimeOver (duration) {
      if (duration.hours() <= 0) {
        if (duration.minutes() <= 0) {
          if (duration.minutes() <= 0) {
            this.$store.dispatch('setActiveTab', 'time-over')
          }
        }
      }
    },
    async getService () {
      this.loading = true
      this.selectedService = []
      const data = this.$store.getters.selectedService.selected
      const detail = this.$store.getters.selectedService.detail
      data.map(x => {
        this.selectedService.push(x)
      })
      detail.map(y => {
        this.detailService.push(y)
      })
      await this.delay(500)
      this.loading = false
    },
    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },
    confirmUser (data) {
      this.loadingButton = true
      this.loadingCircle = true
      if (!data.notes) {
        data.notes = ''
      }
      let newData = { ...this.dataUser, ...data }
      newData.reference = this.baseList
      let cart = this.$store.getters.cart.cart
      const token = this.$store.getters.cart.response.cart_token
      let customAddress = ''
      if (this.$store.getters.dataUser.address) {
        customAddress = this.$store.getters.dataUser.address.formatted_address
        if (!customAddress || customAddress === null) customAddress = this.$store.getters.dataUser.address.faddress
      }
      cart.customer = {
        name: data.name,
        email: data.email,
        mobile: data.phone.formattedNumber,
        notes: data.notes
      }
      const addKey = { notes: data.notes, custom_customer_address: customAddress }
      cart = { ...cart, ...addKey }
      bookingCart(cart, token).then(response => {
        const data = {
          cart: cart,
          response: response.data.data
        }
        this.$store.commit('SET_DATA_USER', { ...newData })
        this.$store.commit('SET_DATA_CART', { ...data })
        this.loadingButton = false
        this.loadingCircle = false
        this.$store.dispatch('setActiveTab', 'select-payments')
      }).catch(error => {
        this.loadingCircle = false
        this.loadingButton = false
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
      })
    },
    cancelBooking () {
      this.modalCancel = true
    },
    destroyedData () {
      this.$store.commit('SET_SELECTED_LOCATION', null)
      this.$store.commit('SET_SELECTED_SERVICES', null)
      this.$store.commit('SET_DATA_CART', null)
      this.$store.dispatch('setActiveTab', 'locations')
    },
    handleResize () {
      let paddingValue = 32
      if (this.showButton) paddingValue = 96
      this.widthScreen = window.innerWidth
      const height = window.innerHeight - 56
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding: 0 8px ' + paddingValue + 'px 8px;'
    }
  }
}
</script>
