<template>
  <transition name="fade">
    <div v-if="!loading" class="cz-text-lg cz-text-center cz-color cz-bold">
      {{ title }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'headerTitle',
  props: [
    'loading',
    'title'
  ]
}
</script>
