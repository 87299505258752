<template>
  <transition name="fade-time">
    <div v-if="loading" class="row at-row no-gutter flex-center flex-middle cz-m-null">
      <div v-for="n in 5" :key="n" class="col cz-ptb-10 box-loading" style="border-radius: 10px; height: 56px; flex-basis: 16.67%;" />
      <div class="col cz-ptb-10">
        <div class="row at-row no-gutter flex-center flex-middle cz-m-null">
          <div class="col box-loading" :style="buttonDateShow"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loadingDate',
  props: ['loading'],
  computed: {
    buttonDateShow () {
      return 'width: 40px; height: 40px; max-height: 40px; max-width: 40px; border-radius: 50%;'
    }
  }
}
</script>
