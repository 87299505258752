<template>
  <transition name="fade-time">
    <div v-if="loading" class="content">
      <div v-if="typeof dataLoad.wrap === 'undefined'" :style="styleList" class="cz-list-card">
        <div v-for="n in dataLoad.length" :key="n">
          <div
            v-if="n === 1 && dataLoad.type === 'list-banner'"
            class="box-loading"
            :style="'border-radius: 10px; margin: 0 0 16px 0; height: 120px; width: ' + dataLoad.width + ';'">
          </div>
          <div
            v-else
            class="box-loading"
            :style="'border-radius:' + dataLoad.radius + ';margin:' + dataLoad.margin + '; height: ' + dataLoad.height + '; width: ' + dataLoad.width + ';'">
          </div>
        </div>
      </div>
      <div v-else-if="dataLoad.wrap">
        <div class="box-loading-inline">
          <div
            v-for="n in dataLoad.length"
            :key="n"
            class="item-content"
            :style="'border-radius:' + dataLoad.radius + ';margin:' + dataLoad.margin + '; height: ' + dataLoad.height + '; width: ' + dataLoad.width + ';'"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loadingPage',
  props: ['loading', 'dataLoading', 'topContent', 'styleList', 'length'],
  computed: {
    dataLoad () {
      let data = this.dataLoading
      return data
    }
  }
}
</script>
