<template>
  <transition name="fade-time">
    <div v-if="loading" :style="styleList" class="cz-w-fit cz-mt-24 cz-text-left">
      <div class="cz-mb-8 label-loading">{{ $t('label.morning') }}</div>
      <div class="cz-group-checkbox">
        <div v-for="n in (dataLoading / 2)" :key="n"><at-button :disbaled="true" class="checkbox loading">00:00;</at-button></div>
      </div>
      <div class="cz-mb-8 label-loading">{{ $t('label.afternoon') }}</div>
      <div class="cz-group-checkbox">
        <div v-for="n2 in (dataLoading / 2)" :key="n2"><at-button :disbaled="true" class="checkbox loading">00:00;</at-button></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loadingPayment',
  props: ['loading', 'dataLoading', 'styleList']
}
</script>
