// import { fetchList } from '../../api/services'

const services = {
  state: {
    listStaff: null
  },

  mutations: {
    SET_STAFFS: (state, staffs) => {
      state.listStaff = staffs
    }
  },

  actions: {
    /*
    fetchServices ({ commit }) {
      return new Promise((resolve, reject) => {
        fetchList({ per_page: 100 }).then(response => {
          commit('SET_SERVICES', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    }
    */
  }
}

export default services
