<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div class="back-step" @click="backStepsTo">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="loading" :title="title"/>
        </div>

        <div class="cz-body">
          <div v-if="!loading" class="content" :style="styleList">
            <date-time-new
              :loading="loadingDate"
              :open-now="isOpenLocation"
              :date-now="date"
              :list-date="listDate"
              @change-from-header="changingDateHeader"
              @change-manual-date="changingDate"
            />

            <div class="cz-mb-16"/>
            <transition name="fade-time">
              <option-first :loading="loadingService" :option-service="optionService" @selected="selectedService"/>
            </transition>

            <loading-page :loading="loadingService" :data-loading="loaderService"/>

            <template v-if="timeService.time">
              <div v-if="timeService.time.time.length > 0" class="cz-text-sm cz-text-center cz-mtb-16">
                {{ $t('tips.timeStart') }}
              </div>
            </template>

            <option-time
              :loading="loadingTime"
              :displayed-time="timeService"
              :date="selectedDate"
              :duration="duration"
              :selected-time-data="selectedTimeData"
              @selected-time="selectedTime"
            />

            <transition name="slide-test-up">
              <div v-if="selectedTimeData.length > 0" class="cz-btn-bottom-book">
                <div class="cz-w-fit cz-boxshadow-btn">
                  <at-button
                    :type="isDisabled ? '' : 'success'"
                    :disabled="isDisabled"
                    :loading="loadingButton"
                    class="cz-btn block h56 text-lg cz-p-8"
                    @click="confirmStart">
                    {{ $t('label.startat') }} {{ selectedTimeData[0] }}
                  </at-button>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <loading-opacity :loading="loadingButton"/>
    </div>
  </transition>
</template>

<script>
import Cookies from 'js-cookie'
import headerTitle from '../../components/UI/title'
import dateTimeNew from '../../components/UI/dateTimeNew'
import loadingPage from '../../components/UI/loading'
import loadingOpacity from '../../components/UI/loading/loadingOpacity'
import OptionFirst from './list-service.vue'
import OptionTime from './list-time.vue'
import { fetchListTime } from '../../api/time'
import { buildCart } from '../../api/booking'
import moment from '../../utils/moment.js'
export default {
  components: {
    headerTitle,
    dateTimeNew,
    OptionFirst,
    loadingPage,
    loadingOpacity,
    OptionTime
  },
  data () {
    return {
      loading: true,
      loadingDate: false,
      title: this.$t('label.selectTime'),
      date: moment(this.$store.getters.selectedService.date),
      listDate: this.$store.getters.selectedService.list_date,
      selectedDate: this.$store.getters.selectedService.date,
      isOpenLocation: this.$store.getters.selectedService.location_open,
      optionService: this.$store.getters.selectedService.detail,
      loadingService: false,
      loaderService: {
        length: 1,
        height: '56px',
        margin: '0 0 16px 0px',
        width: '100%',
        type: 'list',
        radius: '10px'
      },
      loadingTime: true,
      selectedLocation: this.$store.getters.selectedLocation,
      timeService: {},
      duration: {},
      selectedTimeData: [],
      isDisabled: false,
      loadingButton: false,
      queryStaff: {
        datetime: '',
        search_column: ['location_id', 'service_id'],
        search_text: [],
        search_operator: ['=', '='],
        distinct_column: ['id', 'name', 'location_id', 'photo_xs', 'service_id']
      },
      styleList: '',
      slideAnimation: true
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    this.loading = true
    if (moment(this.date).isSame(new Date(Date.now()), 'date')) {
      window.setInterval(() => {
        this.date = moment(new Date(Date.now()))
      }, 1000)
    }
    this.optionService = this.$store.getters.selectedService.detail.map(x => ({ ...x, is_first: false }))
    this.optionService[0].is_first = true
    this.duration = this.optionService[0].duration
    this.loading = false
    this.delay(100)
    this.slideAnimation = false
    this.getTimeList()
  },
  methods: {
    backStepsTo () {
      const serviceNew = {
        date: this.$store.getters.selectedService.date,
        detail: this.$store.getters.selectedService.detail,
        group: this.$store.getters.selectedService.group,
        list_date: this.$store.getters.selectedService.list_date,
        location_open: this.$store.getters.selectedService.location_open,
        selected: this.$store.getters.selectedService.selected
      }
      this.$store.commit('SET_SELECTED_TIMES_START', null)
      this.$store.commit('SET_SELECTED_SERVICES', { ...serviceNew })
      this.$store.dispatch('setActiveTab', 'services')
    },

    getListDate () {
      this.loadingDate = true
      const limit = 5
      const data = []
      let selectedDate = false
      for (let i = 0; i < limit; i++) {
        const newDate = moment(this.selectedDate).add(i, 'days')
        if (i === 0) {
          selectedDate = true
        } else {
          selectedDate = false
        }
        data.push({
          moment: newDate,
          selected: selectedDate
        })
      }
      if (!this.isOpenLocation && this.selectedDate.isSame(this.date, 'date')) {
        data[0].selected = 'block'
        data[1].selected = true
      }
      this.getTimeList()
      this.listDate = data
      this.loadingDate = false
    },

    changingDateHeader (key, list) {
      this.$store.commit('SET_SELECTED_TIMES_START', null)
      for (let i = 0; i < list.length; i++) {
        if (i === key) {
          list[i].selected = true
          this.selectedDate = list[i].moment
        } else {
          if (list[i].selected !== 'block') {
            list[i].selected = false
          }
        }
      }
      this.getTimeList()
    },

    changingDate (date) {
      this.$store.commit('SET_SELECTED_TIMES_START', null)
      const data = []
      this.listDate.map(x => {
        if (x.selected !== 'block') {
          x.selected = false
        }
        if (x.moment.isSame(date, 'date')) {
          x.selected = true
          data.push(x)
        }
      })
      this.selectedDate = moment(date)
      if (data.length <= 0) {
        this.getListDate()
      } else {
        this.getTimeList()
      }
    },

    selectedService (key) {
      this.loadingService = true
      this.optionService.map(x => {
        x.is_first = false
      })
      this.selectedTimeData = []
      const data = this.$store.getters.selectedService
      if (data.time_selected) {
        delete data.time_selected
        this.$store.commit('SET_SELECTED_SERVICES', data)
      }
      this.optionService[key].is_first = true
      this.duration = this.optionService[key].duration
      this.loadingService = false
      this.getTimeList()
    },

    getTimeList () {
      this.loadingTime = true
      this.selectedTimeData = []
      let first = {}
      this.optionService.map(x => { if (x.is_first) first = x })
      const timeZone = moment(this.selectedDate)
      const params = {
        booking_date: moment(this.selectedDate).format('YYYY-MM-DD'),
        duration: first.duration.strtotime,
        service_id: first.service_id,
        zoneCode: timeZone.format('ZZ')
      }
      fetchListTime(this.selectedLocation.id, params).then(response => {
        this.timeService = {
          id: first.id,
          time: response.data.data
        }
        if (!this.$store.getters.selectedTimeStart) {
          this.loadingTime = false
        } else {
          this.selectedTime(this.$store.getters.selectedTimeStart)
        }
      }).catch((error) => {
        this.timeService = {
          id: first.id,
          time: {
            time: []
          }
        }
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
        this.loadingTime = false
      })
    },

    async selectedTime (times) {
      this.loadingTime = true
      const date = moment(this.selectedDate).format('YYYY-MM-DD')
      const tempTime = []
      const history1 = times
      const history2 = []
      const clocks = moment(date + ' ' + times[0], 'YYYY-MM-DD HH:mm').toDate()
      for (var x = 0; x <= this.duration.strtotime; x += 900) {
        const list = moment(clocks).add(x, 'seconds').toDate()
        tempTime.push(moment(list).format('HH:mm'))
        history2.push(moment(list).format('HH:mm'))
      }
      if (tempTime.length < times.length) {
        const last = moment(date + ' ' + tempTime[tempTime.length - 1], 'YYYY-MM-DD HH:mm').add(900, 'seconds').format('HH:mm')
        tempTime.push(last)
      }
      times = tempTime
      const data = this.setClassSelected(times, history1, history2, date)
      await this.delay(100)
      const result = this.timeService
      await result.time.time.map(x => {
        if (x.class) {
          delete x.class
        }
        data.map(y => {
          if (x.time === y.time) {
            x.class = y.class
            if (y.width) {
              x.width = y.width
            }
          }
        })
      })
      this.$store.commit('SET_SELECTED_TIMES_START', times)
      this.selectedTimeData = times
      this.checkIsDisbaledButton()
      this.timeService = result
      this.loadingTime = false
    },

    setClassSelected (times, history1, history2, date) {
      const data = []
      for (var i = 0; i < times.length; i++) {
        if (i === 0) {
          data.push({
            time: times[i],
            class: 'first-check'
          })
        } else if (i === (times.length - 1)) {
          let classTime = 'last-check'
          let width = 100
          if (history2.length < history1.length) {
            classTime = 'last-check-notfull'
            const start = moment(date + ' ' + history1[history1.length - 1], 'YYYY-MM-DD HH:mm')
            const end = moment(date + ' ' + times[times.length - 1], 'YYYY-MM-DD HH:mm')
            const difference = end.diff(start, 'minutes')
            if (difference > 0) {
              width = (difference / 15) * 100
            }
          }
          data.push({
            time: times[i],
            class: classTime,
            width: width
          })
        } else {
          data.push({
            time: times[i],
            class: 'middle-check'
          })
        }
      }
      return data
    },

    checkIsDisbaledButton () {
      if (this.selectedTimeData.length > 0) {
        this.isDisabled = false
      } else {
        this.isDisabled = true
      }
      this.handleResize()
    },

    handleResize () {
      let paddingValue = 16
      let height = window.innerHeight - 80
      if (!this.loading && this.selectedTimeData.length > 0) {
        paddingValue = 48
        height = window.innerHeight - 120
      }
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding-bottom: ' + paddingValue + 'px; smooth-scroll: behavior;'
    },

    async confirmStart () {
      this.loadingButton = true
      let params = {
        location_id: this.selectedLocation.id,
        cart_date: moment(this.selectedDate).format('YYYY-MM-DD'),
        cartDetail: [],
        source: 3
      }
      const selectedNew = this.$store.getters.selectedService
      selectedNew.date = this.selectedDate
      selectedNew.list_date = this.listDate
      selectedNew.time_selected = this.selectedTimeData
      selectedNew.detail = this.optionService
      if (this.optionService.length > 1) {
        const newData = await this.getFisrtService(this.optionService, selectedNew.detail, selectedNew.selected)
        selectedNew.detail = newData.new_detail
        selectedNew.selected = newData.new_selected
      }

      const detailNew = this.optionService.map(x => ({ ...x, start_time: '' }))
      const newParams = await this.setParamsCartDetail(detailNew, selectedNew.selected, params)
      params = newParams

      let cartToken = null
      if (Cookies.get('cart-token')) cartToken = Cookies.get('cart-token')
      buildCart(params, cartToken).then(response => {
        const storeCart = {
          cart: params,
          response: response.data.data
        }
        Cookies.set('cart-token', storeCart.response.cart_token, {
          expires: 1,
          sameSite: 'Lax',
          secure: false
        })
        this.$store.commit('SET_SELECTED_SERVICES', { ...selectedNew })
        this.$store.commit('SET_DATA_CART', { ...storeCart })
        this.loadingButton = false
        this.$store.dispatch('setActiveTab', 'summary')
      }).catch((error) => {
        Cookies.remove('cart-token')
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
        this.loadingButton = false
      })
    },

    async getFisrtService (option, detail, selected) {
      const result = {
        new_detail: null,
        new_selected: null
      }
      const first = option.filter(function (x) { return x.is_first === true })
      const notFirst = option.filter(function (x) { return x.is_first === false })
      const newDetail = first.concat(notFirst)
      const newSelected = []
      detail = newDetail
      await first.map(x => {
        selected.map(y => {
          if (x.service_price_id === y.service_price_id) {
            newSelected.push(y)
          }
        })
      })
      await notFirst.map(x => {
        selected.map(y => {
          if (x.service_price_id === y.service_price_id) {
            newSelected.push(y)
          }
        })
      })
      selected = newSelected.filter((thing, index, self) => index === self.findIndex((y) => (y.service_price_id === thing.service_price_id)))

      result.new_detail = detail
      result.new_selected = selected

      return result
    },

    async setParamsCartDetail (detail, selected, params) {
      const stringDate = params.cart_date
      let timeParam = moment(stringDate + ' ' + this.selectedTimeData[0], 'YYYY-MM-DD HH:mm')
      for (var i = 0; i < detail.length; i++) {
        if (i === 0) {
          detail[i].start_time = timeParam
        } else {
          if (detail[i - 1]) {
            const timeNext = moment(timeParam).add(detail[i - 1].duration.strtotime + 1800, 'seconds')
            detail[i].start_time = timeNext
          }
        }
      }

      await selected.map(x => {
        params.cartDetail.push({
          service_id: x.service_id,
          qty: x.qty,
          service_price_id: x.service_price_id,
          staff: []
        })
      })

      await params.cartDetail.map(x => {
        detail.map(y => {
          if (y.service_price_id === x.service_price_id) {
            x.staff.push({
              staff_id: 0,
              time: y.start_time.format('HH:mm')
            })
          }
        })
      })
      return params
    },

    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    }
  }
}
</script>
