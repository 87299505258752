import request from '../utils/request'
import store from '../store'

const endPoint = '/service'
const endPoint2 = '/treatmentType'

export function fetchList (query) {
  return request({
    url: store.getters.language + endPoint + '/home_list',
    method: 'get',
    params: query
  })
}

export function fetchListSearch (query) {
  return request({
    url: store.getters.language + endPoint + '/search',
    method: 'get',
    params: query
  })
}

export function fetchListTreatment (query) {
  return request({
    url: store.getters.language + endPoint2 + '/lists',
    method: 'get',
    params: query
  })
}
