<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <header-title :loading="false" :title="title"/>
        </div>
      </div>
      <div :style="'padding-bottom: 0px;'" class="cz-body">
        <div v-if="location.length" class="content" :style="styleList">
          <transition name="fade-time">
            <at-card v-if="!loading" :body-style="{ padding: 0 }" :bordered="false" class="banner-location-company cz-border">
              <div class="cage">
                <div v-if="company.photo" :style="{ backgroundImage: 'url(' + company.photo + ')'}" class="company-logo"/>
                <div v-if="!loadingList" :style="{ backgroundImage: 'url(' + photos.medium + ')'}" class="company-location"/>
                <div v-else class="company-location"/>
                <div style="padding: 32px 16px 16px;">
                  <div class="cz-text-center cz-text-20 cz-bold">{{ company.name }}</div>
                  <div class="cz-text-center cz-text-xs cz-color-info">{{ company.description }}</div>
                </div>
              </div>
            </at-card>
          </transition>
          <loading-page :loading="loading && location.length" :data-loading="loadingBanner"/>

          <transition name="fade-time">
            <div v-if="!loadingList" class="cz-form-fit h40 cz-mb-16">
              <!--v-if="maxShow > 5"-->
              <at-input
                v-model="keyword"
                placeholder="Search"
                @keyup.native.enter="searchingLocation(keyword)">
                <template v-if="keyword !== ''" slot="append">
                  <i class="icon icon-x" @click="searchingLocation('')"/>
                </template>
              </at-input>
            </div>
          </transition>
          <transition name="fade-time">
            <template v-if="!loadingList">
              <locations
                :my-coordinate="myLocation"
                :list="location"
                :cities="listCity"
                :max-show="maxShow"
                :loading-list="loadingList"
                @selected="showButton"
              />
            </template>
          </transition>

          <loading-page :loading="loadingList && location.length" :data-loading="dataLoadingList"/>

          <loading-page :loading="loadingList && location.length" :data-loading="loadingButtonMore"/>

          <transition name="slide-test-up">
            <div v-if="isShowButton" class="cz-btn-bottom-book">
              <div class="cz-w-fit cz-boxshadow-btn" ref="button_float">
                <at-button
                  :loading="loadingButton"
                  type="success"
                  class="cz-btn block h56 text-lg"
                  @click="confirm(true)">
                  {{ $t('label.book') }}
                </at-button>
              </div>
            </div>
          </transition>
        </div>
        <div v-else>
          <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
            <img src="../../icons/svg/location-sm.svg" height="100" width="100">
            <p></p>
            <div class="cz-bold cz-text-lg">{{ $t('messages.locationNull') }}</div>
          </div>
        </div>
      </div>

      <loading-opacity :loading="loadingButton"/>
    </div>
  </transition>
</template>

<script>
import Locations from './list'
import { fetchList, fetch } from '../../api/locations'
import { fetchCompany } from '../../api/company.js'
import loadingPage from '../../components/UI/loading'
import loadingOpacity from '../../components/UI/loading/loadingOpacity'
import headerTitle from '../../components/UI/title'
import moment from '../../utils/moment'
import axios from 'axios'
export default {
  name: 'ListLocations',
  components: {
    Locations,
    loadingPage,
    headerTitle,
    loadingOpacity
  },

  data () {
    return {
      myLocation: {},
      location: [],
      listCity: [],
      backUpLocation: [],
      backUpCity: [],
      loading: true,
      title: this.$t('label.selectLocation'),
      listQuery: {
        search_column: ['company_id', 'enable_online_booking'],
        search_operator: ['='],
        search_text: [null, 1],
        sort_column: 'name',
        sort_type: 'asc'
      },
      dayNow: new Date().getDay(),
      company: null,
      photos: [],
      keyword: '',
      loadingList: true,
      dataLoadingList: {
        length: 5,
        type: 'list',
        radius: '3px',
        margin: '0 0 1px 0',
        height: '56px'
      },
      loadingBanner: {
        length: 1,
        type: 'list',
        radius: '10px',
        margin: '0 0 16px 0',
        height: '150px'
      },
      loadingButtonMore: {
        length: 1,
        type: 'list',
        radius: '3px',
        margin: '0 24 0px 0',
        height: '40px'
      },
      windows: {
        width: 0,
        height: 0
      },
      styleList: '',
      maxShow: 5,
      isShowButton: false,
      loadingButton: false,
      slideAnimation: true
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    await this.delay(100)
    this.slideAnimation = false
    this.getCompany(this.$store.getters.datapage.unique_code)
  },

  methods: {
    getMyLocation () {
      navigator.geolocation.getCurrentPosition(position => {
        this.myLocation = {
          lat: position.coords.latitude,
          lon: position.coords.longitude
        }
        let data = { geolocation: null, not_allow_location: 1 }
        if (this.$store.getters.dataUser) {
          data = { ...this.$store.getters.dataUser, ...data }
        }
        data.geolocation = this.myLocation
        this.$store.commit('SET_DATA_USER', data)
      }, error => {
        let data = { not_allow_location: 0 }
        if (this.$store.getters.dataUser) {
          data = { ...this.$store.getters.dataUser, ...data }
        }
        this.$store.commit('SET_DATA_USER', data)
        this.$Message.error({
          message: error.message,
          icon: 'icon-alert-circle'
        })
      })
    },

    getCompany (uniqueId) {
      this.loading = true
      fetchCompany(uniqueId).then(response => {
        this.company = response.data.data
        this.$store.commit('SET_COMPANY', this.company)
        this.checkChooslocations()
      }).catch((error) => {
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
        this.loading = false
        this.loadingList = false
      })
    },

    checkChooslocations () {
      const query = {
        for: 3,
        company_identification: this.company.id
      }
      this.$store.dispatch('setDataPayment', query)
      if (this.$store.getters.datapage.chooselocation) {
        this.getLocation()
      } else {
        this.getMyLocation()
        this.confirm(this.$store.getters.datapage.chooselocation)
      }
    },

    getLocation () {
      this.listQuery.search_text[0] = this.company.id
      fetchList(this.listQuery).then(response => {
        const data = response.data.data.map(x => ({ ...x, selected: false, is_open: '', working_hours: null, more_address: false }))
        this.filterByDay(data)
      }).catch((error) => {
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
        this.location = []
        this.backUpLocation = []
        this.loading = false
        this.loadingList = false
      })
    },

    async filterByDay (data) {
      const results = []
      if (this.dayNow === 0) {
        this.dayNow += 1
      }
      await data.map(x => {
        x.regularWorkingHours.map(y => {
          if (parseInt(x.status) === 1) {
            if (parseInt(y.day) === this.dayNow) {
              x.working_hours = y
              if (x.working_hours !== null) {
                results.push(x)
              }
            }
          }
        })
      })
      if (results.length > 0) {
        this.getPhotos(results)
      } else {
        this.loading = false
        this.loadingList = false
      }
    },

    async getPhotos (data) {
      const newData = data.filter(function (x) { return x.photo_id !== null })
      const results = []
      await newData.map(x => {
        if (x.photo) {
          results.push({
            medium: x.photo,
            small: x.photo_sm,
            extra_small: x.photo_xs
          })
        }
      })
      if (results.length <= 0) {
        results.push({
          medium: data[0].photo,
          small: data[0].photo_sm,
          extra_small: data[0].photo_xs
        })
      }
      this.photos = results[0]
      this.getFromHistory(data)
    },

    async searchingLocation (keyword) {
      this.loadingList = true
      this.isShowButton = false
      this.location.map(x => {
        x.selected = false
      })
      if (keyword !== '') {
        const data = this.location.filter(function (x) {
          return x.name.toLowerCase().includes(keyword.toLowerCase())
        })
        const dataCity = []
        await this.listCity.map(x => {
          data.map(y => {
            if (x.address_level2 === y.leveling_address.address_level2) dataCity.push(x)
          })
        })

        this.listCity = dataCity
        this.location = data
        if (data.length <= 0) {
          this.listCity = this.backUpCity
          this.location = this.backUpLocation
          this.$Message.error({
            message: this.$t('messages.locationNotFound'),
            icon: 'icon-x'
          })
        }
        await this.delay(300)
        this.loadingList = false
      } else {
        this.keyword = ''
        this.location = this.backUpLocation
        this.listCity = this.backUpCity
        await this.delay(300)
        this.loadingList = false
      }
      const checked = this.location.filter(function (x) { return x.selected === true })
      if (checked.length > 0) {
        this.isShowButton = true
      }
    },

    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },

    showAllList () {
      this.loadingList = true
      this.maxShow = this.location.length
      this.delay(200)
      this.handleResize()
      this.loadingList = false
    },

    handleResize () {
      this.windows.width = window.innerWidth
      this.windows.height = window.innerHeight
      let paddingValue = 24
      let height = this.windows.height - (56 + 16)
      if (this.isShowButton) {
        paddingValue = 80
      }
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding-bottom: ' + paddingValue + 'px; smooth-scroll: behavior;'
    },

    showButton () {
      this.isShowButton = false
      const data = this.location.filter(function (x) { return x.selected === true })
      if (data.length > 0) {
        this.isShowButton = true
      }
      this.handleResize()
    },

    async getFromHistory (results) {
      this.location = []
      this.backUpLocation = []
      const data = results
      if (this.$store.getters.selectedLocation) {
        data.map(x => {
          if (this.$store.getters.selectedLocation.id === x.id) {
            x.selected = true
          }
        })
        this.maxShow = data.length
        this.isShowButton = true
        this.handleResize()
      }
      await data.map(x => {
        if (x.location_lat && x.location_lat) {
          x.leveling_address = {}
          this.location.push(x)
        }
      })
      this.setMarker(this.location)
      this.loading = false
    },

    async setMarker (list) {
      const temps = []
      const errors = []
      for (var i = 0; i < list.length; i++) {
        let results = {}
        await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            latlng: list[i].location_lat + ',' + list[i].location_lon,
            key: 'AIzaSyA2Dt2etiCUW4cnw38iES-O8T7yPPIA680'
          }
        }).then(response => {
          const data = response.data.results[0]
          results.lat = data.geometry.location.lat
          results.lon = data.geometry.location.lng
          for (const component of data.address_components) {
            if (component.types[0] === 'postal_code') results.zipcode = component.long_name
            if (component.types[0] === 'administrative_area_level_4') results.address_level4 = component.long_name
            if (component.types[0] === 'administrative_area_level_3') results.address_level3 = component.long_name
            if (component.types[0] === 'administrative_area_level_2') results.address_level2 = component.long_name.replace('é', 'e')
            if (component.types[0] === 'administrative_area_level_1') results.address_level1 = component.long_name
            if (component.types[0] === 'country') results.country = component.long_name
          }
        }).catch(error => {
          errors.push(error)
        })
        list[i].leveling_address = results
        temps.push(results)
      }
      this.backUpLocation = list
      const finalResult = temps.filter((thing, index, self) =>
        index === self.findIndex((y) => (
          y.address_level2 === thing.address_level2
        ))
      )
      this.getMyLocation()
      this.listCity = finalResult
      this.backUpCity = finalResult
      this.loadingList = false
    },

    async confirm (choose) {
      this.loadingButton = true
      if (choose) {
        const data = this.location.filter(function (x) { return x.selected === true })
        if (data.length > 0) {
          this.$store.commit('SET_SELECTED_LOCATION', { ...data[0] })
        }
        await this.delay(500)
        this.$store.dispatch('setActiveTab', 'detail-location')
      } else {
        const date = moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm:ss')
        fetch(this.$store.getters.datapage.selectedLocation, date).then(response => {
          const data = response.data.data
          data.regularWorkingHours.map(x => {
            if (this.dayNow === 0) this.dayNow += 1
            if (x.day === this.dayNow) data.working_hours = x
          })
          this.$store.commit('SET_SELECTED_LOCATION', { ...data })
          this.$store.dispatch('setActiveTab', 'detail-location')
          this.loadingButton = false
        }).catch((error) => {
          this.$Message.error({
            message: error.string,
            icon: 'icon-x'
          })
          this.loadingButton = false
          this.location = []
          this.backUpLocation = []
        })
      }
    }
  }
}
</script>
