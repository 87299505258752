<template>
  <div
    v-if="filterGroup.length > 0 && !loading"
    :style="styleList"
    class="cz-w-fit" >
    <div v-for="(group, key) in filterGroup" :key="key">
      <at-card class="banner cz-border">
        <div class="row at-row flex-center flex-middle cz-m-null">
          <div class="col-5 cz-p-null">
            <div
              :style="{ backgroundImage: 'url(' + group.serviceGroup.photo + ')' }"
              class="img-thumb-48 img-thumb-circle cz-border"
            />
          </div>
          <div class="col cz-p-null">
            <div class="cz-bold cz-color cz-text-left" style="font-size: 20px;">{{ group.serviceGroup.name }}</div>
            <div class="cz-color-info cz-text-left" style="font-size: 10px;">{{ group.description }}</div>
          </div>
        </div>
      </at-card>
      <div v-for="(service, n) in services" :key="n">
        <at-button
          v-if="service.service_group_id === group.service_group_id"
          :type="service.qty > 0 ? 'info' : ''"
          :style="service.qty > 0 ? 'border-radius: 10px; margin: 4px 0px;' : 'border-radius: 0px'"
          class="cz-btn block cz-border-bottom">
          <div class="row at-row flex-center flex-middle cz-m-null">
            <div class="col-19 cz-p-null cz-text-left">
              <div class="cz-bold cz-text-sm cz-color">{{ service.name }}</div>
              <div :class="service.qty > 0 ? 'cz-color' : 'cz-color-info'" class="cz-nobold cz-text-sm">
                {{ replaceDuration(service.duration.desc) }}
                <div :class="service.qty > 0 ? 'cz-bg-black' : 'cz-bg-info'" class="cz-dot-8 cz-bg-info" />
                {{ replaceGender(service.gender) }}
              </div>
              <div
                :class="getClassPrice(service.qty, service.special_price)"
                class="cz-nobold cz-text-sm cz-inline-block">
                {{ currency }} {{ service.fretail_price.replace(',00', '') }}
              </div>
              &nbsp;
              <template v-if="service.special_price > 0">
                <div
                  :class="service.qty > 0 ? 'cz-color' : 'cz-color-info'"
                  class="cz-nobold cz-text-sm cz-inline-block">
                  {{ currency }} {{ service.fspecial_price.replace(',00', '') }}
                </div>
              </template>
            </div>
            <div class="col-5 cz-p-null cz-text-right">
              <template v-if="service.qty <= 0">
                <at-button icon="icon-plus" circle type="success" class="cz-btn increament" @click="increase(service, 0, n)"/>
              </template>
              <template v-else>
                <at-button icon="icon-minus" circle type="info" class="cz-btn increament" @click="increase(service, 1, n)"/>
                <at-button type="info" class="cz-btn cz-p-null">
                  <span class="cz-text-md">{{ service.qty }}</span>
                </at-button>
                <at-button icon="icon-plus" circle type="info" class="cz-btn increament" @click="increase(service, 0, n)"/>
              </template>
            </div>
          </div>
        </at-button>
      </div>
      <div class="cz-mb-16"/>
    </div>
  </div>

  <div v-else>
    <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); text-align: center;">
      <img src="../../icons/svg/service.svg" height="100" width="100">
      <p></p>
      <div class="cz-bold cz-text-lg">{{ $t('messages.serviceNull') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListService',
  props: ['serviceGroup', 'styleCss', 'services', 'dataFilter', 'loading'],

  computed: {
    idForSearch () {
      return this.dataFilter
    },
    styleList () {
      let data = this.styleCss
      return data
    },
    currency () {
      return this.$store.getters.company.currency.symbol
    },
    filterGroup () {
      let data = []
      if (this.dataFilter || this.dataFilter !== 0) {
        this.serviceGroup.map(x => {
          if (this.dataFilter === x.service_group_id) {
            data.push(x)
          }
        })
        return data
      } else {
        data = this.serviceGroup
      }
      return data
    }
  },

  methods: {
    getClassPrice (qty, specialPrice) {
      if (specialPrice > 0 && qty > 0) return 'cz-color cz-text-line'
      if (specialPrice > 0 && qty <= 0) return 'cz-color-info cz-text-line'
      if (specialPrice <= 0 && qty > 0) return 'cz-color'
      if (specialPrice <= 0 && qty <= 0) return 'cz-color-info'
    },
    replaceDuration (time) {
      let newData = ''
      if (time.includes('h')) {
        newData = time.replace('h', this.$t('duration.h'))
        return newData
      } else {
        return time
      }
    },
    replaceGender (code) {
      if (code === 'f') {
        return this.$t('gender.f')
      } else if (code === 'm') {
        return this.$t('gender.m')
      } else {
        return this.$t('gender.e')
      }
    },
    increase (service, type, key) {
      let data = service.qty
      if (type === 1) {
        data -= 1
      } else if (type === 0) {
        data += 1
      }

      service.qty = data
      this.$emit('selected')
    }
  }
}
</script>
