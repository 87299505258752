<template>
  <transition name="fade-time">
    <div v-if="loading" class="cz-container-loading" style="max-width: 632px; top: 0;
    left: 50%; transform: translate(-50%, 0%);">
      <div class="cz-ring-loading">
        <div class="cz-item-loading"/>
        <div class="cz-item-loading"/>
        <div class="cz-item-loading"/>
        <div class="cz-item-loading"/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loadingCircle',
  props: ['loading']
}
</script>
