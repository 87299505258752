<template>
  <div id="app">
    <!-- <router-view /> -->
    <div v-if="activeTab === 'locations'">
      <locations />
    </div>
    <div v-else-if="activeTab === 'detail-location'">
      <detail-location />
    </div>
    <div v-else-if="activeTab === 'services'">
      <services />
    </div>
    <div v-else-if="activeTab === 'my-location'">
      <my-location />
    </div>
    <div v-else-if="activeTab === 'pick-time'">
      <pick-time />
    </div>
    <div v-else-if="activeTab === 'summary'">
      <booking-summary />
    </div>
    <div v-else-if="activeTab === 'personal-detail'">
      <personal-detail />
    </div>
    <div v-else-if="activeTab === 'select-payments' || activeTab.name === 'select-payments'">
      <select-payments />
    </div>
    <div v-else-if="activeTab && activeTab.name === 'credit-card'">
      <credit-card />
    </div>
    <div v-else-if="activeTab.name === 'bank-transfer'">
      <bank-transfer />
    </div>
    <div v-else-if="activeTab.name === 'success'">
      <success />
    </div>
    <div v-else-if="activeTab.name === 'time-over'">
      <time-over />
    </div>
  </div>
</template>

<script>
import { getSystemToken } from './utils/auth'
import Cookies from 'js-cookie'

import Locations from './views/Locations/index'
import DetailLocation from './views/DetailLocation/index'
import Services from './views/Services/index'
import MyLocation from './views/MyLocation/index'
import PickTime from './views/PickTime/index'
import BookingSummary from './views/Summary/index'
import PersonalDetail from './views/PersonalDetails/index'
import SelectPayments from './views/SelectPayment/index'
import CreditCard from './views/SelectPayment/credit-card'
import BankTransfer from './views/SelectPayment/transfer'

export default {
  props: ['title', 'chooselocation', 'selectedlocation', 'comid', 'uniquecode', 'lang'],

  components: {
    Locations,
    DetailLocation,
    Services,
    MyLocation,
    PickTime,
    BookingSummary,
    PersonalDetail,
    SelectPayments,
    CreditCard,
    BankTransfer
  },

  data () {
    return {
      enableUserChooseLocation: true,
      totalSteps: 4,
      activeSteps: 1,
      selected_location: 0,
      comp_id: 0
    }
  },

  computed: {
    activeTab () {
      // console.log(this.$store.getters.activeTab)
      return this.$store.getters.activeTab
    },

    hasToken () {
      return this.$store.state.user.system_token
    }
  },

  watch: {
    chooselocation: {
      immediate: true,
      handler (data) {
        if (data === 'yes') {
          this.enableUserChooseLocation = true
          this.totalSteps = 4
        } else {
          this.enableUserChooseLocation = false
          this.totalSteps = 3
        }
      }
    },
    comid: {
      handler (data) {
        this.compid = data
      }
    },
    selectedlocation: {
      immediate: true,
      handler (data) {
        this.selected_location = data
      }
    }
  },

  async mounted () {
    Cookies.remove('cart-token')
    if (this.lang) {
      this.$store.commit('SET_LANGUAGE', this.lang)
    }
    const data = {
      chooselocation: this.enableUserChooseLocation,
      selectedLocation: this.selectedlocation,
      unique_code: this.comid
    }
    await this.$store.dispatch('setListBank')
    await this.$store.dispatch('setDatapage', data)
    console.log(getSystemToken())
    if (!getSystemToken()) {
      this.$store.dispatch('GetSystemAccessToken').then(() => {
        this.switchTab('locations')
      })
    } else {
      this.switchTab('locations')
    }
  },

  methods: {
    switchTab (tab) {
      this.$store.dispatch('setActiveTab', tab)
    }
  }
}
</script>
