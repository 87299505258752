<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div v-if="chooseLocation" class="back-step" @click="backStep">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="false" :title="title"/>
        </div>
      </div>
      <div :style="'padding-bottom: 0px;'" class="cz-body">
        <div  class="content" :style="styleList">
          <transition name="fade-time">
            <at-card ref="banner" v-if="!loading" :body-style="{ padding: 0 }" :bordered="false" class="banner-location-detail cz-border">
              <div class="cage cz-post-relative">
                <widget-carousel :slides="dataPhotoSlider"/>
                <div v-if="company.photo" :style="{ backgroundImage: 'url(' + company.photo + ')'}" class="company-logo"/>
                <div v-else class="company-location"/>
                <div class="cz-p-16">
                  <div class="cz-text-left cz-text-20 cz-bold cz-mb-4">{{ location.name.replace('amp;', '') }}</div>
                  <div v-if="statusLocation" :class="statusLocation.class" class="cz-text-left cz-text-sm cz-mb-4 cz-bold">
                    {{ location.working_hours.shift_start_time }}
                    -
                    {{ location.working_hours.shift_end_time }}
                    <span
                      :class="statusLocation.button"
                      class="cz-radius-pill cz-text-xs cz-nobold"
                      style="padding: 4px 10px;">
                      {{ $t('label.' + statusLocation.string) }}
                    </span>
                  </div>
                  <template v-if="location.distance && location.distance.genuine">
                    <div
                      v-if="location.address.length > 37"
                      :style="showMoreAddress ? 'white-space: normal;' : 'white-space: nowrap'"
                      class="cz-text-left cz-text-xs cz-color-info">
                      <template v-if="!showAddress">
                        {{ location.distance.format }}{{ location.distance.type }}
                        <span class="cz-color-dark-info">{{ ' • ' }}</span>
                        {{ setAddress(location) }}
                      </template>
                      <template v-else>
                        {{ location.distance.format }}{{ location.distance.type }} {{ location.address }}
                      </template>
                      <at-button
                        v-if="!showAddress"
                        type="text"
                        class="btn cz-p-null cz-color-success cz-nobold"
                        @click="showMoreAddress">
                         ...
                      </at-button>
                      <at-button
                        v-else
                        type="text"
                        class="btn cz-p-null cz-color-success cz-nobold"
                        @click="showMoreAddress">
                         hide
                      </at-button>
                    </div>
                    <div v-else class="cz-text-left cz-text-xs cz-color-info">
                      {{ location.address }}
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="location.address.length > 50"
                      :style="showMoreAddress ? 'white-space: normal;' : 'white-space: nowrap'"
                      class="cz-text-left cz-text-xs cz-color-info">
                      <template v-if="!showAddress">
                        {{ setAddress(location) }}
                      </template>
                      <template v-else>
                        {{ location.address }}
                      </template>
                      <at-button
                        v-if="!showAddress"
                        type="text"
                        class="btn cz-p-null cz-color-success cz-nobold"
                        @click="showMoreAddress">
                         ...
                      </at-button>
                      <at-button
                        v-else
                        type="text"
                        class="btn cz-p-null cz-color-success cz-nobold"
                        @click="showMoreAddress">
                         hide
                      </at-button>
                    </div>
                    <div v-else class="cz-text-left cz-text-xs cz-color-info">
                      {{ location.address }}
                    </div>
                  </template>
                </div>
              </div>
            </at-card>
          </transition>
          <loading-page :loading="loading" :data-loading="loadingBanner"/>

          <div>
            <transition name="fade-time">
              <div v-if="!loading" class="list-group inline nowrap cz-mb-8">
                <div v-for="(icon, x) in iconButton" :key="x" class="list-items">
                  <at-button
                    circle
                    @click="showingDialog(x, icon)">
                    <i class="at-btn__icon">
                      <svg-icon :icon-class="icon" :width="22" :height="22"/>
                    </i>
                  </at-button>
                  <div class="cz-color-info" style="font-size: 10px;">
                    <template v-if="icon === 'home'">{{ $t('label.facility') }}</template>
                    <template v-else-if="icon === 'phone'">{{ $t('label.contact') }}</template>
                    <template v-else>{{ $t('label.' + icon) }}</template>
                  </div>
                </div>
              </div>
            </transition>
            <loading-page :loading="loading" :data-loading="loadingTrigger"/>

            <div
              v-if="!loading"
              class="cz-w-fit cz-text-sm cz-text-center cz-mb-8">
              {{ $t('tips.tipsAppointmentTakePlace') }}
            </div>

            <div class="cz-mb-16"/>
            <transition name="fade-time">
              <at-button v-if="!loading" class="cz-btn cz-p-null cz-border-null block cz-hover-info" @click="setReference(0)">
                <div
                  class="row at-row flex-center flex-middle cz-border cz-radius-3 cz-m-null"
                  style="padding: 8px 10px;">
                  <div class="col-3 cz-text-left cz-p-null">
                    <img src="../../icons/svg/location-sm.svg" width="36" height="36"/>
                  </div>
                  <div class="col cz-text-left">
                    <span class="cz-text-sm cz-bold">
                      {{ $t('label.onServiceProvider') }}
                    </span>
                  </div>
                  <div class="col-3">
                    <div class="cz-text-right cz-text-lg cz-color-info">
                      <i class="icon icon-chevron-right"/>
                    </div>
                  </div>
                </div>
              </at-button>
            </transition>
            <loading-page :loading="loading" :data-loading="loadingButton"/>

            <div class="cz-mb-16"/>
            <template v-if="company.enable_provide_service_at_customer_location !== 0 && company.enable_provide_service_at_customer_location && location.enable_provide_service_at_customer_location !== 0">
              <transition name="fade-time">
                <at-button v-if="!loading" class="cz-btn cz-p-null cz-border-null block cz-hover-info" @click="setReference(1)">
                  <div
                    class="row at-row flex-center flex-middle cz-border cz-radius-3 cz-m-null"
                    style="padding: 8px 10px;">
                    <div class="col-3 cz-text-left cz-p-null">
                      <img src="../../icons/svg/pin-1.svg" width="32" height="32"/>
                    </div>
                    <div class="col cz-text-left">
                      <span class="cz-text-sm cz-bold">
                        {{ $t('label.onMyLocation') }}
                      </span>
                    </div>
                    <div class="col-3">
                      <div class="cz-text-right cz-text-lg cz-color-info">
                        <i class="icon icon-chevron-right"/>
                      </div>
                    </div>
                  </div>
                </at-button>
              </transition>
              <loading-page :loading="loading" :data-loading="loadingButton"/>
            </template>

            <div class="cz-mb-16"/>
            <template v-if="oldCustomer">
              <transition name="fade-time">
                <at-button v-if="!loading && Object.keys(oldCustomer).length > 3" class="cz-btn cz-p-null cz-border-null block cz-hover-info">
                    <div
                      class="row at-row flex-center flex-middle cz-border cz-radius-3 cz-m-null"
                      style="padding: 8px 10px;">
                      <div class="col cz-text-left cz-p-null">
                        <div class="cz-text-sm cz-bold">
                          Hi <span class="cz-text-capital">{{ oldCustomer.name }}</span>
                        </div>
                        <div class="cz-text-xs cz-color-info cz-text-overflow-wrap">
                          {{ $t('label.onSwitchBookBy') }}
                          <span class="cz-text-capital">{{ oldCustomer.name }}</span>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="switch-customer">
                          <div class="middle-line">
                            <at-switch v-model="checkUser" @change="useOldUser"/>
                          </div>
                        </div>
                      </div>
                    </div>
                </at-button>
              </transition>
              <loading-page :loading="loading" :data-loading="loadingButton"/>
            </template>
          </div>
        </div>
      </div>

      <dialog-detail
        :show="openDialog"
        :width-content="350"
        :min-height-content="'unset'"
        :content="contentDialog"
        :icon-button="iconButton"
        :data-collect="dataCollect"
        @close="closingDialog"
        @change="showingDialog"
      />
    </div>
  </transition>
</template>

<script>
import widgetCarousel from '../../components/UI/Carousel'
import loadingPage from '../../components/UI/loading'
import headerTitle from '../../components/UI/title'
import dialogDetail from './dialog'
import moment from '../../utils/moment.js'
import { fetchList } from '../../api/services.js'
export default {
  name: 'DetailLocation',
  components: {
    loadingPage,
    headerTitle,
    widgetCarousel,
    dialogDetail
  },

  data () {
    return {
      chooseLocation: this.$store.getters.datapage.chooselocation,
      loading: true,
      title: this.$t('label.onlineBooking'),
      location: this.$store.getters.selectedLocation,
      company: this.$store.getters.company,
      showAddress: false,
      loadingBanner: {
        length: 1,
        height: '200px',
        margin: '0 0 16px 0',
        type: 'list',
        radius: '10px'
      },
      loadingTrigger: {
        wrap: false,
        length: 5,
        height: '56px',
        width: '56px',
        margin: '0 8px 16px 4px',
        type: 'list',
        radius: '50%'
      },
      loadingButton: {
        length: 1,
        height: '56px',
        margin: '0 0',
        type: 'list',
        radius: '3px'
      },
      iconButton: [],
      date: null,
      workingHours: this.$store.getters.selectedLocation.working_hours,
      statusLocation: null,
      contentDialog: null,
      openDialog: false,
      classData: [],
      serviceData: [],
      facilityData: this.$store.getters.selectedLocation.facility,
      contactData: {
        email: this.$store.getters.selectedLocation.email,
        phone: this.$store.getters.selectedLocation.phone_no
      },
      dataCollect: null,
      checkUser: false,
      oldCustomer: JSON.parse(localStorage.getItem('user')),
      styleList: '',
      slideAnimation: true
    }
  },

  computed: {
    iconGender () {
      let results = ''
      const data = this.location.gender
      if (data === 'e') {
        results = 'unisex'
      } else if (data === 'f') {
        results = 'female'
      } else {
        results = 'male'
      }
      return results
    },
    dataPhotoSlider () {
      let results = []
      if (this.location.photo_location.length > 0) {
        results = this.location.photo_location
      } else {
        results = [{ photo: this.location.photo }]
      }
      return results
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
      this.getStatusLocation()
    }, 1000)
    await this.delay(300)
    this.slideAnimation = false
    this.iconButton = [this.iconGender, 'service', 'class', 'home', 'phone']
    this.getService()
  },
  methods: {
    backStep () {
      this.$store.dispatch('setActiveTab', 'locations')
    },
    handleResize () {
      let paddingValue = 0
      let height = window.innerHeight - 56
      paddingValue = 64
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding-bottom: ' + paddingValue + 'px; smooth-scroll: behavior;'
    },
    getStatusLocation () {
      const dateString = moment(this.date).format('YYYY-MM-DD')
      const start = moment(dateString + ' ' + this.workingHours.shift_start_time, 'YYYY-MM-DD HH:mm').subtract(15, 'seconds')
      const end = moment(dateString + ' ' + this.workingHours.shift_end_time, 'YYYY-MM-DD HH:mm').add(15, 'seconds')
      const opening = moment(dateString + ' ' + this.workingHours.shift_start_time, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
      const closing = moment(dateString + ' ' + this.workingHours.shift_end_time, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
      let results = {}
      if (this.date.isBetween(start, end)) {
        if (this.date.isBetween(closing, end)) {
          results = {
            status: 2,
            string: 'closeSoon',
            class: 'cz-color-warning',
            button: 'cz-bg-warning cz-color-white'
          }
        } else {
          results = {
            status: 1,
            string: 'openNow',
            class: 'cz-color-success',
            button: 'cz-bg-success cz-color-white'
          }
        }
      } else {
        if (this.date.isBetween(opening, start)) {
          results = {
            status: 0,
            string: 'openSoon',
            class: 'cz-color-info',
            button: 'cz-bg-info cz-color'
          }
        } else {
          results = {
            status: 3,
            string: 'close',
            class: 'cz-color-info',
            button: 'cz-bg-info cz-color'
          }
        }
      }
      this.statusLocation = results
    },

    getService () {
      this.loading = true
      const params = {
        search_column: ['company_id', 'location_id'],
        search_text: [this.company.id, this.location.id],
        per_page: 100
      }
      fetchList(params).then(response => {
        this.serviceData = response.data.data
        this.loading = false
      }).catch((error) => {
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
        this.loading = false
      })
    },

    setAddress (location) {
      const data = location.address.split('')
      let limiter = 36
      if (location.distance && !location.distance.genuine) {
        limiter = 49
      }
      if (data.length >= limiter) {
        data.splice(limiter, (data.length - 1))
      }
      const newData = data.join('')
      return newData
    },

    showMoreAddress () {
      if (this.showAddress) {
        this.showAddress = false
      } else {
        this.showAddress = true
      }
    },

    async showingDialog (key, content) {
      this.openDialog = false
      this.contentDialog = {
        index: key,
        string: content
      }
      if (key > 0) {
        if (key === 1) {
          this.dataCollect = this.serviceData
        }
        if (key === 2) {
          this.dataCollect = this.classData
        }
        if (key === 3) {
          this.dataCollect = this.facilityData
        }
        if (key === 4) {
          this.dataCollect = this.contactData
        }
      }
      await this.delay(300)
      this.openDialog = true
    },

    closingDialog () {
      this.openDialog = false
      this.contentDialog = null
    },

    useOldUser (value) {
      let data = this.oldCustomer
      data.useOldUser = value
      this.$store.commit('SET_DATA_USER', { ...data })
    },

    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },

    setReference (type) {
      let nameRouter = 'services'
      let data = {}
      if (this.$store.getters.dataUser) {
        data = this.$store.getters.dataUser
      }
      data.reference = type
      this.$store.commit('SET_DATA_USER', { ...data })
      this.$store.commit('SET_SERVICES', [ ...this.serviceData ])

      if (type === 1) {
        nameRouter = 'my-location'
      }
      this.$store.dispatch('setActiveTab', nameRouter)
    }
  }
}
</script>
