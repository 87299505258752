<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div v-if="!inReview" class="back-step" @click="backStepsTo(false)">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="loading" :title="$t('label.payment')"/>
        </div>
      </div>
      <div
        :class="showAuthCard ? 'cz-post-relative' : ''"
        :style="'padding-bottom: 0px'"
        class="cz-body">
        <transition name="fade-time">
          <div
            v-if="!loading"
            :style="!showAuthCard ? styleList : ''"
            class="content">
            <div class="service-new-list" style="padding: 0px 0px;">
              <at-card
                :no-hover="true"
                :body-style="{ padding: '24px 16px' }"
                class="cz-emboss cz-radius-10">
                <div class="row at-row no-gutter flex-middle cz-m-null">
                  <div class="col-xs-5 col-sm-3 col-md-3">
                    <i class="icon icon-credit-card cz-text-lg cz-color"/>
                  </div>
                  <div class="col-xs-19 col-sm-21 col-md-21">
                    <span class="cz-text-sm cz-color cz-bold">
                      {{ $t('label.creditcard') }}
                    </span>
                  </div>
                </div>
              </at-card>
              <div v-if="!inReview" class="cz-w-fit cz-text-right cz-color cz-mt-8 cz-text-xs cz-color-info">
                {{ $t('tips.tipsPleasePay') }} {{ timer }}
              </div>
            </div>
            <div v-if="!showAuthCard" class="cz-list-card list-staff">
              <form role="form" id="payment-form" class="cz-form-card">
                <label class="cz-form-label">
                  {{ $t('label.cardInformation') }}
                </label>
                <transition name="fade-time">
                  <div v-if="errorName" class="cz-w-fit cz-text-right cz-color-danger cz-text-xs">
                    {{ $t('label.required_name') }}
                  </div>
                </transition>
                <div class="cz-form-input">
                  <input
                    v-model="detail.name"
                    type="text"
                    :placeholder="$t('label.cardHolderName')"
                    class="cz-text-capital"
                    required
                    autocomplete="off"
                    @change="saveInput"
                    @keyup.enter="saveInput">
                </div>

                <transition name="fade-time">
                  <div v-if="errorCard" class="cz-w-fit cz-text-right cz-color-danger cz-text-xs">
                    {{ $t('label.required_card') }}
                  </div>
                </transition>
                <div class="cz-form-input validate">
                  <input
                    id="card-number"
                    v-model="detail.card_number"
                    type="text"
                    :placeholder="$t('label.cardNumber')"
                    autocomplete="off"
                    @keyup="splitCard(detail.card_number)"
                    @keyup.enter="splitCard(detail.card_number)">
                  <div class="cz-append img">
                    <img src="../../assets/img/mc56x40.png">
                  </div>
                </div>

                <transition name="fade-time">
                  <div v-if="errorDate" class="cz-w-fit cz-text-right cz-color-danger cz-text-xs">
                    {{ $t('label.required_valid') }}
                  </div>
                </transition>
                <div class="cz-form-input validate">
                  <input
                    v-model="detail.valid_date.fdate"
                    type="text"
                    :placeholder="$t('label.validThru')"
                    class="cz-cursor"
                    required
                    autocomplete="off"
                    @focus="modalvalid = true"
                    @keyup.enter="saveInput">
                  <div class="cz-append">
                    <i class="icon icon-calendar cz-cursor" @click="modalvalid = true"/>
                  </div>
                </div>

                <transition name="fade-time">
                  <div v-if="errorCCV" class="cz-w-fit cz-text-right cz-color-danger cz-text-xs">
                    {{ 'CCV ' + $t('label.required') }}
                  </div>
                </transition>
                <div class="cz-form-input validate">
                  <input id="card-cvn" v-model="detail.ccv" type="text" placeholder="CVV" autocomplete="off" @change="saveInput" @keyup.enter="saveInput">
                  <div class="cz-append tooltip">
                    <at-popover :content="$t('tips.ccvTips')" trigger="click">
                      <button class="btn-popover" type="button">?</button>
                    </at-popover>
                  </div>
                </div>
                <input id="card-exp-month" v-model="detail.valid_date.month" class="form-control" type="hidden"/>
                <input id="card-exp-year" v-model="detail.valid_date.year" class="form-control" type="hidden"/>

                <div class="cz-w-fit cz-p-8">
                  <div class="row at-row no-gutter flex-middle">
                    <div class="col-xs col-sm col-md">
                      <div class="cz-w-fit cz-overflow-text-dot">
                        <span class="cz-text-xs cz-color-info">
                          {{ $t('label.poweredBy') }}
                        </span>
                      </div>
                    </div>
                    <div class="col-xs col-sm col-md cz-text-right">
                      <img src="../../assets/img/vbyvisa-blu.png" height="24">
                      <img src="../../assets/img/master-code-new.png" height="24">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </transition>
        <loading-page :loading="loading" :data-loading="loaderDropdown"/>
        <loading-page :loading="loading" :data-loading="loader"/>

        <div
          ref="threeDSContainer"
          id="three-ds-container"
          class="cz-w-fit xendit-three-ds-container"
          style="visibility: hidden; opacity: 0; display: none;">
          <iframe
            ref="sampleInlineFrame"
            id="sample-inline-frame"
            name="sample-inline-frame"
            class="xendit-iframe"/>
        </div>
      </div>

      <transition name="fade-steady">
        <div v-if="modalvalid" class="cz-modal">
          <div class="cz-content-modal valid-date">
            <div class="frame-smooth-picker">
              <smooth-picker ref="smoothPicker" :data="dataDate" :change="dateChange"/>
            </div>

            <div class="footer">
              <div class="row at-row no-gutter flex-middle">
                <div class="col-md col-xs col-sm">
                  <at-button class="cancel" @click="cancelDate">
                    {{ $t('label.cancel') }}
                  </at-button>
                </div>
                <div class="col-md col-xs col-sm">
                  <at-button class="done" @click="saveDate">
                    {{ $t('label.done') }}
                  </at-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <loading-circle :loading="loadingPay"/>

      <transition name="slide-test-up">
        <div v-if="isActived" class="cz-btn-bottom-book">
          <div class="cz-w-fit">
            <button type="button" class="at-btn cz-btn block h56 text-lg at-btn--success cz-p-8" @click="handleSelect">
              <span class="at-btn__text">{{ $t('label.payNow') }} <i class="icon icon-arrow-right"></i></span>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { create } from '../../api/booking'
import headerTitle from '../../components/UI/title'
import loadingPage from '../../components/UI/loading'
import loadingCircle from '../../components/UI/loading/loadingCircle'
import moment from '../../utils/moment.js'

export default {
  components: {
    headerTitle,
    loadingPage,
    loadingCircle
  },
  data () {
    return {
      dataPast: [],
      detail: {
        name: this.$store.getters.dataUser.name,
        valid_date: {}
      },
      modalvalid: false,
      dataDate: [],
      loading: true,
      loadingPay: false,
      now: this.$store.getters.dataUser.timeLeft,
      date: moment(new Date(Date.now())),
      limiter: moment(new Date(Date.now())).add(this.$store.getters.activeTab.params.newParams.duration, 'milliseconds'),
      dummyLocation: [],
      chooseLocation: this.$store.getters.activeTab.params.chooseLocation,
      comId: this.$store.getters.activeTab.params.comId,
      listOfYears: [],
      listOfMonths: moment.monthsShort(),
      selectedDate: {},
      styleList: '',
      isActived: false,
      errorDate: false,
      tipsCard: '<table style="text-align:left;"><tr><th>card number: </th><th>4811111111111114</th></tr><tr><th>cvv: </th><th>123</th></tr></table>',
      showAuthCard: false,
      dataCharge: null,
      loaderDropdown: {
        length: 1,
        height: '80px',
        margin: '32px 0 24px 0',
        radius: '10px'
      },
      loader: {
        length: 4,
        height: '56px',
        margin: '0 0 4px 0',
        radius: '4px'
      },
      inReview: false,
      slideAnimation: true
    }
  },
  computed: {
    timer () {
      const duration = moment.duration(this.limiter.diff(this.date))
      let results = ''
      if (duration.hours() > 0) {
        results = this.twoDigitNumber(duration.hours()) + ':' + this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else if (duration.minutes() > 0) {
        results = this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else {
        results = this.twoDigitNumber(duration.seconds())
      }
      this.getTimeOver()
      return results
    },
    usingDuration () {
      const duration = this.limiter.diff(this.date)
      return duration
    },
    errorName () {
      if (this.detail.name) {
        if (this.detail.name.length <= 2) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    errorCard () {
      if (this.detail.card_number) {
        const data = this.detail.card_number.replace(/ /g, '')
        if (data.length < 11) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    errorCCV () {
      if (this.detail.ccv) {
        if (this.detail.ccv.length <= 2) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
    }, 1000)
    window.Xendit.setPublishableKey('xnd_public_development_8T3kDxbkwmpbu1mPRKmMCtfuXUrxKKzntgFyDIiaoPEgdiwrzdZegDYdAjLDO9m')
    window.Xendit._useIntegrationURL(false)
    await this.delay(300)
    this.slideAnimation = false
    this.getPastData()
  },

  methods: {
    getTimeOver () {
      if (this.usingDuration <= 0) {
        this.$store.dispatch('setActiveTab', {
          name: 'time-over',
          params: {
            duration: this.usingDuration
          }
        })
      }
    },
    twoDigitNumber (number) {
      if (number <= 9) {
        number = '0' + number
      }
      return number
    },
    getPastData () {
      this.loading = true
      const data = this.$store.getters.selectedService.selected
      Promise.all(data).then(results => {
        this.setDataDate()
      })
    },

    handleSelect () {
      this.loadingPay = true
      const tokenData = {
        amount: this.$store.getters.activeTab.params.newParams.amount,
        card_number: this.detail.card_number.replace(/ /g, ''),
        card_exp_month: this.detail.valid_date.month.toString(),
        card_exp_year: this.detail.valid_date.year.toString(),
        card_cvn: this.detail.ccv.toString(),
        is_multiple_use: false,
        should_authenticate: true
      }
      window.Xendit.card.createToken(tokenData, this.xenditResponseHandler)
    },

    async xenditResponseHandler (err, creditCardCharge) {
      if (err) {
        this.$Message.warning({
          message: err.message,
          icon: 'icon-alert-circle'
        })
        this.loadingPay = false
      } else {
        if (creditCardCharge.status === 'APPROVED' || creditCardCharge.status === 'VERIFIED') {
          this.loadingPay = true
          this.dataCharge = creditCardCharge
          await this.delay(200)
          this.chargeAppointment()
        } else if (creditCardCharge.status === 'IN_REVIEW') {
          this.loadingPay = true
          this.$refs.threeDSContainer.style.display = 'block'
          this.$refs.threeDSContainer.style.visibility = 'visible'
          this.$refs.threeDSContainer.style.opacity = 1
          this.$refs.sampleInlineFrame.src = creditCardCharge.payer_authentication_url
          this.showAuthCard = true
          await this.delay(500)
          this.inReview = true
          this.loadingPay = false
        } else if (creditCardCharge.status === 'FRAUD') {
          this.$refs.threeDSContainer.style.display = 'none'
          this.loadingPay = false
          this.showAuthCard = false
          this.isActived = true
        } else if (creditCardCharge.status === 'FAILED') {
          this.$refs.threeDSContainer.style.display = 'none'
          this.loadingPay = false
          this.showAuthCard = false
          this.isActived = true
        }
      }
    },

    chargeAppointment () {
      this.loadingPay = true
      this.$refs.threeDSContainer.style.display = 'none'
      this.showAuthCard = false
      this.isActived = false
      const token = this.$store.getters.cart.response.payment_token
      const params = {
        invoice_id: this.$store.getters.cart.response.invoice_id,
        payment_type_id: this.$store.getters.activeTab.params.newParams.payment_type_id,
        amount: this.$store.getters.activeTab.params.newParams.amount,
        detail: {
          token_id: this.dataCharge.id,
          authentication_id: this.dataCharge.authentication_id
        },
        source: 3
      }
      create(params, token).then(response => {
        this.loadingPay = false
        this.$store.dispatch('setActiveTab', { name: 'success' })
      }).catch(errors => {
        this.loadingPay = false
        /*
        this.$Message.error({
          message: errors.string,
          icon: 'icon-x'
        })
        */
        this.$store.dispatch('setActiveTab', { name: 'errors', params: { message: errors, type: 'credit-card' } })
      })
    },

    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },

    saveInput () {
      const formData = { ...this.detail }
      if (Object.keys(formData).length >= 4) {
        if (!this.errorCard && !this.errorName && !this.errorCCV && !this.errorDate) {
          this.isActived = true
        } else {
          this.isActived = false
        }
      } else {
        this.isActived = false
      }
    },

    cancelDate () {
      if (!this.detail.valid_date.fdate) {
        this.detail.valid_date.fdate = '_/_'
        this.errorDate = true
      } else {
        this.errorDate = false
      }
      this.modalvalid = false
    },

    splitCard (value) {
      let val = value
      val = val.replace(/\s/g, '')
      let newVal = ''
      for (let i = 0; i < val.length; i++) {
        if (i % 4 === 0 && i > 0) {
          newVal = newVal.concat(' ')
        }
        newVal = newVal.concat(val[i])
      }
      this.detail.card_number = newVal
      this.saveInput()
    },

    async setDataDate () {
      this.dataDate = []
      const now = moment(new Date(Date.now())).format('YYYY')
      const prev = moment(new Date(Date.now())).subtract(6, 'years').format('YYYY')
      const next = moment(new Date(Date.now())).add(6, 'years').format('YYYY')
      this.listOfYears = []
      for (let i = parseInt(prev); i <= parseInt(next); i++) {
        this.listOfYears.push(i)
      }
      this.dataDate = [
        {
          currentIndex: (new Date()).getMonth() + 1,
          flex: 3,
          list: this.listOfMonths,
          textAlign: 'center',
          className: 'row-group'
        },
        {
          currentIndex: this.listOfYears.indexOf(parseInt(now)) + 1,
          flex: 3,
          list: this.listOfYears,
          textAlign: 'center',
          className: 'row-group'
        }
      ]
      await this.delay(500)
      this.loading = false
    },

    dateChange () {
      const ciList = this.$refs.smoothPicker.getCurrentIndexList()
      const month = this.listOfMonths[ciList[0]]
      const years = this.listOfYears[ciList[1]]
      const formatted = month.toUpperCase() + '/' + years
      let intMonth = ciList[0] + 1
      let date = years + '-' + intMonth
      if (intMonth <= 9) {
        date = years + '-0' + intMonth
      }
      this.selectedDate = {
        fdate: formatted,
        date: date,
        month: intMonth,
        year: years
      }
    },

    saveDate () {
      this.dateChange()
      this.detail.valid_date = { ...this.selectedDate }
      const day = new Date(Date.now())
      const now = moment(day)
      const next = moment(new Date((this.detail.valid_date.date + '-' + day.getDate())))
      if (next.isBefore(now)) {
        this.errorDate = true
      } else {
        this.errorDate = false
      }
      this.saveInput()
      this.modalvalid = false
    },

    backStepsTo (statusOver) {
      if (this.usingDuration > 0) {
        this.$store.dispatch('setActiveTab', {
          name: 'select-payments',
          params: {
            duration: this.usingDuration
          }
        })
      } else {
        this.$store.dispatch('setActiveTab', {
          name: 'time-over',
          params: {
            chooseLocation: this.chooseLocation,
            comId: this.comId
          }
        })
      }
    },

    handleResize () {
      const windows = {}
      windows.width = window.innerWidth
      windows.height = window.innerHeight
      let paddingValue = 32
      if (this.isActived) {
        paddingValue = 96
      }
      const height = windows.height - 56
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; padding: 16px 0px ' + paddingValue + 'px; overflow-y: auto;'
    }
  }
}
</script>
