import request from '../utils/request'
// import requestCart from '../utils/requestCart'
import store from '../store'

// const endPoint = '/cart/create_online_booking'

export function create (data, token) {
  const newHeader = {
    'payment-token': token
  }
  return request({
    url: store.getters.language + '/invoicePayment/create',
    method: 'post',
    headers: newHeader,
    data
  })
}

export function buildCart (data, cartToken = null) {
  if (cartToken || cartToken !== null) {
    const newHeader = {
      'cart-token': cartToken
    }
    return request({
      url: store.getters.language + '/cart/build',
      method: 'post',
      headers: newHeader,
      data
    })
  } else {
    return request({
      url: store.getters.language + '/cart/build',
      method: 'post',
      data
    })
  }
}

export function bookingCart (data, token) {
  const newHeader = {
    'cart-token': token
  }
  return request({
    url: store.getters.language + '/cart/booking',
    method: 'post',
    headers: newHeader,
    data
  })
}
