<template>
  <div>
    <div v-if="list.length > 0">
      <div v-for="(city, key) in listCity" :key="key" class="cz-w-fit">
        <div
          class="cz-color cz-text-sm cz-mb-4">
          {{ city.address_level2 }},
          {{ city.address_level1 }}
        </div>
        <at-radio-group
          v-model="checked"
          class="block cz-mb-8"
          @radio-group-change="selectedLocation">
          <div v-for="(item, n) in locations" :key="n">
            <at-radio-button
              ref="testRefs"
              v-if="item.leveling_address.address_level2 === city.address_level2"
              :label="item.id"
              :class="[item.selected ? 'at-radio--checked cz-bg-select cz-radius-3' : '']"
              class="cz-post-relative">
              <div
                :class="[item.selected ? 'cz-bold' : '']"
                class="cz-color cz-text-xs"
                :style="getStyle(n)">
                <div class="row at-row flex-center flex-middle cz-m-null">
                  <!--
                  <div class="col cz-p-null">
                    <svg-icon
                      :icon-class="'online-mark'"
                      :width="14"
                      :height="16"
                    />
                  </div>
                  -->
                  <div
                    v-if="item.enable_provide_service_at_customer_location !== 0"
                    class="col cz-p-null">
                    <svg-icon
                      :icon-class="'pin-1'"
                      :width="14"
                      :height="16"
                    />
                  </div>
                  <div class="col cz-p-null">
                    <svg-icon
                      :icon-class="getIcon(item.gender)"
                      :width="item.gender === 'm' || item.gender === 'f' ? 14 : 16"
                      :height="16"
                    />
                  </div>
                </div>
              </div>
              <div class="row at-row flex-center flex-middle">
                <div class="col-4">
                  <div class="img-thumb-40" :style="{ backgroundImage: 'url(' + item.photo_xs + ')' }"/>
                </div>
                <div class="col-20 cz-text-left">
                  <div class="cz-text-sm cz-bold cz-color">
                    {{ item.name.replace('amp;', '') }}
                  </div>
                  <div class="cz-text-xs cz-bold cz-color">
                    {{ item.working_hours.shift_start_time }} - {{ item.working_hours.shift_end_time }}
                    <transition name="fade-time">
                      <template v-if="statusLocation.length > 0">
                        <span
                          v-if="item.id === statusLocation[n].id"
                          :class="[item.selected ? 'cz-color' : statusLocation[n].location.class]"
                          class="cz-bold">
                          {{ $t('label.' + statusLocation[n].location.string) }}
                        </span>
                      </template>
                    </transition>
                  </div>
                  <div
                    :class="[item.selected ? 'cz-color cz-bold' : 'cz-color-info']"
                    :style="item.more_address ? 'white-space: normal;' : 'white-space: norwap;'"
                    class="cz-text-xs cz-w-fit">
                    <template v-if="item.distance.genuine">
                      {{ item.distance.format }}{{ item.distance.type }}
                    </template>
                    <template v-else>
                      <template v-if="!item.more_address">
                        <template v-if="item.address.length > 31">
                          {{ setAddress(item.address) }}
                          <at-button
                            type="text"
                            class="btn cz-p-null cz-color-success cz-nobold"
                            @click="moreAddress(item)">
                            ...
                          </at-button>
                        </template>
                        <template v-else>
                          <span class="cz-color-info cz-nobold cz-text-xs">{{ item.address }}</span>
                        </template>
                      </template>
                      <template v-else>
                        {{ item.address }}
                        <at-button
                          type="text"
                          class="btn cz-p-null cz-color-success cz-nobold"
                          @click="moreAddress(item)">
                          hide
                        </at-button>
                      </template>
                    </template>
                    <!--
                    <template v-else>
                      <span class="cz-color-info cz-nobold cz-text-xs">{{ item.address }}</span>
                    </template>
                    <template v-else>
                      {{ item.distance.format }}{{ item.distance.type }}
                      <span class="cz-color-dark-info">{{ ' • ' }}</span>
                      {{ item.address }}
                      <at-button
                        type="text"
                        class="btn cz-p-null cz-color-success cz-nobold"
                        @click="moreAddress(item)">
                        hide
                      </at-button>
                    </template>
                    -->
                  </div>
                </div>
                <!--
                <div class="col-3">
                  <div v-if="item.selected" class="selected-location-icon">
                    <i class="icon icon-check"/>
                  </div>
                </div>
                -->
              </div>
            </at-radio-button>
          </div>
        </at-radio-group>
      </div>
    </div>
    <empty-choose v-else :page="'location'" :text="$t('messages.locationNull')"/>
  </div>
</template>

<script>
import EmptyChoose from '../../components/UI/EmptyChoose'
import moment from '../../utils/moment.js'
import geolib from '../../utils/geolocate'

export default {
  name: 'Locations',
  components: {
    EmptyChoose
  },

  props: ['list', 'loadingList', 'maxShow', 'cities', 'myCoordinate'],

  data () {
    return {
      checked: '',
      date: new Date(Date.now()),
      statusLocation: [],
      loading: false
    }
  },

  computed: {
    listCity () {
      const data = this.cities
      data.map(x => {
        x.distance = this.getDistanceFromMe(x.lat, x.lon)
      })
      data.sort((a, b) => (b.distance.genuine < a.distance.genuine ? 1 : -1))
      return data
    },
    locations () {
      const data = this.list
      data.map(x => {
        x.distance = this.getDistanceFromMe(x.location_lat, x.location_lon)
      })
      data.sort((a, b) => (b.distance.genuine < a.distance.genuine ? 1 : -1))
      return data
    },
    currentPosition () {
      return this.myCoordinate
    }
  },

  mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
      this.getStatusLocation()
    }, 1000)
  },

  methods: {
    getDistanceFromMe (lat, lon) {
      let results = {}
      const temps = geolib.getDistance(
        {
          latitude: parseFloat(this.currentPosition.lat),
          longitude: parseFloat(this.currentPosition.lon)
        },
        {
          latitude: parseFloat(lat),
          longitude: parseFloat(lon)
        }
      )
      results = {
        genuine: temps,
        format: Math.round(geolib.convertDistance(temps, 'km') * 10) / 10,
        type: 'km'
      }
      return results
    },

    async getStatusLocation () {
      this.loading = true
      this.statusLocation = []
      const dateString = moment(this.date).format('YYYY-MM-DD')
      await this.list.map(x => {
        const start = moment(dateString + ' ' + x.working_hours.shift_start_time, 'YYYY-MM-DD HH:mm').subtract(15, 'seconds')
        const end = moment(dateString + ' ' + x.working_hours.shift_end_time, 'YYYY-MM-DD HH:mm').add(15, 'seconds')
        const opening = moment(dateString + ' ' + x.working_hours.shift_start_time, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
        const closing = moment(dateString + ' ' + x.working_hours.shift_end_time, 'YYYY-MM-DD HH:mm').subtract(60, 'minutes')
        let results = {}
        if (this.date.isBetween(start, end)) {
          if (this.date.isBetween(closing, end)) {
            results = {
              status: 2,
              string: 'closeSoon',
              class: 'cz-color-warning'
            }
          } else {
            results = {
              status: 1,
              string: 'openNow',
              class: 'cz-color-success'
            }
          }
        } else {
          if (this.date.isBetween(opening, start)) {
            results = {
              status: 0,
              string: 'openSoon',
              class: 'cz-color-info'
            }
          } else {
            results = {
              status: 3,
              string: 'close',
              class: 'cz-color-info'
            }
          }
        }
        this.statusLocation.push({
          id: x.id,
          location: results
        })
      })
      this.loading = false
    },
    setAddress (address) {
      if (address) {
        let newaddress = address.split('')
        newaddress.splice(25, (newaddress.length - 1))
        return newaddress.join('')
      }
    },
    moreAddress (item) {
      if (item.more_address) {
        item.more_address = false
      } else {
        item.more_address = true
      }
    },
    selectedLocation (value) {
      this.locations.map(x => {
        x.selected = false
        if (x.id === value) {
          x.selected = true
        }
      })
      this.$emit('selected')
    },
    showingOrNot (city) {
      let results = true
      this.locations.map(x => {
        if (x.address_level2 !== city.address_level2) {
          results = false
        }
      })
      return results
    },
    getIcon (gender) {
      let results = 'unisex'
      if (gender === 'm') results = 'male'
      if (gender === 'f') results = 'female'
      return results
    },
    getStyle (keys) {
      let results = 'position: absolute; top: 0px; right: 8px; width: 24px; padding: 8px 0px;'
      if (this.statusLocation.length) {
        if (this.locations[keys].id === this.statusLocation[keys].id) {
          if (this.statusLocation[keys].status !== 0 && this.locations[keys].enable_provide_service_at_customer_location !== 0) {
            results = 'position: absolute; top: 0px; right: 8px; width: 48px; padding: 8px 0px;'
          }
        }
      }
      return results
    }
  }
}
</script>
