<template>
  <div>
    <transition name="fade-time">
      <div v-if="show" class="cz-modal">
        <div class="cz-content-modal" :style="{ maxWidth: widthContent + 'px', minHeight: minimalHeight }">
          <div class="cz-bold cz-text-lg cz-color cz-mb-8">
            {{ labelTtitle }}
            <template v-if="content.index > 0 && content.index <= 3">
              ({{ dataCollect.length }})
            </template>
          </div>
          <div class="cz-text-sm cz-color cz-mb-8">
            <template v-if="content.index === 0">
              {{ $t('tips.tipsDetailLocation') }}
              {{ labelTtitle }}
            </template>
            <template v-if="content.index > 0 && content.index <= 3 && dataCollect.length > 0">
              <div class="cz-text-capital w-fit">
                {{ setTextLength(textDataCollect).string }}
                <at-button
                  v-if="textDataCollect.length > 80"
                  type="text"
                  class="cz-p-null cz-lh-null cz-color-success"
                  @click="showMoreText">
                  <span :class="[moreText ? 'cz-text-xs' : 'cz-text-md']">
                    <template v-if="!moreText">...</template>
                    <template v-else> &nbsp; hide </template>
                  </span>
                </at-button>
              </div>
            </template>
            <template v-if="content.index > 3">
             {{ $t('label.phoneNumber') }}
             <span class="cz-color-primary cz-bold">{{ dataCollect.phone }}</span>,
             Email <span class="cz-color-primary cz-bold">{{ dataCollect.email }}</span>
            </template>
          </div>
          <div
            v-if="iconFinder !== ''"
            :style="{ backgroundColor: getBackground(content.string) }"
            class="cz-w-fit cz-radius-10"
            style="height: 150px; padding: 31px;">
            <svg-icon :icon-class="iconFinder" :width="88" :height="88"/>
            <!--cz-bg-success-semi-light-->
          </div>
          <div
            v-else
            :style="backgroundBanner"
            class="img-thumb-48 cz-border"
            style="height: 150px; width: 100%;"
          />
          <div class="list-group inline nowrap cz-mt-8">
            <div v-for="(icon, x) in iconButton" :key="x" class="list-items">
              <at-button
                :class="[icon === content.string ? 'active' : '']"
                circle
                @click="change(x, icon)">
                <i class="at-btn__icon">
                  <svg-icon :icon-class="icon" :width="22" :height="22"/>
                </i>
              </at-button>
              <div class="cz-color-info" style="font-size: 10px;">
                <template v-if="icon === 'home'">{{ $t('label.facility') }}</template>
                <template v-else-if="icon === 'phone'">{{ $t('label.contact') }}</template>
                <template v-else>{{ $t('label.' + icon) }}</template>
              </div>
            </div>
          </div>
          <div class="cz-w-fit cz-mt-8">
            <at-button class="cz-btn block h40 cz-bg-info cz-border-null cz-p-8" @click="close">
              {{ $t('label.close') }}
            </at-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'dialogDetail',

  props: ['show', 'widthContent', 'minHeightContent', 'content', 'iconButton', 'dataCollect'],

  data () {
    return {
      moreText: false
    }
  },

  computed: {
    minimalHeight () {
      if (this.minHeightContent === 'unset') {
        return 'unset'
      } else {
        return this.minHeightContent + 'px'
      }
    },
    iconFinder () {
      let results = ''
      if (this.content.string === 'male' || this.content.string === 'female' || this.content.string === 'unisex') {
        if (this.content.string === 'male') results = 'logo-man'
        if (this.content.string === 'female') results = 'logo-ladies'
        if (this.content.string === 'unisex') results = 'logo-' + this.content.string
      }
      return results
    },
    labelTtitle () {
      if (this.content.string === 'home') {
        return this.$t('label.facility')
      } else if (this.content.string === 'phone') {
        return this.$t('label.contact')
      } else {
        return this.$t('label.' + this.content.string)
      }
    },
    textDataCollect () {
      let textResult = ''
      if (this.content.index > 0 && this.content.index <= 3 && this.dataCollect.length > 0) {
        this.dataCollect.map(x => {
          textResult += x.name + ', '
        })
      }
      return textResult
    },
    backgroundBanner () {
      let string = ''
      if (this.content.index >= 1 && this.content.index <= 2) {
        if (this.dataCollect.length > 0) {
          if (this.dataCollect[0].photo) {
            string = 'background-image: url(' + this.dataCollect[0].photo + ');'
          }
        } else {
          string = 'background-image: url(' + this.getImage(this.content.string) + '); background-size: auto;'
        }
      } else if (this.content.index >= 3 && this.content.index <= 4) {
        string = 'background-image: url(' + this.$store.getters.selectedLocation.photo + ');'
      }
      return string
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    change (key, content) {
      this.$emit('change', key, content)
    },
    setTextLength (text) {
      let results = {
        string: text,
        length: text.length
      }
      if (text !== '') {
        const arrayText = text.split('')
        let end = 2
        arrayText.splice((arrayText.length - 2), end)
        if (arrayText.length > 80 && !this.moreText) {
          end = arrayText.length - 80
          arrayText.splice(80, end)
        }
        const temp = arrayText.join('')
        results.string = temp.split(',').join(' • ')
        results.length = temp.length
      }
      return results
    },
    showMoreText () {
      if (this.moreText) {
        this.moreText = false
      } else {
        this.moreText = true
      }
    },
    getImage (name) {
      let images = require.context('../../icons/svg/', false, /\.svg$/)
      const url = images('./' + name + '.svg')
      return url
    },
    getBackground (string) {
      if (string === 'male') return '#2882f5'
      if (string === 'female') return '#ffb6cf'
      if (string === 'unisex') return '#46e8bd'
    }
  }
}
</script>
