import request from '../utils/request.js'
import store from '../store'

const endPoint = '/staffAvailable/lists'

export function fetchList (query) {
  return request({
    url: store.getters.language + endPoint,
    method: 'get',
    params: query
  })
}
