import request from '../utils/request'
import store from '../store'

const endPoint = '/places'

export function fetchList (query) {
  return request({
    url: store.getters.language + endPoint + '/list',
    method: 'get',
    params: query
  })
}

export function fetchListNearest (query) {
  return request({
    url: store.getters.language + endPoint + '/search',
    method: 'get',
    params: query
  })
}

export function fetch (id, date) {
  return request({
    url: store.getters.language + endPoint + '/show/' + id,
    method: 'get',
    params: date
  })
}
