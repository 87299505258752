<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div v-if="!successCreate" class="back-step" @click="backStepsTo">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="loading" :title="$t('label.payment')"/>
        </div>
      </div>
      <div class="cz-body">
        <transition name="fade-time">
          <div v-if="!loading" :style="styleList" class="content">
            <div class="service-new-list" style="padding: 0px 0px;">
              <at-card
                :no-hover="true"
                :body-style="{ padding: '24px 16px' }"
                class="cz-emboss cz-radius-10">
                <div class="row at-row no-gutter flex-middle cz-m-null">
                  <div class="col-xs-5 col-sm-3 col-md-3">
                    <i class="icon icon-credit-card cz-text-lg cz-color"/>
                  </div>
                  <div class="col-xs-13 col-sm-15 col-md-15">
                    <span class="cz-text-sm cz-color cz-bold">
                      ATM / {{ $t('label.payTransfer') }}
                    </span>
                  </div>
                  <div class="col-xs-6 col-sm-6 col-md-6 cz-text-right">
                    <span v-if="!successCreate" class="cz-color-primary cz-text-sm cz-bold" @click="backStepsTo">{{ 'change' }}</span>
                  </div>
                </div>
              </at-card>
            </div>
            <div class="cz-w-fit cz-text-right cz-color cz-mt-8 cz-text-xs cz-color-info">
              {{ $t('tips.tipsPleasePay') }} {{ timer }}
            </div>

            <div class="cz-list-card list-staff">
              <div class="cz-w-fit">
                <div class="flex at-flex flex-middle flex-center">
                  <div class="col-6">
                    <div class="cz-text-xs cz-text-left">{{ $t('label.payTo') }}</div>
                  </div>
                  <div class="col">
                    <div class="cz-text-xs cz-text-right">
                      <div
                        :style="{ backgroundImage: 'url(' + dataBank.icon + ')'}"
                        class="img-thumb-40 bank cz-bg-white"
                        style="margin-left: auto; background-size: contain; border-radius: 0px;"
                      />
                    </div>
                  </div>
                </div>

                <at-button
                  class="cz-btn block cz-p-10 cz-mtb-8 cz-border cz-nobold"
                  v-clipboard:copy="dataBank.vn"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError">
                  <div class="flex at-flex flex-middle flex-center">
                    <div class="col cz-color cz-text-lg">
                      <template v-if="dataBank.vn && !loadingPay">
                        <div class="cz-text-left cz-pl-8">{{ splitCard(dataBank.vn) }}</div>
                      </template>
                      <template v-else>
                        <div class="cz-text-left cz-pl-8">{{ '---- ---- ---- ----' }}</div>
                      </template>
                    </div>

                    <div class="col cz-text-right">
                      <i class="icon icon-copy cz-text-lg"/>
                    </div>
                  </div>
                </at-button>
              </div>

              <div class="cz-w-fit cz-mtb-8 h40">
                <at-button type="info" class="cz-btn block cz-color" @click="showModalRight(true)">
                  <span class="cz-text-sm">{{ $t('label.orderDetails') }}</span>
                </at-button>
              </div>

              <div v-if="dataBank.bank.toLowerCase() === 'bni'" class="cz-mtb-16">
                <at-collapse accordion class="cz-collapse" :value="accordionBNI" @on-change="getHtmlListBNI">
                  <at-collapse-item
                    v-for="n in 2" :key="n"
                    :title="n + 'title'"
                    class="cz-mb-16">
                    <div v-if="n === 1" slot="title" class="cz-text-xs cz-color">BNI mobile banking</div>
                    <div v-else-if="n === 2" slot="title" class="cz-text-xs cz-color">BNI internet banking</div>
                    <div>
                      <ol v-if="n === 1" ref="bniMList"/>
                      <ol v-else ref="bniIList"/>
                    </div>
                  </at-collapse-item>
                </at-collapse>
              </div>

              <div v-if="dataBank.bank.toLowerCase() === 'permata'" class="cz-mtb-16">
                <at-collapse accordion class="cz-collapse" :value="accordionPermata" @on-change="getHtmlListPermata">
                  <at-collapse-item
                    v-for="n in 1" :key="n"
                    :title="n + 'title'"
                    class="cz-mb-16">
                    <div slot="title" class="cz-text-xs cz-color">How to pay</div>
                    <div>
                      <ol ref="permataList"/>
                    </div>
                  </at-collapse-item>
                </at-collapse>
              </div>

              <div v-if="dataBank.bank.toLowerCase() === 'bca'" class="cz-mtb-16">
                <at-collapse accordion class="cz-collapse" :value="accordionBCA" @on-change="getHtmlListBCA">
                  <at-collapse-item
                    v-for="n in 3" :key="n"
                    :title="n + 'title'"
                    class="cz-mb-16">
                    <div v-if="n === 1" slot="title" class="cz-text-xs cz-color">m-BCA</div>
                    <div v-else-if="n === 2" slot="title" class="cz-text-xs cz-color">ATM-BCA</div>
                    <div v-else-if="n === 3" slot="title" class="cz-text-xs cz-color">Click BCA</div>
                    <div>
                      <ol v-if="n === 1" ref="bcaMList"/>
                      <ol v-if="n === 2" ref="bcaATMList"/>
                      <ol v-if="n === 3" ref="bcaCKList"/>
                    </div>
                  </at-collapse-item>
                </at-collapse>
              </div>
            </div>
          </div>
        </transition>
        <loading-page :loading="loading" :data-loading="loaderDropdown"/>
        <loading-page :loading="loading" :data-loading="loaderBank"/>
        <loading-page :loading="loading" :data-loading="loader"/>
        <loading-page :loading="loading" :data-loading="loaderDetail"/>
      </div>

      <transition name="slide-test-up">
        <div v-if="!loading" class="cz-btn-bottom-book">
          <div class="cz-w-fit">
            <at-button
              type="success"
              class="cz-btn block h56 text-lg"
              @click="handleSelect">
              {{ $t('label.payNow') }} <i class="icon icon-arrow-right"/>
            </at-button>
          </div>
        </div>
      </transition>

      <transition name="slide-test-up">
        <div v-if="!loading && !loadingPay && successCreate" class="cz-btn-bottom-book">
          <div class="cz-w-fit">
            <at-button
              type="success"
              class="cz-btn block h56 text-lg"
              @click="handleSelect">
              {{ $t('label.makeAnotherBook') }}
            </at-button>
          </div>
        </div>
      </transition>

      <transition name="fade-steady">
        <div v-if="showDetail" class="cz-modal-right">
          <transition name="slide-right">
            <div v-if="!frameReady" class="cz-content-modal use-header" style="min-height: unset;">
              <div class="cz-modal-header">
                <div class="flex at-flex flex-center flex-middle cz-mb-8">
                  <div class="col cz-text-md cz-color cz-text-left cz-bold cz-m-null cz-p-null">
                    {{ $t('label.orderDetails') }}
                  </div>
                  <div class="col-6 cz-text-right cz-color cz-m-null cz-p-null">
                    <at-button type="info" class="cz-btn cz-radius-3" @click="showModalRight(false)">
                      Esc
                    </at-button>
                  </div>
                  <div class="col-2 cz-text-right cz-color cz-m-null cz-p-null">
                    <span @click="showModalRight(false)">
                      <i class="icon icon-x cz-text-lg cz-color cz-cursor"/>
                    </span>
                  </div>
                </div>
              </div>

              <div class="cz-modal-body">
                <div class="cz-w-fit cz-border-bottom cz-mb-16">
                  <div class="cz-color-info cz-text-xs cz-bold cz-mb-16">
                    {{ $t('label.boughtItems') }}
                  </div>
                  <div v-for="(item, n) in detailCart.detail" :key="n">
                    <div
                      :class="[n === (detailCart.detail.length - 1) ? 'cz-mb-16' : 'cz-mb-8']"
                      class="flex at-flex flex-center flex-middle cz-mb-8">
                      <div class="col cz-text-left cz-p-null" style="width: 50%">
                        <span class="cz-text-sm cz-color">{{ item.name }}</span>
                      </div>
                      <div class="col cz-text-right cz-m-null cz-p-null" style="width: 25%">
                        <span class="cz-text-sm cz-color cz-bold">
                          ({{ item.qty }})
                          {{ item.price.replace(',00', '') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cz-w-fit cz-border-bottom cz-mb-16">
                  <div class="cz-color-info cz-text-xs cz-bold cz-mb-16">
                    {{ $t('label.toPay') }}
                  </div>
                  <div>
                    <div class="flex at-flex flex-center flex-middle cz-mb-8">
                      <div class="col cz-text-left cz-color cz-p-null">
                        <span class="cz-text-sm cz-color">{{ $t('label.subTotal') }}</span>
                      </div>
                      <div class="col cz-text-right cz-color cz-p-null">
                        <span class="cz-text-sm cz-bold cz-color">
                          ({{ subTotal.qty }})
                          {{ subTotal.ftotal }}
                        </span>
                      </div>
                    </div>
                    <div class="flex at-flex flex-center flex-middle cz-mb-8">
                      <div class="col cz-text-left cz-color cz-p-null">
                        <span class="cz-text-sm cz-color">{{ $t('label.discount') }}</span>
                      </div>
                      <div class="col cz-text-right">
                        <span class="cz-text-sm cz-bold cz-color cz-p-null">
                          {{ discount }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cz-w-fit cz-mb-16">
                  <div class="flex at-flex flex-center flex-middle cz-mb-8">
                    <div class="col cz-text-left cz-p-null">
                      <span class="cz-text-sm cz-color cz-bold">{{ $t('label.total') }}</span>
                    </div>
                    <div class="col cz-text-right cz-p-null">
                      <span class="cz-text-sm cz-color cz-bold">
                        {{ overAllTotal.format }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>

      <loading-circle :loading="loadingPay"/>
    </div>
  </transition>
</template>

<script>
import { create } from '../../api/booking'
import headerTitle from '../../components/UI/title'
import loadingPage from '../../components/UI/loading'
import loadingCircle from '../../components/UI/loading/loadingCircle'
import moment from '../../utils/moment.js'

export default {
  components: {
    headerTitle,
    loadingPage,
    loadingCircle
  },
  data () {
    return {
      dataPast: [],
      loading: true,
      now: this.$store.getters.dataUser.timeLeft,
      date: moment(new Date(Date.now())),
      limiter: moment(new Date(Date.now())).add(this.$store.getters.activeTab.params.newParams.duration, 'milliseconds'),
      chooseLocation: this.$store.getters.activeTab.params.chooseLocation,
      comId: this.$store.getters.activeTab.params.comId,
      styleList: '',
      isActived: false,
      dataBank: this.$store.getters.activeTab.params.dataBank,
      accordionBNI: null,
      accordionPermata: null,
      accordionBCA: null,
      showDetail: false,
      listItem: this.$store.getters.selectedService.selected,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true
      },
      overAllTotal: {},
      discount: 0,
      loadingPay: false,
      successCreate: false,
      loaderDropdown: {
        length: 1,
        height: '80px',
        margin: '48px 0 24px 0',
        radius: '10px'
      },
      loaderBank: {
        length: 1,
        height: '24px',
        margin: '0',
        radius: '3px'
      },
      loader: {
        length: 1,
        height: '56px',
        margin: '0 0 8px 0',
        radius: '4px'
      },
      loaderDetail: {
        length: 1,
        height: '24px',
        margin: '0',
        radius: '4px'
      },
      slideAnimation: true,
      frameReady: true,
      detailCart: this.$store.getters.cart.response.cartDetail,
      subTotal: {}
    }
  },
  computed: {
    timer () {
      const duration = moment.duration(this.limiter.diff(this.date))
      let results = ''
      if (duration.hours() > 0) {
        results = this.twoDigitNumber(duration.hours()) + ':' + this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else if (duration.minutes() > 0) {
        results = this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else {
        results = this.twoDigitNumber(duration.seconds())
      }
      this.getTimeOver()
      return results
    },
    usingDuration () {
      const duration = this.limiter.diff(this.date)
      return duration
    },
    currency () {
      return this.$store.getters.company.currency.symbol
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
    }, 1000)
    await this.delay(300)
    this.slideAnimation = false
    this.getPastData()
  },

  methods: {
    async showModalRight (status) {
      this.showDetail = status
      this.frameReady = true
      await this.delay(300)
      this.frameReady = false
    },
    getTimeOver () {
      if (this.usingDuration <= 0) {
        this.$store.dispatch('setActiveTab', {
          name: 'time-over',
          params: {
            duration: this.usingDuration
          }
        })
      }
    },
    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },
    twoDigitNumber (number) {
      if (number <= 9) {
        number = '0' + number
      }
      return number
    },
    getPastData () {
      this.loading = true
      const data = this.$store.getters.selectedService.selected
      Promise.all(data).then(results => {
        this.getAllTotal()
      })
    },

    onCopy: function (e) {
      this.$Message.success({
        message: e.text
      })
    },

    onError: function (e) {
      this.$Message.warning({
        message: 'Copy Failed'
      })
    },

    handleSelect () {
      this.loadingPay = true
      if (!this.dataBank.vn) {
        const token = this.$store.getters.cart.response.payment_token
        const params = {
          invoice_id: this.$store.getters.cart.response.invoice_id,
          payment_type_id: this.$store.getters.activeTab.params.newParams.payment_type_id,
          amount: this.$store.getters.activeTab.params.newParams.amount,
          detail: {
            bank: this.dataBank.bank
          },
          source: 3
        }
        create(params, token).then(response => {
          this.dataBank.vn = response.data.data.payment_va_numbers[0].va_number
          this.successCreate = true
          this.$Message.success({
            message: this.$t('label.succesBook'),
            icon: 'icon-check'
          })
          this.loadingPay = false
        }).catch(errors => {
          this.loadingPay = false
          this.successCreate = false
          this.$Message.error({
            message: errors.string,
            icon: 'icon-x'
          })
          this.$store.dispatch('setActiveTab', { name: 'errors', params: { message: errors, type: 'transfer' } })
        })
      } else {
        this.$store.commit('SET_SELECTED_LOCATION', null)
        // this.$store.commit('SET_SELECTED_STAFFS', null)
        this.$store.commit('SET_SELECTED_SERVICES', null)
        this.$store.dispatch('setActiveTab', 'locations')
      }
    },

    splitCard (value) {
      let val = value.toString()
      val = val.replace(/\s/g, '')
      let newVal = ''
      for (let i = 0; i < val.length; i++) {
        if (i % 4 === 0 && i > 0) {
          newVal = newVal.concat(' ')
        }
        newVal = newVal.concat(val[i])
      }
      return newVal
    },

    backStepsTo () {
      if (this.usingDuration > 0) {
        this.$store.dispatch('setActiveTab', {
          name: 'select-payments',
          params: {
            duration: this.usingDuration
          }
        })
      } else {
        this.$store.dispatch('setActiveTab', {
          name: 'time-over',
          params: {
            chooseLocation: this.chooseLocation,
            comId: this.comId
          }
        })
      }
    },

    countDown (data) {
      if (data === '00:00') {
        return true
      } else {
        return false
      }
    },

    handleResize () {
      const windows = {}
      windows.width = window.innerWidth
      windows.height = window.innerHeight
      let paddingValue = 120
      if (this.dataBank.bank === 'bni') {
        paddingValue = 136
      }
      const height = windows.height - 120
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; padding: 16px 0px ' + paddingValue + 'px; overflow-y: auto;'
    },

    getHtmlListBNI (list) {
      if (list) {
        if (parseInt(list[0]) === 0) {
          this.$refs.bniMList[0].innerHTML = ''
          for (var i = 1; i <= 8; i++) {
            this.$refs.bniMList[0].innerHTML += '<li>' + this.$t('transfer_m_bni._' + i) + '</li>'
          }
        }
        if (parseInt(list[0]) === 1) {
          this.$refs.bniIList[0].innerHTML = ''
          for (var j = 1; j <= 8; j++) {
            this.$refs.bniIList[0].innerHTML += '<li>' + this.$t('transfer_i_bni._' + j) + '</li>'
          }
        }
      }
    },

    getHtmlListPermata (list) {
      if (list) {
        this.$refs.permataList[0].innerHTML = ''
        for (var j = 1; j <= 7; j++) {
          this.$refs.permataList[0].innerHTML += '<li>' + this.$t('transfer_permata._' + j) + '</li>'
        }
      }
    },

    getHtmlListBCA (list) {
      if (list) {
        if (parseInt(list[0]) === 0) {
          this.$refs.bcaMList[0].innerHTML = ''
          for (var i = 1; i <= 7; i++) {
            this.$refs.bcaMList[0].innerHTML += '<li>' + this.$t('transfer_m_bca._' + i) + '</li>'
          }
        }
        if (parseInt(list[0]) === 1) {
          this.$refs.bcaATMList[0].innerHTML = ''
          for (var j = 1; j <= 6; j++) {
            this.$refs.bcaATMList[0].innerHTML += '<li>' + this.$t('transfer_atm_bca._' + j) + '</li>'
          }
        }
        if (parseInt(list[0]) === 1) {
          this.$refs.bcaCKList[0].innerHTML = ''
          for (var k = 1; k <= 6; k++) {
            this.$refs.bcaCKList[0].innerHTML += '<li>' + this.$t('transfer_click_bca._' + k) + '</li>'
          }
        }
      }
    },

    getTotalPerItem (price, qty) {
      const intPrice = parseInt(price)
      const intQty = parseInt(qty)
      const total = intPrice * intQty
      const ftotal = total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      return ftotal
    },
    async getSubTotal (list) {
      const data = this.detailCart.detail
      this.subTotal = {
        qty: 0,
        total: this.detailCart.total_price,
        ftotal: this.detailCart.ftotal_price.replace(',00', '')
      }
      data.map(x => {
        this.subTotal.qty += x.qty
      })
      await this.delay(300)
      this.loading = false
    },
    getAllTotal () {
      const data = this.$store.getters.cart.response.cartDetail
      this.overAllTotal = {
        int: data.total_price,
        format: data.ftotal_price.replace(',00', '')
      }
      this.getSubTotal()
    }
  }
}
</script>
