import { fetchList, fetch } from '../../api/locations'

const locations = {
  state: {
    locations: [],
    selectedLocation: null
  },

  mutations: {
    SET_LOCATIONS: (state, locations) => {
      state.locations = locations
    },
    SET_SELECTED_LOCATION: (state, location) => {
      state.selectedLocation = location
    }
  },

  actions: {
    fetchLocations ({ commit }) {
      return new Promise((resolve, reject) => {
        fetchList().then(response => {
          commit('SET_LOCATIONS', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    fetchSelectedLocation ({ commit }, id) {
      return new Promise((resolve, reject) => {
        fetch(id).then(response => {
          commit('SET_SELECTED_LOCATION', response.data.data)
          resolve(response)
        }).catch(error => {
          commit('SET_SELECTED_LOCATION', null)
          reject(error)
        })
      })
    }
  }
}

export default locations
