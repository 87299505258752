import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import locations from './modules/locations'
import services from './modules/services'
import staffs from './modules/staffs'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    services,
    locations,
    user,
    staffs
  },
  getters
})

export default store
