import request from '../utils/request'
import store from '../store'

export function getSystemToken () {
  const data = {
    client_id: 3,
    client_secret: 'XqrxFXaEqxZ72Yq15GTe9G5F1Q9zqoe6wYDoBb5P',
    grant_type: 'client_credentials'
  }

  return request({
    url: store.getters.language + '/authForSystem/login',
    method: 'post',
    data,
    headers: {
      device: 123123123
    }
  })
}
