<template>
  <transition :name="animationState">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div class="back-step" @click="backStepsTo">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>
          <header-title :loading="false" :title="title"/>
        </div>

        <div class="cz-body">
          <div v-if="!loading" class="content" :style="styleList">
            <div class="cz-mb-16">
              <at-button type="text" class="cz-btn cz-w-fit cz-p-8" @click="goToAddAppointment">
                <div class="row at-row flex-center flex-middle cz-m-null">
                  <div class="col">
                    <div class="cz-text-lg cz-text-left cz-color" style="font-size: 20px;">Your Appointment</div>
                  </div>
                  <div class="col-4 cz-text-right">
                    <i class="icon icon-plus cz-color cz-text-lg"/>
                  </div>
                </div>
              </at-button>
              <at-button
                type="info"
                class="cz-btn block cz-bg-input no-hover cz-p-8 cz-border-null cz-m-null"
                style="border-radius: 50px; line-height: normal;">
                <div class="cz-text-xs cz-color cz-nobold">
                  {{ formattedDate }}
                </div>
              </at-button>
            </div>

            <list-summary
              :loading="loading"
              :list-items="listItems"
              :date="date"
              @load-staff="getStaffs"
              @load-time="getTimes"
              @delete="deleteService"
              @selected-staff="selectedStaff"
              @selected-time="selectedTime"
            />

            <transition name="slide-test-up">
              <div v-if="!loading && validService" class="cz-btn-bottom-book">
                <div class="cz-w-fit cz-boxshadow-btn">
                  <at-button
                    :loading="loadingConfirm"
                    type="success"
                    class="cz-btn block h56 text-lg cz-p-8"
                    @click="setBuildCart">
                    {{ $t('label.bookNow') }}
                  </at-button>
                </div>
              </div>
            </transition>
          </div>

          <loading-page :loading="loading" :data-loading="loader"/>
        </div>
      </div>
      <loading-opacity :loading="loadingConfirm"/>
    </div>
  </transition>
</template>

<script>
import headerTitle from '../../components/UI/title'
import listSummary from './list'
import loadingPage from '../../components/UI/loading'
import loadingOpacity from '../../components/UI/loading/loadingOpacity'
import { fetchListTime } from '../../api/time'
import { buildCart } from '../../api/booking'
import { fetchList } from '../../api/staffs'
import moment from '../../utils/moment.js'

export default {
  components: {
    loadingPage,
    headerTitle,
    listSummary,
    loadingOpacity
  },

  data () {
    return {
      title: this.$t('label.summary'),
      loading: true,
      date: this.$store.getters.selectedService.date,
      selectedService: this.$store.getters.selectedService.selected,
      serviceGroup: this.$store.getters.selectedService.group,
      detailService: this.$store.getters.selectedService.detail,
      listStaff: this.$store.getters.listStaff,
      loader: {
        length: 3,
        height: '112px',
        radius: '10px',
        margin: '0 0 16px 0'
      },
      queryTime: [],
      loadingTime: true,
      listTime: this.$store.getters.selectedService.time_selected,
      selectedLocation: this.$store.getters.selectedLocation,
      startTime: null,
      currentTime: moment(new Date(Date.now())),
      validService: false,
      loadingConfirm: false,
      slideAnimation: true,
      listItems: [],
      sameError: []
    }
  },

  computed: {
    formattedDate () {
      const today = moment(new Date(Date.now())).toDate()
      const selected = moment(this.date).toDate()
      let format = ''
      if (moment(selected).isSame(today, 'date')) {
        format = this.$t('label.today') + ', ' + moment(selected).format('DD MMMM YYYY')
      } else {
        format = moment(selected).format('dddd') + ', ' + moment(selected).format('DD MMMM YYYY')
      }
      return format
    },
    animationState () {
      // if (!this.$router.currentRoute.params.page) {
      //   return 'slide-right'
      // } else {
      //   return 'slide-fade'
      // }
      return 'slide-fade'
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    window.setInterval(() => {
      this.currentTime = moment(new Date(Date.now()))
    }, 1000)
    this.delay(300)
    this.slideAnimation = false
    this.getDefaultData()
  },

  methods: {
    handleResize () {
      let paddingValue = 56
      const height = window.innerHeight - 120
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; overflow-y: auto; padding: 0 8px ' + paddingValue + 'px 8px;'
    },

    backStepsTo () {
      this.$store.commit('SET_DATA_CART', null)
      this.$store.dispatch('setActiveTab', 'pick-time')
    },

    goToAddAppointment () {
      const params = {
        services: this.$store.getters.services,
        selected: this.selectedService,
        group: this.serviceGroup,
        detail: this.detailService
      }
      this.$store.dispatch('setActiveTab', {
        name: 'add-appointment',
        params
      })
    },

    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },

    getDefaultData () {
      this.loading = true
      this.validService = false
      let data = this.detailService.map(x => ({ ...x, start_time: '', loading_time: false, loading_big_time: false, loading_staff: false, staffs: [], staff_was_load: false, times: { available: true, list: [] }, selected_staff: {}, selected_time: {} }))
      const dataCart = this.$store.getters.cart.response.cartDetail.detail
      const dataTemp = data.sort(function (a, b) {
        if (a.id < b.id) return 1
        if (a.id > b.id) return -1
      })
      data = this.setDefaulData(dataTemp, dataCart)
      const stringDate = moment(this.date).format('YYYY-MM-DD')
      this.listItems = dataTemp.sort((a, b) => (moment(stringDate + ' ' + b.selected_time.start, 'YYYY-MM-DD HH:mm').isBefore(moment(stringDate + ' ' + a.selected_time.start, 'YYYY-MM-DD HH:mm')) ? 1 : -1))
      this.delay(100)
      this.loading = false
      this.validService = true
    },

    setDefaulData (data, cart) {
      let staffCart = []
      data.map(x => {
        cart.map(y => {
          if (x.service_price_id === y.service_price_id && x.service_id === y.service_id) {
            const temp = y.staff.map(z => ({ ...z, id_list: x.id }))
            temp.map(a => {
              staffCart.push(a)
            })
          }
        })
      })
      const staffUnique = staffCart.filter((thing, index, self) => index === self.findIndex((y) => (y.id === thing.id)))
      const staffSorting = staffUnique.sort(function (a, b) {
        if (a.id_list < b.id_list) return 1
        if (a.id_list > b.id_list) return -1
      })
      for (var i = 0; i < data.length; i++) {
        data[i].selected_staff = staffSorting[i]
        data[i].selected_time = {
          start: staffSorting[i].time,
          end: staffSorting[i].end_time
        }
        if (!staffSorting[i].end_time) {
          data[i].times.available = false
        }
      }
      return data
    },

    getStaffs (data) {
      if (!this.listItems[data.key].staff_was_load) {
        this.listItems[data.key].loading_staff = true
        const params = {
          datetime: moment(this.date).format('YYYY-MM-DD'),
          search_column: ['location_id', 'service_id'],
          search_text: [this.selectedLocation.id, data.detail.service_id],
          search_operator: ['=', '=']
        }

        fetchList(params).then(response => {
          this.listItems[data.key].staffs = response.data.data
          this.listItems[data.key].loading_staff = false
          this.listItems[data.key].staff_was_load = true
          this.checkCartValid()
        }).catch((error) => {
          this.listItems[data.key].staffs = []
          if (this.listItems[data.key].selected_staff.staff_id !== null) {
            this.listItems[data.key].staffs.push(this.listItems[data.key].selected_staff)
          } else {
            this.$Message.error({
              message: error.string,
              icon: 'icon-x'
            })
          }
          this.checkCartValid()
          this.listItems[data.key].loading_staff = false
          this.listItems[data.key].staff_was_load = true
        })
      }
    },

    getTimes (data, from) {
      if (this.listItems[data.key].times.available) {
        if (from === 0) this.listItems[data.key].loading_time = true
        if (from === 1) this.listItems[data.key].loading_big_time = true
        const cartToken = this.$store.getters.cart.response.cart_token
        const stringDate = moment(this.date).format('YYYY-MM-DD')
        const params = {
          booking_date: stringDate,
          duration: data.duration,
          service_id: data.service_id,
          staff_id: data.staff_id,
          cart_token: cartToken
        }
        fetchListTime(this.selectedLocation.id, params).then(response => {
          this.checkTimeAvailable(data.key, response.data.data.time, from)
        }).catch((error) => {
          this.listItems[data.key].times.available = false
          this.listItems[data.key].times.list = []
          this.listItems[data.key].loading_time = false
          this.$Message.error({
            message: error.string,
            icon: 'icon-x'
          })
          this.checkCartValid()
        })
      }
    },

    async checkTimeAvailable (keys, times, from) {
      const results = []
      await times.map(x => {
        if (x.staff_working_hours_available !== 0 && x.appointment_available !== 0 && x.cart_available !== 0 && x.resource_available !== 0) {
          const stringDate = moment(this.date).format('YYYY-MM-DD')
          const start = moment(stringDate + ' ' + x.time, 'YYYY-MM-DD HH:mm').toDate()
          const nowDate = moment(new Date(Date.now())).toDate()
          const stringNow = moment(new Date(Date.now())).format('YYYY-MM-DD')
          if (stringDate === stringNow) {
            if (!moment(start).isBefore(nowDate) || moment(start).isSame(nowDate)) {
              results.push(x)
            }
          } else {
            results.push(x)
          }
        }
      })
      if (results.length > 0) {
        this.listItems[keys].times.list = results
        const time = this.listItems[keys].selected_time.start
        const isAvailable = results.filter(function (x) {
          if (x.time === time) {
            return x
          }
        })
        if (this.listItems[keys].selected_time.end === null) {
          results.map(x => {
            if (x.time === time) this.listItems[keys].selected_time.end = x.end_time
          })
        }
        if (isAvailable.length <= 0) {
          this.listItems[keys].times.available = false
        } else {
          this.listItems[keys].times.available = true
        }
      } else {
        this.listItems[keys].times.available = false
        this.listItems[keys].times.list = []
      }
      this.validService = true
      if (from === 1) {
        const filtering = this.listItems.filter(function (x) { return x.times.list.length > 0 })
        if (filtering.length === this.listItems.length) this.validService = true
        if (filtering.length < this.listItems.length) this.validService = false
      }
      this.listItems[keys].loading_time = false
      this.listItems[keys].loading_big_time = false
      this.checkCartValid()
    },

    async deleteService (key) {
      this.loading = true
      const index = this.selectedService.findIndex(x => x.id === this.detailService[key].id)
      if (this.selectedService[index]) {
        this.selectedService[index].qty -= 1
        if (this.selectedService[index].qty <= 0) {
          this.selectedService.splice(index, 1)
        }
      }
      this.listItems.splice(key, 1)
      this.detailService.splice(key, 1)
      await this.delay(100)
      this.loading = false
    },

    selectedStaff (keys, staff) {
      this.listItems[keys].selected_staff = staff
      this.listItems[keys].times.available = true
      const params = {
        key: keys,
        duration: this.listItems[keys].duration.strtotime,
        service_id: this.listItems[keys].service_id,
        staff_id: this.listItems[keys].selected_staff.staff_id
      }
      this.getTimes(params, 1)
    },

    async selectedTime (data) {
      this.listItems[data.key].loading_big_time = true
      this.listItems[data.key].selected_time = {
        start: moment(data.start).format('HH:mm'),
        end: moment(data.end).format('HH:mm')
      }
      this.listItems[data.key].times.available = true
      await this.delay(300)
      this.listItems[data.key].loading_big_time = false
      this.reshufleList()
      this.checkCartValid()
    },

    reshufleList () {
      const stringDate = moment(this.date).format('YYYY-MM-DD')
      this.listItems.sort((a, b) => (moment(stringDate + ' ' + b.selected_time.start, 'YYYY-MM-DD HH:mm').isBefore(moment(stringDate + ' ' + a.selected_time.start, 'YYYY-MM-DD HH:mm')) ? 1 : -1))
    },

    checkCartValid () {
      const errorStaff = []
      const errorTime = []
      this.listItems.map(x => {
        if (x.staff_was_load && x.staffs.length <= 0) errorStaff.push(x.id)
        if (!x.times.available) errorTime.push(x.id)
      })
      const temp = errorStaff.concat(errorTime)
      const results = temp.filter((thing, index, self) =>
        index === self.findIndex((y) => (
          y === thing
        ))
      )
      if (results.length === this.listItems.length) {
        this.validService = false
      } else {
        this.validService = true
      }
    },

    setBuildCart () {
      this.loadingConfirm = true
      const data = {
        location_id: this.selectedLocation.id,
        cart_date: moment(this.date).format('YYYY-MM-DD'),
        cartDetail: [],
        source: 3
      }
      this.selectedService.map(x => {
        data.cartDetail.push({
          service_id: x.service_id,
          qty: x.qty,
          service_price_id: x.service_price_id,
          staff: []
        })
      })
      this.setStaffCart(data)
    },

    setStaffCart (data) {
      this.listItems.map(x => {
        data.cartDetail.map(y => {
          if (x.service_price_id === y.service_price_id) {
            let staffId = 0
            if (x.selected_staff.id) staffId = x.selected_staff.id
            if (x.selected_staff.staff_id) staffId = x.selected_staff.staff_id
            y.staff.push({
              staff_id: staffId,
              time: x.selected_time.start
            })
          }
        })
      })
      this.confirmStaff(data)
    },

    confirmStaff (data) {
      const storeService = this.$store.getters.selectedService
      storeService.detail = this.listItems
      storeService.selected = this.selectedService
      const cartToken = this.$store.getters.cart.response.cart_token
      buildCart(data, cartToken).then(response => {
        if (response.data.error === 0) {
          const storeCart = {
            cart: data,
            response: response.data.data
          }
          this.$store.commit('SET_SELECTED_SERVICES', { ...storeService })
          this.$store.commit('SET_DATA_CART', { ...storeCart })
          this.loadingConfirm = false
          this.$store.dispatch('setActiveTab', 'personal-detail')
        } else {
          if (response.data.error.time) {
            this.sameError = response.data.error.time
            this.sameError.map(x => {
              this.$Message.error({
                message: x.error_message,
                icon: 'icon-x'
              })
            })
            this.checkSameStaff(response.data.data)
          }
          this.loadingConfirm = false
        }
      }).catch(error => {
        this.loadingConfirm = false
        this.$Message.error({
          message: error.string,
          icon: 'icon-x'
        })
      })
    },
    checkSameStaff (cart) {
      this.loading = true
      const staffCart = []
      const idCart = []

      this.sameError.map(x => {
        idCart.push(x.cart_detail_staff_id)
      })

      cart.cartDetail.detail.map(x => {
        x.staff.map(y => {
          idCart.map(z => {
            if (y.id === z) {
              staffCart.push(y)
            }
          })
        })
      })

      this.listItems.map(x => {
        staffCart.map(y => {
          if (x.selected_staff.id === y.staff_id || x.selected_staff.staff_id === y.staff_id) x.times.available = false
        })
      })
      this.loading = false
    }
  }
}
</script>
