import Cookies from 'js-cookie'

const SystemTokenKey = 'zenwell-systoken-online-booking'

export function getSystemToken () {
  return Cookies.getJSON(SystemTokenKey)
}

export function setSystemToken (token) {
  return Cookies.set(SystemTokenKey, token, { expires: 1, sameSite: 'Lax', secure: false }) // add expiry 1 day
}

export function removeToken () {
  Cookies.remove(SystemTokenKey)
}
