import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import elementEnLocale from 'at-ui/dist/locale/en-US'
import enLocale from './en'
import idLocale from './id'
Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  id: {
    ...idLocale,
    ...elementEnLocale
  }
}
const i18n = new VueI18n({
  locale: Cookies.get('language-widget') ? Cookies.get('language-widget') : 'en',
  messages
})

export default i18n
