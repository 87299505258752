<template>
  <div
    ref="carousel"
    class="carousel-frame">
    <div
      v-for="(item, n) in slides" :key="n"
      :style="{ backgroundImage: 'url(' + item.photo + ')'}"
      class="company-location carousel"/>
    <div
      v-if="slides.length > 1"
      class="navigation-frame">
      <at-button
        v-for="(nav, x) in slides"
        :key="x"
        :class="[x === indexActive ? 'active' : '']"
        icon
        circle
        class="navigation"
        @click="slideCarousel(x)"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'widgetCarousel',
  props: ['slides'],
  data () {
    return {
      indexActive: 0
    }
  },
  methods: {
    slideCarousel (key) {
      this.indexActive = key
      this.$refs.carousel.scrollLeft = 0
      const stepScroll = (this.$refs.carousel.clientWidth * key)
      if (stepScroll > 0) {
        this.$refs.carousel.scrollLeft += stepScroll
      }
    }
  }
}
</script>
