<template>
  <div class="cz-w-fit">
    <div class="cz-350p center cz-mb-8 cz-overflow-x-hide">
      <div v-if="!loading" class="row at-row no-gutter flex-center flex-middle cz-m-null">
        <div
          v-for="(item, n) in listDate"
          :key="n"
          :class="getBackground(item, n)"
          :style="item.selected ? 'padding-top: 14px; padding-bottom: 8px;' : ''"
          class="col cz-radius-10 cz-ptb-10 cz-post-relative"
          @click="changeDateHeader(item, n)">
          <template v-if="item.selected !== 'block'">
            <div v-if="item.selected" :style="styleMonth">{{ getMonths(item.moment) }}</div>
          </template>
          <div class="cz-text-xs cz-bold">{{ getDays(item.moment) }}</div>
          <div class="cz-text-sm">{{ getDate(item.moment) }}</div>
        </div>
        <div class="col cz-ptb-10">
          <date-picker
            v-model="time"
            :open.sync="openPicker"
            :append-to-body="true"
            :clearable="false"
            :disabled-date="blockingDate"
            :lang="lang"
            @open="blockingDate(time)"
            @change="changeDate">
            <template slot="input">
              <div class="row at-row no-gutter flex-center flex-middle cz-m-null">
                <div class="col cz-cursor" :style="buttonDateShow">
                  <i :class="[openPicker ? 'icon-chevron-up' : 'icon-chevron-down' ]" class="icon cz-text-lg cz-lh-unset"/>
                </div>
              </div>
            </template>
            <template v-slot:footer="{ emit }">
              <div class="cz-w-fit">
                <at-button
                  :disabled="!openNow"
                  type="info"
                  class="cz-btn block cz-bg-input h40 cz-nobold cz-border-null"
                  @click="getToday(emit)">
                  <div class="row at-row flex-middle">
                    <div class="col cz-text-left cz-text-md cz-color-info">{{ todayFormat }}</div>
                    <div class="col cz-text-right cz-text-sm cz-color">{{ $t('label.today') }}</div>
                  </div>
                </at-button>
              </div>
            </template>
          </date-picker>
        </div>
      </div>
      <loading-date :loading="loading"/>
    </div>
  </div>
</template>

<script>
import moment from '../../../utils/moment'
import DatePicker from 'vue2-datepicker'
import loadingDate from '../loading/loadingDate'
export default {
  props: ['dateNow', 'listDate', 'loading', 'openNow'],

  components: {
    DatePicker,
    loadingDate
  },
  data () {
    return {
      dateFormat: 'ddd, DD MMMM YYYY',
      time: new Date(Date.now()),
      openPicker: false,
      isOpenLocation: this.$store.getters.selectedLocation.isOpen,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        },
        monthBeforeYear: false
      }
    }
  },
  watch: {},
  computed: {
    buttonDateShow () {
      if (this.openPicker) {
        return 'background-color: #E0E0E0; width: 40px; height: 40px; line-height: 40px; max-height: 40px; max-width: 40px; border-radius: 50%; align-items:'
      } else {
        return 'width: 40px; height: 40px; line-height: 40px; max-height: 40px; max-width: 40px; border-radius: 50%; align-items:'
      }
    },
    todayFormat () {
      return moment(this.dateNow).format('DD MMM YYYY')
    },
    styleMonth () {
      return 'position: absolute; top: 0px; font-size: 9px; left: 50%; transform: translate(-50%, 0); background-color: #00B082; padding: 1px 4px; border-radius: 0px 0px 5px 5px;'
    }
  },
  created () {
    if (this.$store.getters.language === 'id') {
      this.lang.formatLocale = {
        months: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nop', 'Des'],
        weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
        weekdaysShort: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        weekdaysMin: ['Mg', 'Sn', 'Sl', 'Rb', 'Km', 'Jm', 'Sb'],
        firstDayOfWeek: 1
      }
    }
  },
  mounted () {
    this.selectedDefault()
  },
  methods: {
    selectedDefault () {
      let date = moment(this.dateNow).toDate()
      if (!this.openNow) {
        date = moment(this.dateNow).add(1, 'days').toDate()
      }
      this.time = date
      this.blockingDate(this.time)
    },

    getDays (moments) {
      const listDate = moment(moments).format('YYYY-MM-DD')
      const dateNow = moment(new Date(Date.now())).format('YYYY-MM-DD')
      if (listDate === dateNow) {
        return this.$t('label.today')
      } else {
        return moment(moments).format('ddd')
      }
    },

    getDate (moments) {
      return moment(moments).format('DD')
    },

    getMonths (moments) {
      return moment(moments).format('MMM')
    },

    getBackground (item) {
      if (item.selected === 'block') {
        return 'cz-block-date-new cz-hover-date'
      } else {
        if (item.selected === true) {
          return 'cz-selected-date-new'
        } else {
          return 'cz-hover-date'
        }
      }
    },

    blockingDate (date) {
      let dateMoment = new Date()
      if (this.openNow) {
        dateMoment = moment(date).subtract(1, 'days').toDate()
      }
      return date < new Date(dateMoment)
    },

    changeDateHeader (item, n) {
      if (item.selected !== 'block') {
        if (item.selected === false) {
          this.time = moment(item.moment).toDate()
          this.$emit('change-from-header', n, this.listDate)
        }
      } else {
        this.$Notify.warning({
          title: this.$t('label.not_available')
        })
      }
    },

    getToday (emit) {
      const dateNow = new Date(Date.now())
      if (!this.openNow) {
        this.$Notify.warning({
          title: this.$t('label.not_available')
        })
      } else {
        this.$emit('change-manual-date', emit(dateNow))
      }
    },

    changeDate (date) {
      this.$emit('change-manual-date', date)
    }
  }
}
</script>
