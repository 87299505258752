import Cookies from 'js-cookie'
import { fetchListTreatment } from '../../api/services.js'
import { fetchBank } from '../../api/company.js'
const query = {
  per_page: 500
}

const app = {
  state: {
    device: 'desktop',
    language: Cookies.get('language-widget') ? Cookies.get('language-widget') : 'en',
    treatment: [],
    datapage: {},
    banks: null,
    company: null,
    countPendingRequest: 0,
    activeTab: ''
  },
  mutations: {
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set('language-widget', language, {
        expires: 1,
        sameSite: 'Lax',
        secure: false
      })
    },
    SET_TREATMENT: (state, treatment) => {
      state.treatment = treatment
    },
    SET_DATAPAGE: (state, datapage) => {
      state.datapage = datapage
    },
    SET_LIST_BANK: (state, banks) => {
      state.banks = banks
    },
    SET_COMPANY: (state, companys) => {
      state.company = companys
    },
    SET_COUNT_PENDING_REQUEST: (state, count) => {
      state.countPendingRequest = count
    },
    SET_ACTIVE_TAB: (state, payload) => {
      state.activeTab = payload
    }
  },
  actions: {
    toggleDevice ({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setLanguage ({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setTreatment ({ commit }, treatment) {
      return new Promise((resolve, reject) => {
        fetchListTreatment(query).then(response => {
          commit('SET_TREATMENT', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setDatapage ({ commit }, datapage) {
      console.log(datapage)
      commit('SET_DATAPAGE', { ...datapage })
    },
    setListBank ({ commit }) {
      return new Promise((resolve, reject) => {
        fetchBank().then(response => {
          commit('SET_LIST_BANK', response.data.data)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setCompany ({ commit }, company) {
      commit('SET_COMPANY', company)
    },
    setActiveTab ({ commit }, tab) {
      commit('SET_ACTIVE_TAB', tab)
    }
  }
}

export default app
