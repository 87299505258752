<template>
  <div class="cz-w-fit cz-mb-16">
    <at-card
      :no-hover="true"
      :body-style="{ padding: 0 }"
      :style="{ backgroundColor: '#FAFAFA' }"
      class="cz-radius-10 cz-emboss cz-bg-info">
      <div class="cz-p-16">
        <div class="flex at-flex flex-middle flex-center">
          <div class="col-4 cz-text-left">
            <div :style="{ backgroundImage: 'url(' + detailService[0].photo_xs + ')' }" class="img-thumb-40 img-thumb-cirlce"/>
          </div>
          <div class="col-14">
            <div class="cz-bold cz-text-sm cz-text-left cz-color cz-mb-4">
              {{ detailService[0].name }}
            </div>
            <div class="cz-text-left cz-text-xs cz-color-info">
              {{ startAt }}
              -
              {{ endAt }}
            </div>
            <div
              :style="!showAddress ? 'white-space: nowrap; overflow: hidden' : ''"
              class="cz-w-fit cz-text-xs cz-text-left cz-color-info">
              {{ selectedLocation.address }}
            </div>
            <div class="cz-text-left">
              <at-button
                v-if="!showAddress"
                type="text"
                class="btn cz-p-null cz-color-success cz-nobold"
                @click="showMoreAddress">
                 ...
              </at-button>
              <at-button
                v-else
                type="text"
                class="btn cz-p-null cz-color-success cz-nobold"
                @click="showMoreAddress">
                 hide
              </at-button>
            </div>
          </div>
          <div class="col-6 cz-text-center">
            <div
              class="cz-p-10 cz-radius-10 cz-text-center cz-emboss"
              style="background-color: #ECF5FF; width: 56px; margin-left: auto;">
              <div class="cz-text-xs cz-color">{{ monthBook }}</div>
              <div class="cz-text-lg cz-color-primary cz-bold">{{ datesBook }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="cz-p-16 cz-radius-10 cz-bg-white">
        <div class="flex at-flex flex-middle flex-center">
          <div class="col-3 cz-text-left">
            <i class="icon icon-clock cz-text-lg cz-color"/>
          </div>
          <div class="col-14 cz-text-left">
            <div class="cz-text-sm cz-bold cz-color">
              {{ $t('label.startat') }} {{ startAt }}
            </div>
           <div class="cz-text-xs cz-color-info">
              {{ detailService[0].name }}
              ({{ replaceDuration(detailService[0].duration.desc) }})
            </div>
          </div>
          <div class="col-7 cz-text-right">
            <div class="cz-text-sm cz-semi-bold cz-color">
              {{ totalBook.currency }} {{ formatted }}
              <input v-model="formatted" v-money="money" type="hidden"/>
            </div>
            <div class="cz-text-xs cz-color-info">
              {{ totalBook.total_items }}
              {{ $t('label.items') }}
            </div>
          </div>
        </div>
      </div>
    </at-card>

    <div class="cz-w-fit cz-mtb-24">
      <span class="cz-color cz-text-sm">
        {{ $t('tips.bookingAdd') }}
      </span>
    </div>

    <form class="cz-form cz-post-relative">
      <label class="cz-form-label">
        {{ $t('label.name') }}
      </label>
      <input ref="name" v-model="user.name" type="text" :class="[errorForm.name ? 'error' : '']" :minlength="2" :placeholder="$t('label.name')" required @change="update"/>
      <div class="cz-mb-16"/>
      <label class="cz-form-label">
        {{ $t('label.email') }}
      </label>
      <input ref="email" v-model="user.email" type="email" :class="[errorForm.email ? 'error' : '']" :placeholder="$t('label.email')" required @change="update"/>
      <div class="cz-mb-16"/>
      <label class="cz-form-label">
        {{ $t('label.phoneNumber') }}
      </label>
      <VuePhoneNumberInput
        ref="phoneNumber"
        id="phoneNumber1"
        v-model="phone"
        :default-country-code="countryCode !== '' ? countryCode : 'ID'"
        :translations="customePlaceholder"
        :countries-height="40"
        :class="[errorForm.phone ? 'error' : '']"
        @update="logUser"
      />
      <div class="cz-mb-16"/>

      <div v-if="dataUser.reference === 1">
        <div
          class="flex at-flex flex-center flex-middle cz-m-null cz-bg-input cz-p-8"
          style="border-radius: 3px 3px 0px 0px;">
          <div class="col-4 cz-text-left cz-p-null">
            <img src="../../icons/svg/pin-1.svg" height="24" width="24">
          </div>
          <div class="col cz-text-left cz-p-null">
            {{ $t('label.onMyLocation') }}
          </div>
        </div>
        <div
          class="flex at-flex flex-center flex-middle cz-m-null cz-p-8 cz-border"
          style="border-radius: 0px 0px 3px 3px; border-top: 0px;">
          <div
            :style="styleAddress"
            class="col cz-text-left cz-p-null">
            {{ dataUser.address.faddress}}
            <at-button
              v-if="!hideAddress"
              type="text"
              class="cz-btn btn cz-color-primary cz-p-null"
              @click="wrapAddressUser(hideAddress)">
              {{ 'hide' }}
            </at-button>
          </div>
          <div v-if="hideAddress" class="col-3 cz-text-right cz-p-null">
            <at-button
              type="text"
              class="cz-btn btn cz-color-primary cz-p-null"
              @click="wrapAddressUser(hideAddress)">
              {{ 'more' }}
            </at-button>
          </div>
        </div>
      </div>
      <div class="cz-mb-16"/>

      <label class="cz-form-label">
          {{ $t('label.bookingNotes') }}
      </label>
      <input
        v-model="user.notes"
        :placeholder="$t('label.bookingNotes')"
        name="notes"
        type="text"
      />
      <div class="cz-mb-16"/>
      <!--
      <at-button
        size="large"
        class="cz-w-fit cz-text-left cz-border cz-p-10"
        style="background-color: #F5F5F5; border-color: #F5F5F5;">
        <div class="flex at-flex flex-center flex-middle">
          <div class="col">
            <div class="cz-w-fit cz-text-sm cz-color cz-nobold cz-text-left cz-text-overflow-wrap">
              {{ $t('tips.tipsSaveDetails') }}
            </div>
          </div>

          <div class="col-4">
            <div class="switch-customer">
              <div class="middle-line">
                <at-switch v-model="user.save_details" @change="changeStatusDetails"/>
              </div>
            </div>
          </div>
        </div>
      </at-button>
      -->

      <div class="cz-mb-16"/>
      <div class="cz-mb-16">
        <at-button
          size="large"
          class="cz-w-fit cz-text-left cz-border cz-p-10"
          style="background-color: #F5F5F5; border-color: #F5F5F5;">
          <div class="flex at-flex flex-center flex-middle">
            <div class="col">
              <div class="cz-w-fit cz-text-sm cz-color cz-nobold cz-text-left cz-text-overflow-wrap">
                {{ $t('tips.tipsMarketing') }}
              </div>
            </div>

            <div class="col-4">
              <div class="switch-customer">
                <div class="middle-line">
                  <at-switch v-model="user.marketing" @change="changeStatusMarketing"/>
                </div>
              </div>
            </div>
          </div>
        </at-button>
      </div>

      <div class="cz-mb-16"/>

      <label class="cz-form-label">
        {{ $t('tips.bookingClick') }} '{{ $t('label.confirmBooking') }}!' {{ $t('tips.bookingAgree') }}
      </label>

      <div class="cz-mb-16"/>
      <div class="cz-radius-3 cz-p-10 cz-border cz-mb-16" style="height: 80px; overflow-y: auto;">
        <span class="cz-text-md cz-color">
          {{ $t('messages.comeEarlier') }} :)
        </span>
      </div>

      <div class="cz-mb-16">
        <at-button
          size="large"
          class="cz-w-fit cz-border cz-p-10"
          @click="cancelBook">
          <span class="cz-color-danger">{{ $t('label.cancelBook') }}</span>
        </at-button>
      </div>
      <transition name="slide-test-up">
        <div v-if="!loading && !errorForm.name && !errorForm.email && !errorForm.phone" class="cz-btn-bottom-book">
          <div class="cz-w-fit">
            <at-button
              type="success"
              :loading="loadingButton"
              class="cz-btn block h56 text-lg cz-p-8"
              @click="confirmUser">
              {{ $t('label.confirmBooking') }}
            </at-button>
          </div>
        </div>
      </transition>
    </form>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import moment from '../../utils/moment.js'
export default {
  name: 'FormDetail',
  components: {
    VuePhoneNumberInput
  },

  props: [
    'selectedService',
    'detailService',
    'selectedLocation',
    'dataUser',
    'loadingButton',
    'loading'
  ],

  watch: {
    dataUser: {
      handler (data) {
        if (data.useOldUser) {
          this.user.name = data.name
          this.user.email = data.email
          if (data.phone) {
            this.user.phone = data.phone.phoneNumber
            this.user.notes = data.notes
          }
          this.phone = data.phone.phoneNumber
          this.user.marketing = data.marketing
          this.user.save_details = data.save_details
          this.address = data.faddress
        }
      },
      deep: true,
      immediate: true
    }
  },

  data () {
    return {
      showAddress: false,
      formatted: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true
      },
      countryCode: '',
      user: {
        marketing: false,
        save_details: false
      },
      customePlaceholder: {
        phoneNumberLabel: this.$t('label.phoneNumber')
      },
      phone: '',
      phoneValidation: true,
      errorForm: {
        email: false,
        name: false,
        phone: false
      },
      address: '',
      styleAddress: 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',
      hideAddress: true
    }
  },

  computed: {
    monthBook () {
      const date = this.$store.getters.cart.response.cart_date
      return moment(date).format('MMM')
    },
    datesBook () {
      const date = this.$store.getters.cart.response.cart_date
      return moment(date).format('DD')
    },
    startAt () {
      const date = this.$store.getters.cart.response.cart_date
      const data = this.detailService[0].selected_time.start
      const time = moment(date + ' ' + data, 'YYYY-MM-DD HH:mm').format('HH:mm')
      return time
    },
    endAt () {
      const date = this.$store.getters.cart.response.cart_date
      let data = this.detailService[0].selected_time.end
      if (this.detailService.length > 1) {
        data = this.detailService[this.detailService.length - 1].selected_time.end_time
      }
      const time = moment(date + ' ' + data, 'YYYY-MM-DD HH:mm').format('HH:mm')
      return time
    },
    totalBook () {
      const data = this.getTotal()
      return data
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  mounted () {
  },

  methods: {
    wrapAddressUser (status) {
      if (status) {
        this.hideAddress = false
        this.styleAddress = ''
      } else {
        this.hideAddress = true
        this.styleAddress = 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
      }
    },
    changeStatusMarketing () {
      if (this.user.marketing) {
        this.user.marketing = false
      } else {
        this.user.marketing = true
      }
    },
    changeStatusDetails () {
      if (this.user.save_details) {
        this.user.save_details = false
      } else {
        this.user.save_details = true
      }
    },
    setAddress (address) {
      const data = address.split('')
      if (data.length >= 100) {
        data.splice(20, 99)
      } else {
        data.splice(20, (data.length - 1))
      }
      const newData = data.join('')
      return newData
    },
    showMoreAddress () {
      if (this.showAddress) {
        this.showAddress = false
      } else {
        this.showAddress = true
      }
    },

    replaceDuration (data) {
      let newData = ''
      if (data.includes('h')) {
        newData = data.replace('h', this.$t('duration.h'))
        return newData
      } else {
        return data
      }
    },
    getTotal () {
      const data = {
        total_items: 0,
        total_price: 0,
        currency: this.$store.getters.company.currency.symbol
      }
      this.selectedService.map(x => {
        data.total_items += x.qty
      })
      data.total_price = this.$store.getters.cart.response.cartDetail.total_price
      this.formatted = data.total_price
      return data
    },

    logUser (payload) {
      if (payload.isValid) {
        this.user.phone = payload
        this.errorForm.phone = false
      } else {
        this.errorForm.phone = true
      }
    },
    update () {
      if (!this.$refs.email.validity.valid) {
        this.errorForm.email = true
      } else {
        this.errorForm.email = false
      }
      if (!this.$refs.name.validity.valid) {
        this.errorForm.name = true
      } else {
        this.errorForm.name = false
      }
    },
    confirmUser () {
      if (Object.keys(this.user).length >= 4) {
        this.$emit('confirm', this.user)
      }
    },
    cancelBook () {
      this.$emit('cancel')
    },
    handleResize () {
      if (!this.loading && !this.errorForm.name && !this.errorForm.email && !this.errorForm.phone) {
        this.$emit('show-button', true)
      } else {
        this.$emit('show-button', false)
      }
    }
  }
}
</script>
