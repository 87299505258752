import Vue from 'vue'
import App from './App.vue'
import store from './store/index'

// plugins
import AtUI from 'at-ui'

import 'at-ui-style/css/at.css'
import './assets/css/main.scss'

// style scss customize
import './styles/index.scss'

// widget setup
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'

import i18n from './lang'
import './icons'

import money from 'v-money'
import SmoothPicker from 'vue-smooth-picker'
import 'vue-smooth-picker/dist/css/style.css'
import VueClipboard from 'vue-clipboard2'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, { load: { key: 'AIzaSyA2Dt2etiCUW4cnw38iES-O8T7yPPIA680', libraries: 'places', installComponents: true } })
Vue.use(SmoothPicker)
Vue.use(money)
Vue.use(AtUI)
Vue.use(vueCustomElement)
Vue.use(VueClipboard)
Vue.config.productionTip = false

// new Vue is not needed anymore, as you're initializing the app through vue-custom-element
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// use vue-custom-element
App.store = store
// App.router = router
App.i18n = i18n
Vue.customElement('zenwel-widget', App)
