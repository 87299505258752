<template>
  <div class="svg-frame">
    <div :style="iconUrl" class="svg-icon"/>
  </div>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: ['iconClass', 'className', 'width', 'height'],
  computed: {
    iconUrl () {
      let images = require.context('../../icons/svg/', false, /\.svg$/)
      const url = images('./' + this.iconClass + '.svg')
      const style = 'background-image: url(' + url + '); width: ' + this.width + 'px; height: ' + this.height + 'px;'
      return style
    }
  }
}
</script>
