<template>
  <div v-if="!loading">
    <div v-for="(list, n) in listItems" :key="n">
      <at-card
        :no-hover="true"
        :body-style="{ padding: 0 }"
        :class="[breakTime.length > 1 ? 'cz-mb-4' : 'cz-mb-16']"
        class="cz-radius-10 summary">
        <div slot="title" class="flex at-flex flex-center flex-middle cz-mb-8 cz-p-8">
          <div class="col-4 cz-text-left cz-p-null">
            <div :style="{ backgroundImage: 'url(' + list.photo + ')' }" class="img-thumb-40 cz-border"/>
          </div>

          <div class="col cz-text-left">
            <div class="cz-bold cz-text-sm">{{ list.name }}</div>
            <div class="cz-color-info cz-text-xs cz-inline-block">{{ replaceDuration(list.duration.desc) }}</div>
            <div class="cz-dot-8 cz-bg-info"/>
            <div
              :class="[parseInt(list.special_price) > 0 ? 'cz-text-line' : '']"
              class="cz-inline-block">
              <span class="cz-color-info cz-text-xs">{{ list.fretail_price.replace(',00', '') }}</span>
            </div>
            <div
              v-if="list.special_price > 0"
              class="cz-inline-block cz-ml-4">
              <span class="cz-color-info cz-text-xs">{{ list.fspecial_price.replace(',00', '') }}</span>
            </div>
          </div>

          <div class="col-6 cz-text-right">
            <at-dropdown ref="atdropdown" trigger="click" placement="bottom-right" class="summary-staff">
              <at-button type="text" class="cz-btn" @click="showingStaff(n, list)">
                <div class="flex at-flex flex-end flex-middle">
                  <div class="col">
                    <div
                      v-if="list.selected_staff.photo"
                      :style="{ backgroundImage: 'url(' + list.selected_staff.photo + ')' }"
                      class="img-thumb-40 img-thumb-circle"
                    />
                    <div
                      v-else
                      :style="{ backgroundImage: 'url(' + list.selected_staff.photo_xs + ')' }"
                      class="img-thumb-40 img-thumb-circle"
                    />
                  </div>
                  <div class="col cz-p-null"><i class="icon icon-chevron-down"/></div>
                </div>
              </at-button>
              <at-dropdown-menu slot="menu" style="width: 330px;">
                <template v-if="listItems.length > 1">
                  <at-dropdown-item
                    v-if="listItems.length > 1"
                    class="cz-p-null">
                    <div class="flex at-flex flex-middle flex-center">
                      <div class="col cz-text-left cz-p-8">
                        <span class="cz-text-sm cz-color">{{ $t('label.chooseStaff') }}</span>
                      </div>
                      <div class="col cz-text-right cz-p-8">
                        <div class="button cz-text-sm cz-color-danger cz-bold" @click="deleted(n)">
                          {{ $t('label.removeService') }}
                        </div>
                      </div>
                    </div>
                  </at-dropdown-item>
                </template>

                <at-dropdown-item
                  v-if="list.loading_staff"
                  class="cz-p-null">
                  <div
                    v-for="loading in 4"
                    :key="loading"
                    class="box-loading"
                    style="height: 40px; margin: 4px 4px; border-radius: 10px;"
                  />
                </at-dropdown-item>

                <template v-if="list.staffs.length > 0">
                  <div v-for="(listStaff, keyStaff) in list.staffs" :key="keyStaff">
                    <at-dropdown-item
                      :class="[listStaff.id === list.selected_staff.staff_id || listStaff.id === list.selected_staff.id ? 'cz-bg-success-light no-hover' : '']"
                      class="cz-p-null">
                      <div
                        :title="listStaff.name"
                        class="flex at-flex flex-middle flex-center"
                        @click="selectedStaff(n, listStaff)">
                        <div class="col-4 cz-p-8 cz-text-left">
                          <div
                            v-if="listStaff.photo_xs"
                            :style="{ backgroundImage: 'url(' + listStaff.photo_xs + ')' }"
                            class="img-thumb-40 img-thumb-circle"
                          />
                          <div
                            v-else
                            :style="{ backgroundImage: 'url(' + listStaff.photo + ')' }"
                            class="img-thumb-40 img-thumb-circle"
                          />
                        </div>
                        <div class="col cz-p-8 cz-text-left">
                          <div
                            :class="[listStaff.id === list.id ? 'cz-bold' : '']"
                            class="cz-w-fit cz-text-overflow-dot"
                            style="text-transform: capitalize;">
                            <div class="cz-text-sm cz-color">{{ listStaff.name }}</div>
                          </div>
                        </div>
                      </div>
                    </at-dropdown-item>
                  </div>
                </template>
              </at-dropdown-menu>
            </at-dropdown>
          </div>
        </div>
        <div
          v-if="!list.loading_big_time"
          :style="!setBackgroudDefault(list) ? 'background-color: #FFDCE5;' : ''"
          class="flex at-flex flex-middle flex-center cz-p-16 cz-radius-10">
          <template v-if="list.times.available || list.staffs.length > 0">
            <div class="col-3 cz-text-left cz-p-null">
              <i class="icon-clock icon cz-text-lg"/>
            </div>
            <div class="col cz-text-left">
              <span class="cz-text-md cz-color">{{ list.selected_time.start }} - </span>
              <span class="cz-text-md cz-color">
                <template v-if="list.selected_time.end">{{ list.selected_time.end }}</template>
                <template v-else>{{ getEndTime(list.duration.strtotime, list.selected_time.start) }}</template>
              </span>
            </div>
            <div class="col-4 cz-p-null cz-text-right">
              <at-dropdown trigger="click" placement="bottom-right" class="summary-time">
                <at-button type="text" class="cz-btn cz-p-null" @click="showingTimes(n, list)">
                  <i class="icon icon-chevron-down cz-text-lg"/>
                </at-button>

                <at-dropdown-menu slot="menu" style="width: 330px;">
                  <at-dropdown-item
                    v-if="list.loading_time"
                    class="cz-p-null">
                    <div
                      v-for="loading in 4"
                      :key="loading"
                      class="box-loading"
                      style="height: 40px; margin: 4px 4px; border-radius: 10px;"
                    />
                  </at-dropdown-item>
                  <template v-if="!list.loading_time">
                    <at-dropdown-item
                      v-for="(time, keyTime) in list.times.list"
                      :key="keyTime"
                      class="cz-p-null">
                      <at-button
                        :class="[selectedBackground(time, list.selected_time, list.duration.strtotime) ? 'cz-bg-success-light' : '']"
                        class="cz-btn block cz-border-null cz-p-8 cz-radius-0"
                        style="border-radius: 0px;"
                        @click="selectedTime(n, time)">
                        <div class="flex at-flex flex-center flex-middle">
                          <div class="cz-color col-3 cz-text-left">
                            <i class="icon icon-clock cz-text-lg"/>
                          </div>
                          <div class="col cz-text-left">
                            <span class="cz-color cz-text-md cz-nobold">
                              {{ time.time }} -
                            </span>
                            <span class="cz-color cz-text-md cz-nobold">
                              {{ time.end_time }}
                            </span>
                          </div>
                        </div>
                      </at-button>
                    </at-dropdown-item>
                  </template>
                </at-dropdown-menu>
              </at-dropdown>
            </div>
          </template>

          <template v-else>
            <div class="col-3 cz-text-left cz-p-null cz-color-danger">
              <i class="icon icon-clock cz-text-lg"/>
            </div>
            <div class="col cz-text-left">
              <span class="cz-text-md cz-color-danger">{{ $t('label.not_available') }} </span>
            </div>
          </template>
        </div>

        <div v-else class="box-loading" style="height: 56px; border-radius: 10px;"/>
      </at-card>
      <template v-if="!loading && breakTime.length > 0">
        <div
          v-for="(breaks, x) in breakTime"
          :key="x">
          <template v-if="breaks.value.h > 0 || breaks.value.min > 0">
            <div v-if="x === n && x !== (breakTime.length - 1)" class="row at-row flex-center flex-middle cz-mtb-8">
              <div class="col cz-text-right">
                <img
                  src="../../icons/svg/break-info.svg"
                  height="24"
                  width="24"
                >
              </div>
              <div class="col cz-text-left">
                {{ breaks.break }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from '../../utils/moment.js'

export default {
  name: 'listSummary',
  props: ['listItems', 'loading', 'date'],

  computed: {
    breakTime () {
      const data = []
      const stringDate = moment(this.date).format('YYYY-MM-DD')
      for (var i = 0; i < this.listItems.length; i++) {
        const end1 = moment(stringDate + ' ' + this.listItems[i].selected_time.end, 'YYYY-MM-DD HH:mm')
        let end2 = end1
        if (this.listItems[i + 1]) {
          end2 = moment(stringDate + ' ' + this.listItems[i + 1].selected_time.start, 'YYYY-MM-DD HH:mm')
        }
        const duration = moment.duration(end2.diff(end1))
        let result = ''
        if (duration.hours() > 0) {
          result = duration.hours() + this.$t('duration.h') + ' ' + duration.minutes() + this.$t('duration.min')
        } else {
          result = duration.minutes() + this.$t('duration.min')
        }
        data.push({
          index: i,
          break: result,
          value: {
            h: duration.hours(),
            min: duration.minutes(),
            sec: duration.seconds()
          }
        })
      }
      return data
    }
  },

  methods: {
    replaceDuration (data) {
      let newData = ''
      if (data.includes('h')) {
        newData = data.replace('h', this.$t('duration.h') + ' ')
        return newData
      } else {
        return data
      }
    },

    formattedStart (time) {
      return moment(time).format('HH:mm')
    },

    formattedEnd (time, duration) {
      return moment(time).add(duration, 'seconds').format('HH:mm')
    },

    showingStaff (keys, list) {
      if (list.staffs.length <= 0) {
        const data = { key: keys, detail: list }
        this.$emit('load-staff', data)
      }
    },

    showingTimes (keys, list) {
      if (list.times.list.length <= 0) {
        let idStaff = list.selected_staff.id
        if (list.selected_staff.staff_id) {
          idStaff = list.selected_staff.staff_id
        }
        const data = {
          key: keys,
          duration: list.duration.strtotime,
          service_id: list.service_id,
          staff_id: idStaff
        }
        this.$emit('load-time', data, 0)
      }
    },

    deleted (key) {
      this.$emit('delete', key)
    },

    selectedStaff (key, staff) {
      this.$emit('selected-staff', key, staff)
    },

    selectedTime (keys, time) {
      const date = moment(this.date).format('YYYY-MM-DD')
      const data = {
        key: keys,
        start: moment(date + ' ' + time.time, 'YYYY-MM-DD HH:mm'),
        end: moment(date + ' ' + time.end_time, 'YYYY-MM-DD HH:mm')
      }
      this.$emit('selected-time', data)
      /*
      const results = []
      if (this.listItems.length > 1) {
        for (var i = 0; i < this.listItems.length; i++) {
          if (keys !== i) {
            if (this.listItems[i].selected_staff.staff_id === this.listItems[keys].selected_staff.staff_id) {
              let startBefore = moment(date + ' ' + this.listItems[i].selected_time.start, 'YYYY-MM-DD HH:mm')
              let endBefore = moment(date + ' ' + this.listItems[i].selected_time.end, 'YYYY-MM-DD HH:mm').add((this.listItems[i].duration.strtotime), 'seconds')
              if (data.start.isBetween(startBefore, endBefore)) results.push(i)
              if (data.end.isBetween(startBefore, endBefore)) results.push(i)
            }
          }
        }
      }
      if (results.length > 0) {
        this.$Message.warning({
          message: moment(data.start).format('HH:mm') + ' - ' + moment(data.end).format('HH:mm') + ' ' + this.$t('label.not_available'),
          icon: 'icon-alert-circle'
        })
      } else {
        this.$emit('selected-time', data)
      }
      */
    },

    selectedBackground (time, staff, duration) {
      let results = false
      if (staff.end_time) {
        if (time.time === staff.start && time.end_time === staff.end) results = true
      } else {
        if (time.time === staff.start && time.end_time === this.getEndTime(duration, staff.start)) results = true
      }
      return results
    },

    setBackgroudDefault (list) {
      let results = true
      if (list.times.available) {
        if (list.staff_was_load) {
          if (list.staffs.length <= 0) {
            results = false
          }
        } else {
          results = true
        }
      } else {
        results = false
      }
      return results
    },

    getEndTime (duration, start) {
      const stringDate = moment(this.date).format('YYYY-MM-DD')
      const end = moment(stringDate + ' ' + start, 'YYYY-MM-DD HH:mm').add(duration, 'seconds')
      const results = end.format('HH:mm')
      return results
    }
  }
}
</script>
