import axios from 'axios'
import request from '../utils/request.js'
import store from '../store'

const CancelToken = axios.CancelToken
const endPoint = '/locationShiftTime/show/'
let cancelRequest

export function fetchListTime (id, query) {
  return request({
    url: store.getters.language + endPoint + id,
    method: 'get',
    params: query,
    cancelToken: new CancelToken(function executor (c) { cancelRequest = c })
  })
}

export default cancelRequest
