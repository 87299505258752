export default {
  label: {
    name: 'Nama',
    email: 'Email',
    phoneNumber: 'Nomor telepon',
    bookingNotes: 'Catatan pesanan',
    steps: 'step',
    of: 'dari',
    selectLocation: 'Pilih Lokasi',
    openNow: 'Buka',
    openSoon: 'Segera buka',
    closeSoon: 'Segera tutup',
    close: 'Tutup',
    moreLocations: 'Lebih banyak lokasi',
    bookNow: 'Pesan sekarang',
    locations: 'Lokasi',
    items: 'item',
    categories: 'Kategori',
    staff: 'Staf',
    chooseService: 'Pilih service',
    services: 'Servis',
    selectDate: 'Pilih tanggal',
    selectStaff: 'Pilih staf',
    noStaff: 'Tidak ada referensi staf',
    pickTime: 'Pilih waktu',
    afternoon: 'Sore',
    morning: 'Pagi',
    selectDateStaff: 'Staf & tanggal',
    serviceSchedule: 'Jadwal servis',
    payment: 'Pembayaran',
    selectPayment: 'Pilih pembayaran',
    creditcard: 'Kartu kredit / Kartu debit',
    payOnSpot: 'Bayar di tempat',
    useVoucher: 'Gunakan voucher',
    amount: 'Jumlah',
    cardInformation: 'Informasi kartu',
    cardHolderName: 'Nama pemegang kartu',
    cardNumber: 'Nomor kartu',
    validThru: 'Berlaku sampai',
    cvv: 'CVV',
    poweredBy: 'Dipersembahkan oleh',
    payNow: 'Bayar sekarang',
    cancel: 'Batal',
    done: 'Selesai',
    months: 'Bulan',
    years: 'Tahun',
    reload: 'Muat ulang',
    today: 'Hari ini',
    not_available: 'Tidak tersedia!',
    required_name: 'nama wajib diisi!',
    required_email: 'email tidak valid!',
    required_mobile: 'nomor telepon tidak valid!',
    required_card: 'minimal 12 digit angka!',
    required: 'wajib di isi!',
    required_valid: 'Masa berlaku tidak valid!',
    cancelingService: 'Batalkan servis',
    timeHasExpired: 'Waktu habis',
    tryAgain: 'Coba lagi',
    succesBook: 'Pesanan Anda telah dibuat.',
    makeAnotherBook: 'Buat pemesanan lain',
    startat: 'Mulai dari',
    dateTime: 'Tanggal & Waktu',
    chooseStaff: 'Pilih staf',
    removeService: 'Hapus servis',
    onMyLocation: 'Lokasi saya',
    onServiceProvider: 'Lokasi Penyedia Servis',
    onSwitchBookBy: 'Aktifkan untuk memesan sebagai ',
    recentLocation: 'Location terbaru',
    useLocation: 'Gunakan',
    setYourLocation: 'Tetapkan Lokasi Anda',
    selectProvider: 'Pilih Penyedia',
    near: 'Dekat',
    book: 'Pesan',
    selectItem: 'Pilih Item',
    add: 'Tambah',
    selectTime: 'Pilih Waktu',
    summary: 'Ringkasan',
    cancelBook: 'Batalkan pesanan',
    change: 'ubah',
    confirmBooking: 'Konfirmasi Pesanan',
    back: 'Kembali',
    main: 'Menu utama',
    onlineBooking: 'Pemesanan Online',
    payTransfer: 'Transfer Bank',
    toPay: 'Bayar',
    enterPromoCode: 'Masukan kode promo',
    discard: 'Buang',
    keep: 'Gunakan',
    tryAnotherPay: 'Pembayaran lain',
    orderDetails: 'Detail pesanan',
    payTo: 'Bayar ke',
    boughtItems: 'Item',
    total: 'Total',
    subTotal: 'Subtotal',
    discount: 'Discount',
    confirm: 'Konfirmasi',
    unisex: 'Uniseks',
    facility: 'Fasilitas',
    class: 'Kelas',
    service: 'Layanan',
    male: 'Pria',
    female: 'Wanita',
    contact: 'Kontak',
    chargeNow: 'Charge Sekarang'
  },
  tips: {
    timeStart: 'Kapan kamu ingin memulai?',
    bookingAdd: 'Tambahkan detail informasi anda untuk mengkonfirmasi pesanan',
    bookingClick: 'Dengan mengklik',
    bookingAgree: 'Anda menyetujui kebijakan berikut.',
    creditCardTips: 'Bayar dengan kartu Visa atau MasterCard',
    payOnSpotTips: 'Bayar nanti di tempat pemesanan',
    useVoucherTips: 'Apakah anda punya voucher?',
    needMorePayTips: 'Butuh pemesanan lebih nyaman?',
    tryTips: 'Coba',
    itTips: 'itu',
    freeTips: 'Gratis',
    beautyTips: 'Aplikasi pemesanan kecantikan dan kebugaran',
    ccvTips: '3 digit angka terakhir di belakang kartu Anda.',
    tipsMarketing: 'Menerima pemberitahuan pemasaran',
    tipsAppointmentTakePlace: 'Di mana layanan akan berlangsung?',
    tipsSaveDetails: 'Simpan detail untuk pemesanan mendatang',
    payTransferTips: 'Bayar dengan Transfer',
    tipsPleasePay: 'Silahkan bayar dalam',
    tipsForFailedPay: 'Tapi, jangan khawatir kamu masih bisa mencoba dengan pembayaran lain.',
    tipsDetailLocation: 'Berdasarkan jenis kelamin layanan yang dimilikinya, penyedia ini untuk '
  },
  messages: {
    locationNull: 'Lokasi tidak tersedia!',
    serviceNull: 'Item tidak tersedia!',
    locationClose: 'Lokasi ini sudah tutup!',
    locationNotFound: 'Lokasi tidak ditemukan!',
    categoriesNull: 'Kategori tidak ditemukan!',
    staffNotValid: 'Anda harus memilih satu staf untuk masing-masing service!',
    dateNotValid: 'Tanggal tidak diperbolehkan!',
    monthNotValid: 'Bulan tidak diperbolehkan!',
    staffNotAvailable: ' tidak tersedia pada jam ',
    comeEarlier: 'Mohon datang 10 menit lebih awal',
    inCompleteForm: 'Mohon lengkapi formulir ini!',
    errorTimePicker: 'Staff lain belum memiliki waktu!',
    messageTimeOver: 'Jangan khawatir, kembali pilih waktu dan konfirmasi kembali pesanan Anda',
    messageBookSuccess: 'Konfirmasi email telah dikirim ke',
    noWorkingHours: ' belum memiliki jam kerja!',
    durationError: 'Maaf servis ini hanya tersedia sampai ',
    durationErrorClock: 'pukul ',
    timeNotAvailable: 'Maaf waktu tidak tersedia!',
    messagesCancelBook: 'Apakah kamu yakin ingin membatalkan pemesanan ini?',
    paymentNotSupport: ' tidak tersedia untuk ',
    sorry: 'Maaf',
    fallbackErrMessage: 'Terjadi kesalahan. Silakan refresh halaman.'
  },
  month: {
    first: 'Januari',
    second: 'Februari',
    third: 'Maret',
    fourth: 'April',
    fifth: 'Mei',
    sixth: 'Juni',
    seventh: 'Juli',
    eighth: 'Agustus',
    ninth: 'September',
    tenth: 'Oktober',
    eleventh: 'Nopember',
    twelveth: 'Desember'
  },
  duration: {
    min: 'm',
    h: 'j'
  },
  gender: {
    e: 'Semua orang',
    m: 'Pria',
    f: 'Wanita'
  },
  title: {
    booking_confirmation: 'Konfirmasi Pemesanan',
    method_payment: 'Metode Pembayaran'
  },
  transfer_m_bni: {
    _1: 'Buka aplikasi <b>BNI Mobile Banking</b> dan login',
    _2: 'Pilih menu <b>Transfer</b>',
    _3: 'Pilih menu <b>Penagihan Akun Virtual</b>',
    _4: 'Pilih rekening bank yang ingin Anda gunakan',
    _5: 'Masukkan <b>16</b> digit nomor akun virtual',
    _6: 'Informasi penagihan akan muncul di halaman validasi pembayaran',
    _7: 'Jika informasi itu benar, masukkan kata sandi Anda untuk melanjutkan pembayaran',
    _8: 'Transaksi Anda akan diproses'
  },
  transfer_i_bni: {
    _1: 'Pergi ke <b> https://ibank.bni.co.id </b> lalu klik <b> Login </b>',
    _2: 'Lanjutkan login dengan <b> ID Pengguna Anda </b> dan <b> Kata Sandi </b>',
    _3: 'Klik <b> Transfer </b> lalu <b> Tambahkan Akun Favorit </b> dan pilih <b> Antar Rekening BNI </b>',
    _4: 'Masukkan nama akun, nomor akun, dan email lalu klik <b> Lanjutkan </b>',
    _5: 'Masukkan <b> Kode Otentikasi </b> dari token Anda dan kemudian klik <b> Lanjutkan </b>',
    _6: 'Kembali ke menu utama dan pilih <b> Transfer </b> dan kemudian <b> Transfer Antar Rekening BNI </b>',
    _7: 'Pilih akun yang baru saja Anda buat pada langkah sebelumnya sebagai <b> Rekening Tujuan </b> dan isi sisanya sebelum mengklik <b> Lanjutkan </b>',
    _8: 'Periksa apakah detailnya benar, jika benar, silakan masukkan <b> Kode Otentikasi </b> dan klik <b> Lanjutkan </b> dan selesai'
  },
  transfer_permata: {
    _1: 'Di menu utama, pilih <b>Transaksi Lain</b>',
    _2: 'Pilih <b>Pembayaran</b>',
    _3: 'Pilih <b>Pembayaran Lain</b>',
    _4: 'Pilih <b>Akun Virtual</b>',
    _5: 'Masukkan 16 digit Akun No dan tekan <b>Benar/Correct</b>',
    _6: 'Jumlah yang harus dibayar, nomor akun, dan nama merchant akan muncul di halaman konfirmasi pembayaran. Jika informasinya benar, tekan <b>Benar/Correct</b>',
    _7: 'Pilih akun pembayaran Anda dan tekan <b>Benar/Correct</b>'
  },
  transfer_m_bca: {
    _1: 'Masuk ke aplikasi <b>BCA Mobile</b> Anda',
    _2: 'Pilih <b>m-BCA</b>, lalu masukkan kode <b>akses m-BCA</b> Anda',
    _3: 'Pilih <b>m-Transfer</b>, lalu pilih BCA Virtual Account',
    _4: '<b>Masukkan Nomor Akun Virtual</b> atau pilih akun yang ada dari <b>Daftar Transfer</b>',
    _5: 'Masukkan <b>jumlah yang harus dibayar</b>',
    _6: 'Masukkan pin <b>m-BCA</b> Anda',
    _7: 'Pembayaran selesai. Simpan notifikasi sebagai tanda terima pembayaran Anda'
  },
  transfer_atm_bca: {
    _1: 'Di menu utama, pilih <b>Transaksi Lain</b>',
    _2: 'Pilih <b>Transfer</b>',
    _3: 'Pilih <b>Transfer Ke Rekening Virtual BCA</b>',
    _4: 'Masukkan <b>Kode Pembayaran</b> Anda (kode 11 digit) dan klick <b>Benar/Correct</b>',
    _5: 'Masukkan jumlah penuh yang harus dibayar dan klik <b>Benar/Correct</b>',
    _6: 'Detail pembayaran Anda akan muncul di halaman konfirmasi pembayaran. Jika informasinya benar klik <b>Ya/Yes</b> '
  },
  transfer_click_bca: {
    _1: 'Pilih Menu <b>Transfer Dana</b>',
    _2: 'Pilih <b>Transfer Ke Rekening Virtual BCA</b>',
    _3: '<b>Masukkan Nomor Virtual Account BCA</b> atau <b>Pilih dari daftar Transfer</b> dan <b>klik Lanjutkan/Continue</b>',
    _4: 'Jumlah yang harus dibayar, nomor akun dan nama Pedagang akan muncul di halaman konfirmasi pembayaran, jika informasinya benar klik <b>Lanjutkan/Continue</b>',
    _5: 'Dapatkan <b>BCA token</b> Anda dan masukkan Respon KEYBCA <b>APPLI 1</b> dan klik <b>Kirim/Submit/OK</b>',
    _6: 'Transaksi Anda Selesai'
  }
}
