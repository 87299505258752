const services = {
  state: {
    services: null,
    selectedService: null,
    selectedTimeStart: null
  },

  mutations: {
    SET_SERVICES: (state, services) => {
      state.services = services
    },
    SET_SELECTED_SERVICES: (state, selectedService) => {
      state.selectedService = selectedService
    },
    SET_SELECTED_TIMES_START: (state, selectedTime) => {
      state.selectedTimeStart = selectedTime
    }
  }
}

export default services
