<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <header-title :loading="false" :title="$t('label.payment')"/>
        </div>
      </div>
      <div class="cz-body">
        <transition name="fade-time">
          <div v-if="!loading" :style="styleList" class="content">
            <at-card
              :no-hover="true"
              :body-style="{ padding: '20px 16px' }"
              class="cz-emboss cz-radius-10">
              <div class="flex at-flex flex-center flex-middle">
                <div class="col">
                  <div class="cz-color cz-text-left cz-text-xs cz-color-info">{{ $t('label.toPay') }}</div>
                  <div class="cz-color cz-text-left cz-text-lg cz-color cz-bold">
                    {{ overAllTotal.format }}
                  </div>
                </div>
                <div class="col-3">
                  <i
                    class="icon icon-chevron-down cz-text-lg cz-color-info cz-cursor"
                    @click="showModalRight(true)"/>
                </div>
              </div>
            </at-card>
            <div class="cz-w-fit cz-text-right cz-color cz-mt-8 cz-text-xs cz-color-info">
              {{ $t('tips.tipsPleasePay') }} {{ timer }}
            </div>
            <!---->
            <div v-if="!loading && availablePay.length > 0" class="cz-w-fit cz-mtb-16">
              <div class="row at-row flex-center flex-middle cz-m-null">
                <div
                  v-for="(item, n) in availablePay"
                  :key="n"
                  :class="[(n + 1) === availablePay.length ? 'cz-mb-32' : 'cz-mb-16']"
                  class="cz-w-fit">
                  <at-button class="cz-btn cz-p-16 block cz-border" @click="setPaymentMethod(item.id)">
                    <div class="row at-flex flex-middle flex-center">
                      <div class="col-3 cz-p-null cz-text-left">
                        <template v-if="item.id === 8">
                          <i class="icon icon-map-pin cz-text-lg cz-color"/>
                        </template>
                        <template v-else>
                          <i class="icon icon-credit-card cz-text-lg cz-color"/>
                        </template>
                      </div>
                      <div class="col-18 cz-p-null cz-text-left">
                        <div class="cz-w-fit cz-text-overflow-wrap cz-text-sm cz-color">
                          <template v-if="item.id === 7">
                            {{ $t('label.creditcard') }}
                          </template>
                          <template v-else-if="item.id === 8">
                            {{ $t('label.payOnSpot') }}
                          </template>
                          <template v-else>
                            ATM / {{ $t('label.payTransfer') }}
                          </template>
                        </div>
                        <div class="cz-w-fit cz-text-overflow-wrap cz-text-xs cz-color-info">
                          <template v-if="item.id === 7">
                            {{ $t('tips.creditCardTips') }}
                          </template>
                          <template v-else-if="item.id === 8">
                            {{ $t('tips.payOnSpotTips') }}
                          </template>
                          <template v-else>
                            {{ $t('tips.payTransferTips') }}
                          </template>
                        </div>
                      </div>
                      <div class="col-3 cz-p-null cz-text-right">
                        <i class="icon icon-chevron-right cz-text-lg"/>
                      </div>
                    </div>
                  </at-button>
                </div>
              </div>
              <!-- Vouchers -->
              <!--
              <div class="cz-w-fit cz-mb-32">
                <at-button
                  size="large"
                  class="cz-w-fit cz-text-left cz-border"
                  :style="styleButton">
                  <div class="cz-table">
                    <div class="cz-cell">
                      <div class="cz-text-sm cz-color">
                        {{ $t('tips.useVoucherTips') }}
                      </div>
                    </div>

                    <div class="cz-cell cz-text-right">
                      <at-button
                        type="info"
                        class="cz-btn cz-bg-dark-info"
                        @click="usingVoucher">
                        {{ $t('label.useVoucher') }}
                      </at-button>
                    </div>
                  </div>
                </at-button>
              </div>
              -->
            </div>

            <!--
            <div ref="banner" class="banner-payments">
              <div class="cz-table cz-mb-8">
                <div class="cz-cell">
                  <span class="cz-text-md cz-color">
                    {{ $t('tips.needMorePayTips') }}
                  </span>
                </div>
              </div>

              <div class="cz-table">
                <div class="cz-cell cz-pr-8">
                  <span class="cz-text-lg cz-color">
                    {{ $t('tips.tryTips') }}
                  </span>
                </div>
                <div class="cz-cell cz-pr-8">
                  <span class="cz-text-lg cz-color">
                    <img src="../assets/img/zenwel logo.svg" class="cz-w-fit">
                  </span>
                </div>
                <div class="cz-cell">
                  <span class="cz-text-lg cz-color">
                    <template v-if="$store.getters.language === 'id'">
                      {{ $t('tips.freeTips') }}!
                    </template>
                    <template v-else>
                      {{ $t('tips.itTips') }}'s
                      {{ $t('tips.freeTips') }}!
                    </template>
                  </span>
                </div>
              </div>
              <div class="cz-table">
                <div class="cz-cell">
                  <span class="cz-text-xs cz-color-info">
                    {{ $t('tips.beautyTips') }}.
                  </span>
                </div>
              </div>

              <div class="cz-table cz-mtb-16">
                <div class="cz-cell cz-pr-8">
                  <img src="../assets/img/01-app-store-btn@2x.png" height="48" class="cz-w-fit">
                </div>
                <div class="cz-cell cz-pl-8">
                  <img src="../assets/img/02-play-store-btn@2x.png" height="48" class="cz-w-fit">
                </div>
              </div>
            </div>
            -->
          </div>
        </transition>

        <loading-page :loading="loading" :data-loading="loaderDropdown"/>

        <loading-page :loading="loading" :data-loading="loaderOptions"/>
      </div>

      <transition name="fade-steady">
        <div v-if="showDetail" class="cz-modal-right">
          <transition name="slide-right">
            <div v-if="!frameReady" class="cz-content-modal use-header">
              <div class="cz-modal-header">
                <div class="flex at-flex flex-center flex-middle cz-mb-8">
                  <div class="col cz-text-md cz-color cz-text-left cz-bold cz-m-null cz-p-null">
                    {{ $t('label.orderDetails') }}
                  </div>
                  <div class="col-6 cz-text-right cz-color cz-m-null cz-p-null">
                    <at-button type="info" class="cz-btn cz-radius-3" @click="showModalRight(false)">
                      Esc
                    </at-button>
                  </div>
                  <div class="col-2 cz-text-right cz-color cz-m-null cz-p-null">
                    <span @click="showModalRight(false)">
                      <i class="icon icon-x cz-text-lg cz-color cz-cursor"/>
                    </span>
                  </div>
                </div>
              </div>

              <div class="cz-modal-body">
                <div class="cz-w-fit cz-border-bottom cz-mb-16">
                  <div class="cz-color-info cz-text-xs cz-bold cz-mb-16">
                    {{ $t('label.boughtItems') }}
                  </div>
                  <div v-for="(item, n) in detailCart.detail" :key="n">
                    <div
                      :class="[n === (detailCart.detail.length - 1) ? 'cz-mb-16' : 'cz-mb-8']"
                      class="flex at-flex flex-center flex-middle cz-p-null">
                      <div class="col cz-text-left cz-m-null cz-p-null" style="width: 50%">
                        <span class="cz-text-sm cz-color">{{ item.name }}</span>
                      </div>
                      <div class="col cz-text-right cz-m-null cz-p-null" style="width: 25%">
                        <span class="cz-text-sm cz-color cz-bold">
                          ({{ item.qty }})
                          {{ item.price.replace(',00', '') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cz-w-fit cz-border-bottom cz-mb-16">
                  <div class="cz-color-info cz-text-xs cz-bold cz-mb-16">
                    {{ $t('label.toPay') }}
                  </div>
                  <div
                    class="flex at-flex flex-center flex-middle cz-mb-8 cz-p-null">
                    <div class="col cz-text-left cz-m-null cz-p-null" style="width: 50%">
                      <span class="cz-text-sm cz-color">{{ $t('label.subTotal') }}</span>
                    </div>
                    <div class="col cz-text-right cz-m-null cz-p-null" style="width: 25%">
                      <span class="cz-text-sm cz-color cz-bold">
                        ({{ subTotal.qty }})
                        {{ subTotal.ftotal }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex at-flex flex-center flex-middle cz-mb-8 cz-p-null">
                    <div class="col cz-text-left cz-m-null cz-p-null" style="width: 50%">
                      <span class="cz-text-sm cz-color">{{ $t('label.discount') }}</span>
                    </div>
                    <div class="col cz-text-right cz-m-null cz-p-null" style="width: 25%">
                      <span class="cz-text-sm cz-color cz-bold">
                        {{ discount }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="cz-w-fit cz-mb-16">
                  <div class="flex at-flex flex-center flex-middle cz-mb-8">
                    <div class="col cz-text-left cz-p-null">
                      <span class="cz-text-sm cz-color cz-bold">{{ $t('label.total') }}</span>
                    </div>
                    <div class="col cz-text-right cz-p-null">
                      <span class="cz-text-sm cz-color cz-bold">
                        {{ overAllTotal.format }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </transition>

      <transition name="fade-steady">
        <div v-if="modalListBank && !loading" class="cz-modal">
          <div class="cz-content-modal new-service cz-post-relative" style="min-height: unset; max-width: 480px;">
            <div class="cz-btn-close-modal">
              <i class="icon icon-x cz-text-lg cz-cursor" @click="modalListBank = false"/>
            </div>
            <div class="cz-w-fit cz-mb-16">
              <div class="cz-color cz-bold cz-text-lg cz-text-center">
                ATM / {{ $t('label.payTransfer') }}
              </div>
            </div>
            <at-button
              v-for="(item, n) in dataListBank"
              :key="n"
              class="cz-w-fit cz-p-8 cz-m-null cz-border-null"
              @click="setTransferBank(item)">
              <div class="flex at-flex flex-middle flex-center">
                <div :class="[widthScreen <= 400 ? 'col-7' : 'col-4' ]" class="cz-text-left cz-p-null">
                  <div
                    :style="{ backgroundImage: 'url(' + item.icon + ')'}"
                    class="img-thumb-40 cz-bg-white"
                    style="width: 64px; background-size: contain;"
                  />
                </div>
                <div :class="[widthScreen <= 400 ? 'col-15' : 'col' ]" class="cz-color cz-bold cz-text-sm cz-text-left">
                  <div class="cz-pl-8 cz-w-fit cz-text-left">{{ item.bank.toUpperCase() }}</div>
                </div>
                <div :class="[widthScreen <= 400 ? 'col-2' : 'col-3']">
                  <div class="cz-pr-8 cz-w-fit cz-text-right">
                    <i class="icon icon-chevron-right cz-text-lg"/>
                  </div>
                </div>
              </div>
            </at-button>
          </div>
        </div>
      </transition>

      <loading-circle :loading="loadingPay"/>
    </div>
  </transition>
</template>

<script>
import { create } from '../../api/booking'
import moment from '../../utils/moment'
import headerTitle from '../../components/UI/title'
// import loadingPayment from '../../components/UI/loading/loadingPayment'
import loadingPage from '../../components/UI/loading'
import loadingCircle from '../../components/UI/loading/loadingCircle'
export default {
  components: {
    headerTitle,
    // loadingPayment,
    loadingCircle,
    loadingPage
  },
  data () {
    return {
      loading: false,
      date: moment(new Date(Date.now())),
      limiter: moment(new Date(Date.now())).add(this.$store.getters.cart.response.second_left, 'seconds'),
      overAllTotal: {},
      selectedService: [],
      discount: 0,
      availablePay: this.$store.getters.paymentMethods,
      showDetail: false,
      modalListBank: false,
      dataListBank: this.$store.getters.banks,
      formDataOnSpot: this.$store.getters.cart,
      loadingPay: false,
      styleList: '',
      loaderDropdown: {
        length: 1,
        height: '100px',
        margin: '48px 0 24px 0',
        radius: '10px'
      },
      loaderOptions: {
        length: this.$store.getters.paymentMethods.length,
        height: '72px',
        margin: '0 0 8px 0',
        radius: '3px'
      },
      slideAnimation: true,
      widthScreen: 0,
      frameReady: true,
      detailCart: this.$store.getters.cart.response.cartDetail,
      subTotal: {}
    }
  },
  computed: {
    timer () {
      const duration = moment.duration(this.limiter.diff(this.date))
      let results = ''
      if (duration.hours() > 0) {
        results = this.twoDigitNumber(duration.hours()) + ':' + this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else if (duration.minutes() > 0) {
        results = this.twoDigitNumber(duration.minutes()) + ':' + this.twoDigitNumber(duration.seconds())
      } else {
        results = this.twoDigitNumber(duration.seconds())
      }
      this.backToSteps()
      return results
    },
    usingDuration () {
      const duration = this.limiter.diff(this.date)
      return duration
    },
    styleCentering () {
      const data = 'flex-grow: 1;'
      return data
    },
    currency () {
      return this.$store.getters.company.currency.symbol
    }
  },
  created () {
    if (this.$store.getters.activeTab.params && this.$store.getters.activeTab.params.duration) {
      this.limiter = moment(new Date(Date.now())).add(this.$store.getters.activeTab.params.duration, 'milliseconds')
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },

  async mounted () {
    window.setInterval(() => {
      this.date = moment(new Date(Date.now()))
    }, 1000)
    await this.delay(300)
    this.slideAnimation = false
    this.getPastData()
    this.getAllTotal()
  },

  methods: {
    async showModalRight (status) {
      this.showDetail = status
      this.frameReady = true
      await this.delay(300)
      this.frameReady = false
    },
    twoDigitNumber (number) {
      if (number <= 9) {
        number = '0' + number
      }
      return number
    },
    getPastData () {
      this.loading = true
      this.selectedService = []
      const data = this.$store.getters.selectedService.selected
      data.map(x => {
        this.selectedService.push(x)
      })
      Promise.all(data).then((results) => {
        this.getAllTotal()
      })
    },

    countDown (data) {
      if (data === '00:00') {
        return true
      } else {
        return false
      }
    },

    backToSteps () {
      if (this.usingDuration <= 0) {
        this.$store.dispatch('setActiveTab', {
          name: 'time-over',
          params: {
            chooseLocation: this.chooseLocation,
            comId: this.comId
          }
        })
      }
    },

    savePaymentOnSpot () {
      this.loadingPay = true
      const dataCart = this.$store.getters.cart.response
      const params = {
        invoice_id: dataCart.invoice_id,
        payment_type_id: this.formDataOnSpot.payment_type_id,
        amount: this.overAllTotal.int,
        source: 3
      }
      const token = dataCart.payment_token
      create(params, token).then(response => {
        this.loadingPay = false
        this.$store.dispatch('setActiveTab', {
          name: 'success',
          params: {
            chooseLocation: this.chooseLocation,
            comId: this.comId
          }
        })
      }).catch(errors => {
        this.loadingPay = false
        this.$Notify.error({
          message: errors.string
        })
      })
    },

    handleResize () {
      this.widthScreen = window.innerWidth
      // if need banner for height 164
      let paddingValue = 20
      // 120 is height header
      const height = window.innerHeight - 56
      this.styleList = 'height: ' + height + 'px; max-height: ' + height + 'px; overflow-x: hidden; padding: 16px 0px ' + paddingValue + 'px; overflow-y: auto;'
    },

    usingVoucher () {
      this.$Notify({
        title: 'Opps sorry!',
        message: 'this feature will be available soon',
        type: 'info'
      })
    },

    setPaymentMethod (value) {
      this.formDataOnSpot.payment_type_id = value
      if (value === 8) {
        this.savePaymentOnSpot()
      } else if (value === 7) {
        const params = {
          invoice_id: this.$store.getters.cart.response.invoice_id,
          payment_type_id: this.formDataOnSpot.payment_type_id,
          amount: this.overAllTotal.int,
          detail: {},
          source: 3,
          token: this.$store.getters.cart.response.payment_token,
          duration: this.usingDuration
        }
        this.$store.dispatch('setActiveTab', {
          name: 'credit-card',
          params: {
            chooseLocation: this.chooseLocation,
            comId: this.comId,
            formData: this.formDataOnSpot,
            newParams: params
          }
        })
      } else if (value === 11) {
        this.modalListBank = true
      }
    },

    closeModalListBank () {
      this.modalListBank = false
    },

    setTransferBank (list) {
      const data = this.formDataOnSpot
      data.payment = {
        bank: list.bank.toLowerCase()
      }
      const params = {
        invoice_id: this.$store.getters.cart.response.invoice_id,
        payment_type_id: this.formDataOnSpot.payment_type_id,
        amount: this.overAllTotal.int,
        detail: data.payment,
        source: 3,
        token: this.$store.getters.cart.response.payment_token,
        duration: this.usingDuration
      }
      this.$store.dispatch('setActiveTab', {
        name: 'bank-transfer',
        params: {
          chooseLocation: this.chooseLocation,
          comId: this.comId,
          formData: data,
          dataBank: list,
          newParams: params
        }
      })
    },

    async getSubTotal () {
      const data = this.detailCart.detail
      this.subTotal = {
        qty: 0,
        total: this.detailCart.total_price,
        ftotal: this.detailCart.ftotal_price.replace(',00', '')
      }
      data.map(x => {
        this.subTotal.qty += x.qty
      })
      await this.delay(300)
      this.loading = false
    },

    getAllTotal () {
      const data = this.$store.getters.cart.response.cartDetail
      this.overAllTotal = {
        int: data.total_price,
        format: data.ftotal_price.replace(',00', '')
      }
      this.getSubTotal()
    },
    delay (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    }
  }
}
</script>
