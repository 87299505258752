<template>
  <transition name="slide-right">
    <div v-if="!slideAnimation" class="cz-card-custom">
      <div class="cz-header">
        <div class="header-title">
          <div class="back-step" @click="backStepsTo">
            <i class="icon icon-arrow-left cz-text-lg"/>
          </div>

          <header-title :loading="false" :title="title"/>
        </div>
      </div>

      <div class="cz-body cz-pt-null">
        <transition name="fade-time">
          <div v-if="!loading" class="content">
            <div class="map-search-location">
              <div class="at-input at-input--large at-input-group at-input--append">
                <gmap-autocomplete
                  ref="gmapAutoComplete"
                  class="at-input__original"
                  placeholder="Search Google Maps"
                  @place_changed="setPlace"
                />
                <div class="at-input-group__append"><span><i class="icon icon-search"/></span></div>
              </div>
            </div>

            <gmap-map
              :options="optionsMap"
              :center="center"
              :zoom="12">
              <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
                @dragend="updateCoordinates"
              />
            </gmap-map>

            <div class="map-footer">
              <div class="map-current-location">
                <at-button size="large" circle @click="currentLocation">
                  <img src="../../icons/svg/current-location.svg" width="20" height="20"/>
                </at-button>
              </div>
              <div class="map-confirm-location">
                <div class="flex at-flex flex-center flex-middle cz-m-null">
                  <div class="col cz-p-null cz-text-left">
                    <label
                      v-if="!disabledDescLoc"
                      class="cz-text-sm cz-color-info">
                      {{ $t('label.recentLocation') }}
                    </label>
                    <at-textarea
                      v-model="address"
                      :disabled="disabledDescLoc"
                      :class="!disabledDescLoc ? '' : 'disabled'"
                      resize="none"
                      min-rows="1"
                      max-rows="6"
                      readonly
                    />
                  </div>
                  <div class="col-8 cz-p-null">
                    <at-button
                      :disabled="disabledDescLoc"
                      :class="!disabledDescLoc ? '' : 'disabled'"
                      type="success"
                      class="cz-btn block"
                      @click="confirm">
                      <span class="cz-text-sm">{{ $t('label.useLocation') }}</span>
                    </at-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <loading-page :loading="loading" :data-loading="'myLocation'"/>
      </div>
    </div>
  </transition>
</template>

<script>
import loadingPage from '../../components/UI/loading'
import headerTitle from '../../components/UI/title'
import axios from 'axios'
export default {
  components: {
    loadingPage,
    headerTitle
  },
  data () {
    return {
      title: this.$t('label.setYourLocation'),
      loading: true,
      center: { lat: 45.508, lng: -73.587 },
      markers: [],
      places: [],
      currentPlace: null,
      optionsMap: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      },
      address: '',
      comId: this.$store.getters.company.id,
      dataAddress: {},
      slideAnimation: true
    }
  },
  computed: {
    disabledDescLoc () {
      if (this.address !== '' || this.address.length > 5) {
        return false
      } else {
        return true
      }
    }
  },
  async mounted () {
    this.$nextTick(() => {
      this.geoLocate()
    })
    this.delayLoading(300)
    this.slideAnimation = false
  },
  methods: {
    backStepsTo () {
      const data = this.$store.getters.dataUser
      delete data.address
      this.$store.commit('SET_DATA_USER', { ...data })
      this.$store.dispatch('setActiveTab', 'detail-location')
    },

    setDefaultMarker () {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.currentPlace = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.markers = [{
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }
        }]
        this.setMarker(this.center.lat, this.center.lng)
      }, error => {
        this.$Message.error({
          message: error.message,
          icon: 'icon-alert-circle'
        })
      })
    },

    geoLocate () {
      const data = this.$store.getters.dataUser
      if (data.address) {
        if (data.address.geolocate && data.address.faddress && data.address.geolocates) {
          this.center = data.address.geolocate
          this.markers = data.address.geolocates
          this.address = data.address.faddress
          this.dataAddress = {
            address_level1: data.address.address_level1,
            address_level2: data.address.address_level2,
            address_level3: data.address.address_level3,
            address_level4: data.address.address_level4,
            country: data.address.country
          }
        } else {
          this.setDefaultMarker()
        }
      } else {
        this.setDefaultMarker()
      }
      this.loading = false
    },

    setPlace (place) {
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      this.setMarker(lat, lng)
    },

    setMarker (lat, lng) {
      axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: lat + ',' + lng,
          key: 'AIzaSyA2Dt2etiCUW4cnw38iES-O8T7yPPIA680'
        }
      }).then(response => {
        let results = {}
        const data = response.data.results[0]
        for (const component of data.address_components) {
          if (component.types[0] === 'postal_code') {
            results.zipcode = component.long_name
          }
          if (component.types[0] === 'administrative_area_level_4') {
            results.address_level4 = component.long_name
          }
          if (component.types[0] === 'administrative_area_level_3') {
            results.address_level3 = component.long_name
          }
          if (component.types[0] === 'administrative_area_level_2') {
            results.address_level2 = component.long_name
          }
          if (component.types[0] === 'administrative_area_level_1') {
            results.address_level1 = component.long_name
          }
          if (component.types[0] === 'country') {
            results.country = component.long_name
          }
        }
        results.formatted_address = data.formatted_address
        this.dataAddress = results
        this.center = {
          lat: lat,
          lng: lng
        }

        this.markers = [{
          position: {
            lat: lat,
            lng: lng
          }
        }]
        this.address = data.formatted_address
      }).catch(error => {
        this.$Notify({
          type: 'error',
          message: error
        })
      })
    },

    updateCoordinates (location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.center = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.setMarker(location.latLng.lat(), location.latLng.lng())
    },

    async currentLocation () {
      this.setDefaultMarker()
      await this.delayLoading(200)
      if (this.currentPlace) {
        const lat = parseFloat(this.currentPlace.lat)
        const lng = parseFloat(this.currentPlace.lng)
        this.setMarker(lat, lng)
      }
    },

    saveInput () {
      let data = {}
      if (this.$store.getters.dataUser !== null) {
        data = this.$store.getters.dataUser
      }
      data.address = {
        geolocate: this.center,
        geolocates: this.markers,
        faddress: this.address,
        zipcode: this.dataAddress.zipcode,
        address_level1: this.dataAddress.address_level1,
        address_level2: this.dataAddress.address_level2,
        address_level3: this.dataAddress.address_level3,
        address_level4: this.dataAddress.address_level4,
        country: this.dataAddress.country
      }
      data.query = {
        latitude: this.center.lat,
        longitude: this.center.lng,
        company_id: this.comId
      }
      this.$store.commit('SET_DATA_USER', { ...data })
    },

    delayLoading (time) {
      return new Promise((resolve, reject) => {
        setTimeout(resolve, time)
      })
    },

    confirm () {
      this.saveInput()
      this.$store.dispatch('setActiveTab', 'services')
    }
  }
}
</script>
